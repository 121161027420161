import { createSelector } from '@reduxjs/toolkit';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import uk from 'date-fns/locale/uk';
import hu from 'date-fns/locale/hu';

const toLocale = {
  de,
  en,
  pl,
  uk,
  hu,
};

export const getLanguage = (state) => state.global.language;

export const getDateFnsLocale = createSelector(
  getLanguage,
  (language) => {
    return toLocale[language];
  },
);

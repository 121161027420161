import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { KEY_TYPES } from '../../drApi/constants/keyType';
import IconBadge from '../../IconBadge/IconBadge';
import styles from './KeyTypesField.module.scss';

const map = [{
  keyType: KEY_TYPES.KEY,
  icon: 'key',
}, {
  keyType: KEY_TYPES.REMOTE,
  icon: 'remote',
}, {
  keyType: KEY_TYPES.CARD,
  icon: 'card',
}];

const KeyTypesField = ({ name, isEditing }) => {
  const [, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;
  const handleChange = useCallback((keyType, rawNewValue) => {
    const newValue = parseInt(rawNewValue, 10);
    setValue({
      [KEY_TYPES.KEY]: value[KEY_TYPES.KEY],
      [KEY_TYPES.REMOTE]: value[KEY_TYPES.REMOTE],
      [KEY_TYPES.CARD]: value[KEY_TYPES.CARD],
      [keyType]: newValue,
    });
  }, [setValue, value]);

  const strValues = {
    [KEY_TYPES.KEY]: `${value[KEY_TYPES.KEY]}`,
    [KEY_TYPES.REMOTE]: `${value[KEY_TYPES.REMOTE]}`,
    [KEY_TYPES.CARD]: `${value[KEY_TYPES.CARD]}`,
  };

  return (
    <div className={styles.wrapper}>
      {map.map(({ keyType, icon }) => {
        return (
          <div className={styles.item} key={keyType}>
            <IconBadge icon={icon} />
            <input
              className={styles.input}
              type="number"
              value={strValues[keyType]}
              onChange={(e) => { handleChange(keyType, e.target.value); }}
              disabled={!isEditing}
            />
          </div>
        );
      })}
    </div>
  );
};

KeyTypesField.propTypes = {
  name: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

export default KeyTypesField;

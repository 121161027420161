import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useAddElementMutation, useDeleteElementMutation, useUpdateElementMutation } from '../../drApi/drApi';
import { CARD_TYPES } from '../../drApi/constants/cardType';
import IconBadge from '../../IconBadge/IconBadge';
import styles from './IconTextElement.module.scss';
import CardTextInput from '../../EditableCard/CardTextInput';
import getPath from '../../../utils/getPath';
import setPath from '../../../utils/setPath';
import deleteDraftElement from '../thunks/deleteDraftElement';
import {
  getActiveChangeId,
  getStopCardElementHasChanges,
  getStopCardElementWithChanges,
} from '../../drApi/stopSelectors';
import ReviewBar from '../ReviewBar';
import { getGloballySelectedHouseId } from '../../drApi/housesSelectors';

const IconTextElement = ({ stopId, cardType, elementId, elementPropPath, title, icon, large = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const element = useSelector(getStopCardElementWithChanges(stopId, cardType, elementId));
  const hasChanges = useSelector(getStopCardElementHasChanges(stopId, cardType, elementId));
  const activeChangeId = useSelector(getActiveChangeId(stopId, cardType, elementId));
  const houseId = useSelector(getGloballySelectedHouseId);

  const { type: elementType, isDraft = false } = element;
  const [isEditing, setIsEditing] = useState(isDraft);
  const initialValue = getPath(element, elementPropPath);

  const [addElement, { isSuccess: isSuccessAdd, isLoading: isLoadingAdd }] = useAddElementMutation();
  const [updateElement, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate }] = useUpdateElementMutation();
  const [deleteElement, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete }] = useDeleteElementMutation();

  const isSuccess = isSuccessAdd || isSuccessUpdate || isSuccessDelete;
  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete;

  const onSubmit = useCallback(
    (values) => {
      const { [elementPropPath]: newValue } = values;

      if (newValue === initialValue) {
        setIsEditing(false);
        return;
      }
      let updateFields = null;
      const parts = elementPropPath.split('.');
      if (parts.length === 1) {
        updateFields = { [elementPropPath]: newValue };
      } else {
        const firstKey = parts.shift();
        updateFields = {
          [firstKey]: setPath(element[firstKey] || {}, parts.join('.'), newValue),
        };
      }

      if (isDraft) {
        addElement({
          stopId,
          houseId,
          cardType,
          elementId,
          body: {
            element: {
              type: elementType,
              ...updateFields,
            },
          },
        });
        return;
      }

      updateElement({
        stopId,
        cardType,
        elementId,
        activeChangeId,
        updateFields,
      });
    },
    [
      elementPropPath,
      initialValue,
      isDraft,
      updateElement,
      stopId,
      cardType,
      elementId,
      activeChangeId,
      element,
      addElement,
      houseId,
      elementType,
    ],
  );

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onDelete = useCallback(() => {
    if (isDraft) {
      dispatch(deleteDraftElement({ stopId, cardType }));
      return;
    }
    deleteElement({ stopId, cardType, elementId });
  }, [cardType, deleteElement, dispatch, elementId, isDraft, stopId]);

  return (
    <Formik
      initialValues={{
        [elementPropPath]: initialValue || '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleReset, handleChange, values }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          canEdit={!hasChanges}
          title={title}
          onDelete={onDelete}
          canDelete={!hasChanges}
          canAbort={!isDraft}
          withDeleteModal
          deleteModalTitle={t('Element löschen')}
          deleteModalContent={t('Wollen Sie wirklich dieses Element löschen?')}
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          large={large}
        >
          <div className={styles.wrapper}>
            <IconBadge icon={icon} />
            <div className={styles.number}>
              <CardTextInput
                type="text"
                // necessary for field names with dots as per https://github.com/formium/formik/issues/676#issuecomment-624652343
                name={`['${elementPropPath}']`}
                onChange={handleChange}
                value={values[elementPropPath]}
                disabled={!isEditing}
              />
            </div>
          </div>
          {!isEditing && hasChanges && (
            <ReviewBar stopId={stopId} cardType={cardType} elementId={elementId} onEdit={() => setIsEditing(true)} />
          )}
        </EditableCard>
      )}
    </Formik>
  );
};

IconTextElement.propTypes = {
  stopId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  cardType: PropTypes.oneOf(Object.values(CARD_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  elementPropPath: PropTypes.string.isRequired,
  large: PropTypes.bool,
};
export default IconTextElement;

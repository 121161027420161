import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import * as JSURL from 'jsurl';
import { useCallback, useMemo } from 'react';

const useQueryParam = (key) => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => { return new URLSearchParams(search); }, [search]);

  const rawValue = searchParams.get(key);

  const value = useMemo(() => JSURL.parse(rawValue), [rawValue]);

  const setValue = useCallback(
    (newValue) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSURL.stringify(newValue));
      history.push({ pathname, search: newSearchParams.toString() });
    },
    [history, key, pathname, searchParams],
  );

  return [value, setValue];
};

export default useQueryParam;

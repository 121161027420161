import { createSelector } from '@reduxjs/toolkit';
import { getUsersForCurrentHouse } from './usersSelectors';
import { USER_TYPES } from './constants/userType';

export const getUser = (userId) => createSelector(
  getUsersForCurrentHouse,
  (users) => users?.find((u) => (u._id === userId)),
);

export const getUserMdeId = (userId) => createSelector(
  getUser(userId),
  (user) => user?.mdeId,
);

export const getUserName = (userId) => createSelector(
  getUser(userId),
  (user) => user?.name,
);

export const getUserType = (userId) => createSelector(
  getUser(userId),
  (user) => user?.userType,
);

export const getUserPermissions = (userId) => createSelector(
  getUser(userId),
  (user) => {
    return user ? (user.permissions || []) : undefined;
  },
);

export const getUserHasPermission = (permissionToCheck, userId) => createSelector(
  getUserPermissions(userId),
  (permissions) => {
    if (!permissions) {
      return undefined;
    }
    return permissions.includes(permissionToCheck);
  },
);

export const getIsManager = (userId) => createSelector(
  getUser(userId),
  (user) => {
    const { userType } = user || {};
    return userType && userType === USER_TYPES.MANAGER;
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import RLCell from '../../recordsList/RLCell';
import RLRow from '../../recordsList/RLRow';
import UserTypeBadge from '../../UserTypeBadge/UserTypeBadge';
import { KEY_ACTION_TYPES_FRIENDLY_NAME, KEY_ACTION_TYPES } from '../../drApi/constants/keyActionType';

const KeyActionTableRow = ({ row, columns }) => {
  return (
    <RLRow>
      {columns.map((col) => {
        if (col.hidden) return null;
        let content = row[col.key];

        if (content) {
          if (col.type === 'date') {
            content = format(new Date(content), 'dd. MMM yy | kk:mm');
          }

          if (col.key === 'keyActionType') {
            const actionType = content;
            content = KEY_ACTION_TYPES_FRIENDLY_NAME[content];

            if (actionType === KEY_ACTION_TYPES.GIVE_OVER_KEYS && row.nextUser) {
              content += ` an ${row.nextUser}`;
            }
          }

          if (col.key === 'userType') {
            content = <UserTypeBadge userType={content} />;
          }
        }

        return <RLCell key={col.key + row._id}>{content}</RLCell>;
      })}

    </RLRow>

  );
};

KeyActionTableRow.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default KeyActionTableRow;

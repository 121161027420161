const uploadImageToS3 = async ({ file, s3UploadData }) => {
  const formData = new FormData();
  Object.keys(s3UploadData?.fields)
    .forEach((key) => formData.append(key, s3UploadData?.fields[key]));
  formData.append('file', file);

  const response = await fetch(s3UploadData.url, {
    method: 'POST',
    body: formData,
  });

  if (response.status === 204) {
    return `${s3UploadData?.url}/${s3UploadData?.fields?.key}`;
  }

  return false;
};

export default uploadImageToS3;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FilterSelect.module.scss';
import { ReactComponent as NotChecked } from '../../img/checkbox_unchecked.svg';
import { ReactComponent as Checked } from '../../img/checkbox_checked.svg';
import { ReactComponent as LoadingIcon } from '../../img/loader_thin_white.svg';

const FilterSelect = ({
  options,
  onChange,
  value: valueFromProps = undefined,
  isLoading = false,
  multiple = false,
  search = true,
}) => {
  const [searchStr, setSearchStr] = useState('');
  const filteredOptions = useMemo(() => {
    const trimmedSearchStr = searchStr.trim();
    if (trimmedSearchStr === '') {
      return options;
    }
    return options.filter((option) => {
      const { name } = option;
      return name.toLowerCase().indexOf(trimmedSearchStr.toLowerCase()) !== -1;
    });
  }, [options, searchStr]);
  return (
    <div className={styles.main}>
      {search && (
        <div className={styles.inputWrapper}>
          <input
            type="text"
            className={styles.input}
            onChange={(event) => {
              setSearchStr(event.target.value);
            }}
          />
        </div>
      )}
      <div className={styles.optionsList}>
        {isLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingIcon className={styles.loadingIcon} />
          </div>
        ) : (
          filteredOptions.map((option) => {
            const { value, name } = option;
            const isSelected = multiple ? valueFromProps.includes(value) : valueFromProps === value;
            return (
              <button
                type="button"
                className={styles.option}
                key={value}
                onClick={() => {
                  if (multiple) {
                    if (isSelected) {
                      onChange(valueFromProps.filter((v) => v !== value));
                    } else {
                      onChange([...valueFromProps, value]);
                    }
                  } else {
                    onChange(value);
                  }
                }}
              >
                {name}
                <div className={styles.checkboxWrapper}>{isSelected ? <Checked /> : <NotChecked />}</div>
              </button>
            );
          })
        )}
      </div>
    </div>
  );
};

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  multiple: PropTypes.bool,
  search: PropTypes.bool,
};

export default FilterSelect;

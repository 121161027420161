import React, { useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './SideMenu.module.scss';
import { ReactComponent as LogoutIcon } from '../../img/logout.svg';
import { ReactComponent as TasksIcon } from '../../img/task.svg';
import { ReactComponent as ActiveTasksIcon } from '../../img/task_white.svg';
import { ReactComponent as RoutesIcon } from '../../img/navigation.svg';
import { ReactComponent as ActiveRoutesIcon } from '../../img/navigation_white.svg';
import { ReactComponent as StopsIcon } from '../../img/home.svg';
import { ReactComponent as ActiveStopsIcon } from '../../img/home_white.svg';
import { ReactComponent as UserIcon } from '../../img/user.svg';
import { ReactComponent as ActiveUserIcon } from '../../img/user_white.svg';
import { ReactComponent as KeysIcon } from '../../img/key.svg';
import { ReactComponent as ActiveKeysIcon } from '../../img/key_white.svg';
import { ReactComponent as DashboardIcon } from '../../img/dashboard.svg';
import { ReactComponent as ActiveDashboardIcon } from '../../img/dashboard_white.svg';
import { ReactComponent as ImportLogIcon } from '../../img/importLog.svg';
import { ReactComponent as ActiveImportLogIcon } from '../../img/importLog_white.svg';
import { ReactComponent as SettingsIcon } from '../../img/settings.svg';
import { getMyFriendlyUserType, getMyMdeId, getHasPermission } from '../drApi/meSelectors';
import UnconfirmedCount from './UnconfirmedCount';
import { PERMISSIONS } from '../drApi/constants/permissions';
import { setGloballySelectedHouseId } from '../slices/globalSlice';

const SideMenu = () => {
  const { t } = useTranslation();
  const MENU_ITEMS = useMemo(() => {
    return [{
      path: '/unconfirmed_stops',
      label: t('Aufgaben'),
      IconComponent: TasksIcon,
      ActiveIconComponent: ActiveTasksIcon,
    }, {
      path: '/routes',
      label: t('Tourauswahl'),
      IconComponent: RoutesIcon,
      ActiveIconComponent: ActiveRoutesIcon,
    }, {
      path: '/users',
      label: t('Nutzer'),
      IconComponent: UserIcon,
      ActiveIconComponent: ActiveUserIcon,
    }, {
      path: '/keys',
      label: t('Schlüssel'),
      IconComponent: KeysIcon,
      ActiveIconComponent: ActiveKeysIcon,
    }, {
      path: '/stops',
      label: t('Stops'),
      IconComponent: StopsIcon,
      ActiveIconComponent: ActiveStopsIcon,
    },
    {
      path: '/dashboard',
      label: t('Dashboard'),
      IconComponent: DashboardIcon,
      ActiveIconComponent: ActiveDashboardIcon,
      adminView: true,
    },
    {
      path: '/importlog',
      label: t('Import Log'),
      IconComponent: ImportLogIcon,
      ActiveIconComponent: ActiveImportLogIcon,
      adminView: true,
    },
    {
      path: '/profile',
      label: t('Einstellungen'),
      IconComponent: SettingsIcon,
      ActiveIconComponent: SettingsIcon,
    },
    ];
  }, [t]);

  const friendlyUserType = useSelector(getMyFriendlyUserType);
  const mdeId = useSelector(getMyMdeId);
  const isGlobalAdmin = useSelector(getHasPermission(PERMISSIONS.GLOBAL_ADMIN));
  const hasDashboardPermission = useSelector(getHasPermission(PERMISSIONS.DASHBOARD));

  const { logout } = useAuth0();

  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    dispatch(setGloballySelectedHouseId({ houseId: null }));
    logout({ returnTo: window.location.origin });
  }, [dispatch, logout]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>Roadbook</div>
      <div className={styles.menu}>
        {MENU_ITEMS.map(({
          path,
          label,
          IconComponent,
          ActiveIconComponent,
          adminView,
        }) => (
          (!adminView || hasDashboardPermission) && (
          <NavLink
            className={styles.menuItem}
            to={path}
            key={path}
            activeClassName={styles.active}
          >
            <div className={styles.menuLabel}>
              {label}
              {path === '/unconfirmed_stops' && (
              <UnconfirmedCount />
              )}
            </div>
            <IconComponent className={styles.menuIcon} />
            <ActiveIconComponent className={`${styles.menuIcon} ${styles.onActive}`} />
          </NavLink>
          )
        ))}

      </div>
      <div className={styles.profile}>
        <div className={styles.name}>{mdeId}</div>
        <div className={styles.role}>
          {t(friendlyUserType)}
          {isGlobalAdmin && ` (${t('Admin')})`}
        </div>

      </div>
      <button
        type="button"
        className={styles.logoutButton}
        onClick={onLogout}
      >
        <LogoutIcon className={styles.logoutIcon} />
        {t('Ausloggen')}
      </button>
    </div>
  );
};

export default SideMenu;

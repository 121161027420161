import React, { useMemo, Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { startOfDay, endOfToday, sub, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDeleteKeyInformationMutation, useGetKeyInformationQuery, useGetKeyActionLogQuery } from '../drApi/drApi';
import KeyIdBadge from '../KeyIdBadge/KeyIdBadge';
import DetailsPageLoader from '../loaders/DetailsPageLoader';
import TitleBar from '../detailsPageComponents/TitleBar';
import CardsContainer from '../cards/CardsContainer';
import NoteEditableCard from './editableCards/NoteEditableCard';
import StopsEditableCard from './editableCards/StopsEditableCard';
import KeyTypesEditableCard from './editableCards/KeyTypesEditableCard';
import ImageDisabledCardContent from '../detailsPageComponents/ImageDisabledCardContent';
import Card from '../cards/Card';
import KeyInstancesSection from './KeyInstancesSection';
import ImageCardContent from '../detailsPageComponents/ImageCardContent';
import KeyActionTable from './table/KeyActionTable';
import RangePicker from '../RangePicker/RangePicker';
import styles from './KeyInformation.module.scss';

const KeyInformationDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { keyInformationId } = useParams();

  const [deleteKeyInformation, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] =
    useDeleteKeyInformationMutation();

  const defaultEnd = endOfToday();
  const defaultStart = startOfDay(sub(defaultEnd, { weeks: 1 }));

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const { isLoading: keyInformationLoading, data: keyInformationData } = useGetKeyInformationQuery({
    keyInformationId,
  });

  const { isLoading: keyActionsLogsLoading, data: keyActionLogsData } = useGetKeyActionLogQuery({
    keyInformationId,
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const { keyInstances, keyInformation } = keyInformationData || {};
  const { keyActionLogs } = keyActionLogsData || {};
  const image = keyInformation?.image;

  const title = useMemo(() => {
    return keyInstances?.map(({ name }) => {
      return (
        <Fragment key={name}>
          <KeyIdBadge name={name} />
          &nbsp;
        </Fragment>
      );
    });
  }, [keyInstances]);

  const handleDeleteKeyInformation = useCallback(() => {
    deleteKeyInformation({ keyInformationId });
  }, [deleteKeyInformation, keyInformationId]);

  useEffect(() => {
    if (isSuccessDelete) {
      history.push('/keys');
    }
  }, [isSuccessDelete, history]);

  if (keyInformationLoading) {
    return <DetailsPageLoader />;
  }

  return (
    <>
      <TitleBar
        title={title}
        isLoading={isLoadingDelete}
        canDelete
        onDelete={handleDeleteKeyInformation}
        withDeleteModal
        deleteModalTitle={t('Schlüssel löschen')}
        deleteModalContent={t('Möchten Sie wirklich diesen Schlüssel inklusive aller Schlüssel-IDs löschen?')}
      />
      <CardsContainer>
        <NoteEditableCard keyInformation={keyInformation} />
        <StopsEditableCard keyInformation={keyInformation} />
        <KeyTypesEditableCard keyInformation={keyInformation} />
        <Card title={t('Bild')} large>
          {image ? <ImageCardContent src={image} /> : <ImageDisabledCardContent />}
        </Card>
      </CardsContainer>
      <KeyInstancesSection keyInstances={keyInstances} keyInformationId={keyInformationId} />

      <h1 className={styles.header}>
        {`${t('Schlüsselevents')} ${format(startDate, 'dd. MMM')} - ${format(endDate, 'dd. MMM')}`}
      </h1>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultRanges={[7, 14, 28]}
      />
      <KeyActionTable data={keyActionLogs || []} isLoading={keyActionsLogsLoading} />
    </>
  );
};

export default KeyInformationDetails;

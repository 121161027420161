import { createSelector } from '@reduxjs/toolkit';
import i18n from '../../i18next/i18next';
import { drApi } from './drApi';

const getStop = (stopId) =>
  createSelector(drApi.endpoints.getStop.select({ stopId }), (query) => {
    return query?.data;
  });

export const getStopCustomerName = (stopId) =>
  createSelector(getStop(stopId), (stop) => {
    return stop?.customerName;
  });

export const getStopCardByType = (stopId, cardType) =>
  createSelector(getStop(stopId), (stop) => {
    return stop?.cards?.find((card) => card.type === cardType);
  });

export const getStopKeyInstances = (stopId) =>
  createSelector(getStop(stopId), (stop) => {
    return stop?.keyInstances;
  });

export const getStopKeyInformationId = (stopId) =>
  createSelector(getStop(stopId), (stop) => {
    return stop?.keyInformationId;
  });

export const getStopCardHasDraft = (stopId, cardType) =>
  createSelector(getStopCardByType(stopId, cardType), (card) => {
    return card?.elements?.some((e) => e.isDraft);
  });

export const getStopCardElementIds = (stopId, cardType) =>
  createSelector(getStopCardByType(stopId, cardType), (card) => {
    return card?.elements?.map((e) => e._id) || [];
  });

const getStopCardElement = (stopId, cardType, elementId) =>
  createSelector(getStopCardByType(stopId, cardType), (card) => {
    return card?.elements?.find((e) => e._id === elementId);
  });

export const getStopCardElementType = (stopId, cardType, elementId) =>
  createSelector(getStopCardElement(stopId, cardType, elementId), (element) => {
    return element?.type;
  });

export const getStopCardElementWithChanges = (stopId, cardType, elementId) =>
  createSelector(getStopCardElement(stopId, cardType, elementId), (element) => {
    const { unconfirmedChanges } = element;
    const updateChange = unconfirmedChanges?.find((cR) => cR.type === 'update');
    return updateChange ? { ...element, ...updateChange.updateFields } : element;
  });

export const getStopCardElementHasChanges = (stopId, cardType, elementId) =>
  createSelector(getStopCardElement(stopId, cardType, elementId), (element) => {
    return element?.unconfirmedChanges?.length > 0;
  });

export const getDisplayChange = (stopId, cardType, elementId) =>
  createSelector(getStopCardElement(stopId, cardType, elementId), (element) => {
    if (!element) {
      return null;
    }
    const { unconfirmedChanges } = element;
    if (!unconfirmedChanges) {
      return null;
    }
    const create = unconfirmedChanges.find((u) => u.type === 'create');
    if (create) {
      return create;
    }
    return unconfirmedChanges.length > 0 ? unconfirmedChanges[0] : undefined;
  });

export const getActiveChangeId = (stopId, cardType, elementId) =>
  createSelector(getDisplayChange(stopId, cardType, elementId), (change) => {
    return change?.id;
  });

export const getElementChangeProperties = (stopId, cardType, elementId) =>
  createSelector(getDisplayChange(stopId, cardType, elementId), (unconfirmedChange) => {
    const { _id: changeId, type: changeType, issuerName } = unconfirmedChange;

    let props;
    switch (changeType) {
      case 'create':
        props = {
          ex: `${i18n.t('Neue Karte von')} ${issuerName}`,
          acceptLabel: i18n.t('Akzeptieren'),
          denyLabel: i18n.t('Löschen'),
          canEdit: true,
          confirmDenyTitle: i18n.t('Ablehnen bestätigen'),
          confirmDenyText: i18n.t(
            'Wollen Sie das neue Element wirklich verwerfen? Die Änderungen des Fahrers gehen verloren und können nicht wieder hergestellt werden.',
          ),
        };
        break;
      case 'update':
      case 'change':
        props = {
          ex: `${i18n.t('Bearbeitet von')} ${issuerName}`,
          acceptLabel: i18n.t('Akzeptieren'),
          denyLabel: i18n.t('Verwerfen'),
          canEdit: true,
          confirmDenyTitle: i18n.t('Ablehnen bestätigen'),
          confirmDenyText: i18n.t(
            'Wenn Sie die Anfrage abbrechen, gehen die Änderugen vom Fahrer verloren und können nicht wieder hergestellt werden.',
          ),
        };
        break;
      case 'delete':
        props = {
          ex: `${i18n.t('Zur Löschung vorgeschlagen von')} ${issuerName}`,
          acceptLabel: i18n.t('Bestätigen'),
          denyLabel: i18n.t('Ablehnen'),
        };
        break;
      default:
        throw new Error('unknown change changeType');
    }
    return {
      ...props,
      changeId,
      changeType,
    };
  });

export const getStopsSearchString = (state) => state.stops.searchString;

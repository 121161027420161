import { createSelector } from '@reduxjs/toolkit';
import { drApi } from './drApi';
import { USER_TYPES, USER_TYPES_FRIENDLY_NAME } from './constants/userType';

const getMe = createSelector(
  drApi.endpoints.getMe.select(),
  (query) => query?.data,
);
export const getMyFriendlyUserType = createSelector(
  getMe,
  (me) => {
    const { userType } = me || {};
    return userType && USER_TYPES_FRIENDLY_NAME[userType];
  },
);

export const getMyMdeId = createSelector(
  getMe,
  (me) => me && me.mdeId,
);
export const getMyId = createSelector(
  getMe,
  (me) => me && me._id,
);

export const getMyHouseId = createSelector(
  getMe,
  (me) => me && me.houseId,
);

export const getIsManager = createSelector(
  getMe,
  (me) => {
    const { userType } = me || {};
    return userType && userType === USER_TYPES.MANAGER;
  },
);

export const getMyPermissions = createSelector(
  getMe,
  (me) => {
    return me ? (me.permissions || []) : undefined;
  },
);

export const getHasPermission = (permissionToCheck) => createSelector(
  getMyPermissions,
  (permissions) => {
    if (!permissions) {
      return undefined;
    }
    return permissions.includes(permissionToCheck);
  },
);

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGetRoutesQuery } from '../../drApi/drApi';
import FilterSelect from '../../FilterSelect/FilterSelect';
import styles from './RoutesFilterPopup.module.scss';
import { getRouteOptions } from '../../drApi/routesSelectors';
import useQueryParam from '../../url/useQueryParam';
import { getGloballySelectedHouseId } from '../../drApi/housesSelectors';

const RoutesFilterPopup = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading } = useGetRoutesQuery(activeHouseId);
  const routeOptions = useSelector(getRouteOptions);
  const [routes, setRoutes] = useQueryParam('routes');
  const onChange = useCallback(
    (newRoutes) => {
      setRoutes(newRoutes);
    },
    [setRoutes],
  );

  const value = routes || [];
  return (
    <div className={styles.main}>
      <FilterSelect options={routeOptions} isLoading={isLoading} onChange={onChange} value={value} multiple />
    </div>
  );
};

RoutesFilterPopup.propTypes = {};

export default RoutesFilterPopup;

import { createSelector } from '@reduxjs/toolkit';
import { getRoutesForActiveHouse } from './routesSelectors';

const getRoute = (routeId) => createSelector(
  getRoutesForActiveHouse,
  (routes) => routes?.find((r) => (r._id === routeId)),
);
export const getRoutePermanentUser = (routeId) => createSelector(
  getRoute(routeId),
  (route) => route?.permanentUser,
);
export const getRoutePermanentUserId = (routeId) => createSelector(
  getRoutePermanentUser(routeId),
  (user) => user?._id,
);

export const getRouteAssignedUsers = (routeId) => createSelector(
  getRoute(routeId),
  (route) => route?.assignedUsers,
);

export const getRouteAssignedUserIds = (routeId) => createSelector(
  getRouteAssignedUsers(routeId),
  (users) => users?.map((u) => u._id),
);

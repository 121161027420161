import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { chartColors } from './chartColors';
import styles from './Dashboard.module.scss';
import { USER_TYPES_FRIENDLY_NAME } from '../drApi/constants/userType';

ChartJS.register(ArcElement, Tooltip, Legend);

const UserTypePieChart = ({ data = [], dimension, title = 'Chart' }) => {
  const { t } = useTranslation();
  const mappedData = useMemo(() => ({
    labels: data.map((el) => t(USER_TYPES_FRIENDLY_NAME[el._id])),
    datasets: [
      {
        data: data.map((el) => el[dimension]),
        backgroundColor: chartColors,
        borderWidth: 0,
      },

    ],
  }), [data, dimension, t]);

  const options = useMemo(() => ({
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: title,
      },
    },
  }), [title]);

  return (
    <div className={styles.pieChart}>
      <Pie data={mappedData} options={options} />
    </div>
  );
};

UserTypePieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dimension: PropTypes.string,
  title: PropTypes.string,
};

export default UserTypePieChart;

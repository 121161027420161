import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useUpdateKeyInformationMutation } from '../../drApi/drApi';
import KeyTypesField from '../fields/KeyTypesField';
import { KEY_TYPES } from '../../drApi/constants/keyType';

const KeyTypesEditableCard = ({ keyInformation }) => {
  const { t } = useTranslation();
  const { _id: keyInformationId, keyTypes } = keyInformation;
  const [isEditing, setIsEditing] = useState(false);

  const [
    updateKeyInformation, { isSuccess, isLoading },
  ] = useUpdateKeyInformationMutation();

  const onSubmit = useCallback((values) => {
    let { keyTypes: newKeyTypes } = values;
    newKeyTypes = { ...newKeyTypes };
    Object.values(KEY_TYPES).forEach((keyType) => {
      if (!newKeyTypes[keyType]) {
        newKeyTypes[keyType] = 0;
      }
    });
    if (Object.keys(keyTypes).every((keyType) => {
      return newKeyTypes[keyType] === keyTypes[keyType];
    })) {
      setIsEditing(false);
      return;
    }
    updateKeyInformation({
      keyInformationId,
      updateFieldsInformation: {
        keyTypes: newKeyTypes,
      },
    });
  }, [keyInformationId, keyTypes, updateKeyInformation]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{ keyTypes }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleReset,
        handleSubmit,
      }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          title={t('Informationen')}
          canDelete={false}
          canAbort
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          large
        >
          <KeyTypesField
            name="keyTypes"
            isEditing={isEditing}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

KeyTypesEditableCard.propTypes = {
  keyInformation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    keyTypes: PropTypes.objectOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default KeyTypesEditableCard;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { ReactComponent as Close } from '../../img/close-icon.svg';

const SearchInput = ({
  onSearch,
  onClearSearch,
  initialValue = '',
  placeHolder = 'Suchen',
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = () => {
    onSearch(searchValue);
  };

  const handleClearSearch = () => {
    onClearSearch();
    setSearchValue('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setSearchValue(initialValue);
  }, [initialValue]);

  return (
    <div className={styles.searchContainer}>
      <input
        value={searchValue}
        type="text"
        placeholder={placeHolder}
        className={styles.searchInput}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
      />

      {searchValue && (
      <button
        className={styles.clearButton}
        type="button"
        onClick={() => handleClearSearch()}
      >
        <Close />
      </button>
      )}

      <button
        className={styles.searchButton}
        type="button"
        onClick={() => handleSearch()}
      >
        {t('Suchen')}
      </button>

    </div>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  placeHolder: PropTypes.string,
};

export default SearchInput;

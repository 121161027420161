import React from 'react';
import PropTypes from 'prop-types';
import sematable from 'sematable';
import { useHistory } from 'react-router-dom';
import JSURL from 'jsurl';
import { useTranslation } from 'react-i18next';
import RLSortHeader from '../recordsList/RLSortHeader';
import styles from './KeysTable.module.scss';
import RLHeaderCell from '../recordsList/RLHeaderCell';
import RLRow from '../recordsList/RLRow';
import RLCell from '../recordsList/RLCell';
import RLTable from '../recordsList/RLTable';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import KeyTypeBadge from '../KeyTypeBadge/KeyTypeBadge';
import RLTableLoader from '../recordsList/RLTableLoader';
import KeyIdBadge from '../KeyIdBadge/KeyIdBadge';
import { KEY_POSITION_TYPES_MAPPINGS } from '../drApi/constants/keyPositionType';
import { ReactComponent as CaretRightIcon } from '../../img/caret_right.svg';

const columns = [
  { key: '_id', header: 'ID', primaryKey: true },
  { key: 'name', header: 'Schlüssel-ID', sortable: true },
  { key: 'stopName', header: 'Stopname', sortable: true },
  { key: 'stopAddress', header: 'Stop-Adresse', sortable: true },
];

const KeysTable = ({
  isLoading,
  headers: { name: nameHeader, stopName: stopNameHeader, stopAddress: stopAddressHeader },
  data,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <RLTable>
        <thead>
          <RLHeaderRow>
            <RLHeaderCell>ID</RLHeaderCell>
            <RLSortHeader {...stopNameHeader} />
            <RLSortHeader {...stopAddressHeader} />
            <RLHeaderCell right>{t('Ort')}</RLHeaderCell>
            <RLHeaderCell right>{t('Besitzer')}</RLHeaderCell>
            <RLHeaderCell right>{t('Schlüsseltypen')}</RLHeaderCell>
            <RLHeaderCell />
          </RLHeaderRow>
        </thead>
        <tbody>
          {data
            .sort((a, b) => {
              return a?.name.localeCompare(b?.name, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .map((keyInstance) => {
              const { _id, customerName, stopAddress, keyInformation, name, currentUser, currentPosition, stopName } =
                keyInstance;
              const { keyTypes, _id: KeyInformationId } = keyInformation;
              const currentUserName = currentUser?.name;
              const position = KEY_POSITION_TYPES_MAPPINGS[currentPosition]?.human;
              const keyTypeValues = Object.keys(keyTypes).filter((keyType) => keyTypes[keyType] > 0);
              return (
                <RLRow
                  key={_id}
                  onClick={() => {
                    history.push(`/keys/${KeyInformationId}?selected=${JSURL.stringify(_id)}`);
                  }}
                  role="button"
                >
                  <RLCell>
                    <KeyIdBadge name={name} />
                  </RLCell>
                  <RLCell>{customerName || stopName}</RLCell>
                  <RLCell>{stopAddress}</RLCell>
                  <RLCell right>{t(position)}</RLCell>
                  <RLCell right>{currentUserName}</RLCell>
                  <RLCell right>
                    <div className={styles.keyTypesWrapper}>
                      {keyTypeValues.map((keyType) => {
                        return <KeyTypeBadge key={keyType} keyType={keyType} />;
                      })}
                    </div>
                  </RLCell>
                  <RLCell right>
                    <CaretRightIcon />
                  </RLCell>
                </RLRow>
              );
            })}
        </tbody>
      </RLTable>
      {isLoading && <RLTableLoader />}
    </>
  );
};

KeysTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default sematable('keys', KeysTable, columns, {
  plain: true,
  defaultPageSize: 9999,
});

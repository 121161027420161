import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import CardsContainer from '../cards/CardsContainer';
import CardTextInput from '../EditableCard/CardTextInput';
import { useCreateUserMutation } from '../drApi/drApi';
import UserTypeSelect from './UserTypeSelect';
import Card from '../cards/Card';
import { USER_TYPES } from '../drApi/constants/userType';
import TitleBar from '../detailsPageComponents/TitleBar';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';
import styles from './UserTypeCard.module.scss';

const NewUser = () => {
  const { goBack, push: historyPush } = useHistory();
  const houseId = useSelector(getGloballySelectedHouseId);

  const [createUser, {
    data, isLoading, isSuccess, isError,
  }] = useCreateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      const { user: { _id: userId } } = data;
      historyPush(`/users/${userId}`);
    }
  }, [isSuccess, isError, data, historyPush]);

  const onSubmit = useCallback((values) => {
    const {
      name, password, mdeId, userType,
    } = values;
    createUser({
      body: {
        friendlyName: name,
        username: mdeId,
        password,
        userType,
        houseId,
      },
    });
  }, [createUser, houseId]);

  const onCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <Formik
      initialValues={{
        name: '',
        password: '',
        userType: USER_TYPES.DRIVER,
        mdeId: '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values: { name, mdeId, password },
      }) => (
        <>
          <TitleBar
            title={name}
            isLoading={isLoading}
            onAccept={handleSubmit}
            onCancel={onCancel}
            acceptLabel="Nutzer Anlegen"
          />
          <CardsContainer>
            <Card
              title="Name"
            >
              <CardTextInput
                name="name"
                onChange={handleChange}
                value={name}
                disabled={isLoading}
              />
            </Card>
            <Card
              title="ID"
            >
              <CardTextInput
                name="mdeId"
                onChange={handleChange}
                value={mdeId}
                disabled={isLoading}
              />
            </Card>
            <Card
              title="Passwort"
            >
              <CardTextInput
                id="password"
                type="text"
                onChange={handleChange}
                value={password}
                disabled={isLoading}
              />
            </Card>
            <Card
              title="Typ"
            >
              <div className={styles.container}>
                <UserTypeSelect />
              </div>
            </Card>
          </CardsContainer>
        </>
      )}
    </Formik>
  );
};

export default NewUser;

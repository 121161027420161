import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../EditableCard/EditableCard';
import { getUserName } from '../drApi/userSelectors';
import { usePatchUserMutation } from '../drApi/drApi';
import CardTextInput from '../EditableCard/CardTextInput';

const UserNameCard = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [isEditing, setIsEditing] = useState(false);

  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();
  const initialName = useSelector(getUserName(userId));

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((values) => {
    const { name: newName } = values;

    if (newName === initialName) {
      setIsEditing(false);
      return;
    }

    patchUser({
      id: userId,
      patch: {
        name: newName,
      },
    });
  }, [initialName, patchUser, userId]);

  const disabled = isLoading || !isEditing;

  return (
    <Formik
      initialValues={{
        name: initialName || '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleReset,
        handleChange,
        values,
      }) => (
        <EditableCard
          onAccept={handleSubmit}
          onAbort={handleReset}
          isEditing={isEditing}
          isLoading={isLoading}
          onEditingChange={setIsEditing}
          title={t('Name')}
        >
          <CardTextInput
            name="name"
            onChange={handleChange}
            value={values.name}
            disabled={disabled}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

export default UserNameCard;

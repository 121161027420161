import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Pagination.module.scss';
import { ReactComponent as CaretRightIcon } from '../../img/caret_right.svg';
import { ReactComponent as CaretRightGreyIcon } from '../../img/caret_right_grey.svg';
import { ReactComponent as CaretLeftIcon } from '../../img/caret_left.svg';
import { ReactComponent as CaretLeftGreyIcon } from '../../img/caret_left_grey.svg';
import { goToNextPage, goToPage, goToPrevPage } from './paginationSlice';
import {
  getPage,
  getPaginationDescription,
  getPaginationDisplayInfo,
  getPaginationNavigationInfo, getTotalPages,
} from './paginationSelectors';

const PageNumber = ({
  page, tableName, selected = false,
}) => {
  const dispatch = useDispatch();
  const handler = () => { dispatch(goToPage({ tableName, page })); };
  return <button className={`${styles.pageItem} ${selected && styles.selected}`} type="button" onClick={handler}>{page}</button>;
};

PageNumber.propTypes = {
  page: PropTypes.number.isRequired,
  tableName: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

const Pagination = ({
  tableName,
}) => {
  const {
    displayPages,
    needsStartDots,
    needsEndDots,
  } = useSelector(getPaginationDisplayInfo(tableName));

  const { from, to, totalItems } = useSelector(getPaginationDescription(tableName));
  const { canGoBack, canGoForward } = useSelector(getPaginationNavigationInfo(tableName));
  const page = useSelector(getPage(tableName));
  const totalPages = useSelector(getTotalPages(tableName));

  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        {from}
        {' '}
        -
        {' '}
        {to}
        {' '}
        von
        {' '}
        {totalItems}
      </div>
      <button
        type="button"
        disabled={!canGoBack}
        className={`${styles.pageItem} ${styles.control}`}
        onClick={() => { dispatch(goToPrevPage({ tableName })); }}
      >
        {canGoBack
          ? <CaretLeftIcon className={styles.icon} />
          : <CaretLeftGreyIcon className={styles.icon} />}
      </button>
      <PageNumber page={1} tableName={tableName} selected={page === 1} />
      {needsStartDots && <div className={styles.pageItem}>...</div>}
      {displayPages.map((p) => {
        return <PageNumber page={p} tableName={tableName} key={p} selected={p === page} />;
      })}
      {needsEndDots && <div className={styles.pageItem}>...</div>}
      <PageNumber page={totalPages} tableName={tableName} selected={totalPages === page} />
      <button
        type="button"
        className={`${styles.pageItem} ${styles.control}`}
        disabled={page >= totalPages}
        onClick={() => { dispatch(goToNextPage({ tableName })); }}
      >
        {canGoForward
          ? <CaretRightIcon className={styles.icon} />
          : <CaretRightGreyIcon className={styles.icon} />}
      </button>

    </div>
  );
};

Pagination.propTypes = {
  tableName: PropTypes.string.isRequired,
};

export default Pagination;

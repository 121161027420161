import { Formik } from 'formik';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditableCard from '../EditableCard/EditableCard';
import CardTextInput from '../EditableCard/CardTextInput';
import { usePatchUserMutation } from '../drApi/drApi';

const UserPasswordCard = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();
  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = useCallback((values) => {
    const { password: newPassword } = values;
    patchUser({
      id: userId,
      patch: {
        password: newPassword,
      },
    });
  }, [patchUser, userId]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const resetPassword = useCallback((values, { setFieldValue }) => {
    setFieldValue('password', '');
  }, []);

  const disabled = isLoading || !isEditing;

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={onSubmit}
      onReset={resetPassword}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleReset,
        handleChange,
        values,
      }) => (
        <EditableCard
          onAccept={handleSubmit}
          onAbort={handleReset}
          isEditing={isEditing}
          onEditingChange={setIsEditing}
          title={t('Passwort')}
          isLoading={isLoading}
        >
          <CardTextInput
            name="password"
            type={isEditing ? 'text' : 'password'}
            onChange={handleChange}
            value={values.password}
            disabled={disabled}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

export default UserPasswordCard;

import { Formik } from 'formik';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditableCard from '../EditableCard/EditableCard';
import { getUserType } from '../drApi/userSelectors';
import { usePatchUserMutation } from '../drApi/drApi';
import { getMyId } from '../drApi/meSelectors';
import UserTypeSelect from './UserTypeSelect';
import styles from './UserTypeCard.module.scss';

const UserTypeCard = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const initialUserType = useSelector(getUserType(userId));
  const myId = useSelector(getMyId);
  const isMe = myId === userId;

  const [isEditing, setIsEditing] = useState(false);

  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((values) => {
    const { userType: selectedUserType } = values;
    if (selectedUserType === initialUserType) {
      setIsEditing(false);
      return;
    }
    patchUser({
      id: userId,
      patch: {
        userType: selectedUserType,
      },
    });
  }, [initialUserType, patchUser, userId]);

  const disabled = !isEditing || isLoading;
  return (
    <Formik
      initialValues={{
        userType: initialUserType,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleReset,
      }) => (
        <EditableCard
          onAccept={handleSubmit}
          onAbort={handleReset}
          isEditing={isEditing}
          isLoading={isLoading}
          onEditingChange={setIsEditing}
          canEdit={!isMe}
          title={t('Typ')}
        >
          <div className={styles.container}>
            <UserTypeSelect
              disabled={disabled}
            />
          </div>
        </EditableCard>
      )}
    </Formik>
  );
};

export default UserTypeCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './IconBadge.module.scss';
import { ReactComponent as CardIcon } from '../../img/card.svg';
import { ReactComponent as RemoteIcon } from '../../img/remote.svg';
import { ReactComponent as KeyIcon } from '../../img/key.svg';
import { ReactComponent as PhoneIcon } from '../../img/phone.svg';
import { ReactComponent as ClockIcon } from '../../img/clock.svg';
import { ReactComponent as PinCodeIcon } from '../../img/pin_code.svg';
import { ReactComponent as KeyUserIcon } from '../../img/key_user_white.svg';
import { ReactComponent as CorsshairIcon } from '../../img/crosshair.svg';
import { ReactComponent as FlagIcon } from '../../img/flag.svg';
import { ReactComponent as PlusIcon } from '../../img/plus.svg';

const DEFAULT_HEIGHT = '12px';

const ICON_NAME_MAPPINGS = {
  key: {
    icon: KeyIcon,
  },
  card: {
    icon: CardIcon,
    height: '6px',
  },
  remote: {
    icon: RemoteIcon,
    height: '14px',
  },
  phone: {
    icon: PhoneIcon,
  },
  clock: {
    icon: ClockIcon,
  },
  pinCode: {
    icon: PinCodeIcon,
  },
  keyUser: {
    icon: KeyUserIcon,
  },
  crosshair: {
    icon: CorsshairIcon,
  },
  flag: {
    icon: FlagIcon,
  },
  plus: {
    icon: PlusIcon,
  },
};

const IconBadge = ({ icon, className: classNameFromProps = '' }) => {
  const className = `${classNameFromProps} ${styles.wrapper}`;

  if (!ICON_NAME_MAPPINGS[icon]) {
    // eslint-disable-next-line no-console
    console.error(`No icon found for ${icon}`);
    return null;
  }
  const Icon = ICON_NAME_MAPPINGS[icon].icon;
  const height = ICON_NAME_MAPPINGS[icon].height || DEFAULT_HEIGHT;
  return (
    <div className={className}>
      <Icon style={{ height }} />
    </div>
  );
};

IconBadge.propTypes = {
  icon: PropTypes.oneOf(Object.keys(ICON_NAME_MAPPINGS)),
  className: PropTypes.string,
};

export default IconBadge;

import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RLHeaderRow from '../../recordsList/RLHeaderRow';
import RLTable from '../../recordsList/RLTable';
import RLTableLoader from '../../recordsList/RLTableLoader';
import RLSortHeader from '../../recordsList/RLSortHeader';
import KeyActionTableRow from './KeyActionTableRow';
import styles from '../KeyInformation.module.scss';

const columns = [
  {
    key: '_id', header: 'ID', hidden: true, primaryKey: true,
  },
  {
    key: 'userType', header: 'Typ', sortable: true,
  },
  { key: 'name', header: 'Nutzer', sortable: true },
  {
    key: 'timestamp', header: 'Zeit', sortable: true, type: 'date',
  },
  { key: 'keyInstanceName', header: 'Schlüsselname', sortable: true },
  { key: 'keyActionType', header: 'Schlüsselevent' },
];

const KeyActionTable = ({
  data,
  headers,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  if (!isLoading && !data?.length) {
    return (
      <div className={styles.text}>
        {t('Keine Daten für diesen Zeitraum')}
      </div>
    );
  }

  return (
    <>
      {isLoading
        ? <RLTableLoader />
        : (
          <RLTable>
            <thead>
              <RLHeaderRow>
                {columns.map((col) => {
                  if (col.hidden) return null;
                  return <RLSortHeader key={col.key} {...headers[col.key]} />;
                })}
              </RLHeaderRow>
            </thead>
            <tbody>
              {data.map((row) => {
                const { _id } = row;
                return <KeyActionTableRow key={_id} row={row} columns={columns} />;
              })}
            </tbody>
          </RLTable>
        )}
    </>
  );
};

KeyActionTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  headers: PropTypes.objectOf(PropTypes.any).isRequired,

  isLoading: PropTypes.bool,
};

export default sematable('KeyActionTable', KeyActionTable, columns, {
  plain: true,
  defaultPageSize: 9999,
  sortKey: 'timestamp',
  sortDirection: 'desc',
});

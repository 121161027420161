import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import Routes from '../routes/Routes';
import styles from './MainArea.module.scss';
import Users from '../users/Users';
import UnconfirmedStops from '../unconfirmedStops/UnconfirmedStops';
import Keys from '../keys/Keys';
import UserDetails from '../userDetails/UserDetails';
import StopDetails from '../StopDetails/StopDetails';
import NewUser from '../userDetails/NewUser';
import TopRightLink from './TopRightLink';
import HouseSwitcher from './HouseSwitcher';
import Stops from '../stops/Stops';
import KeyInformationDetails from '../keyInformationDetails/KeyInformationDetails';
import NewKeyInformation from '../keyInformationDetails/NewKeyInformation';
import { getHasPermission } from '../drApi/meSelectors';
import Dashboard from '../dashboard/Dashboard';
import { PERMISSIONS } from '../drApi/constants/permissions';
import ImportLog from '../ImportLog/ImportLog';
import Profile from '../Profile/Profile';
import SearchInput from '../SearchInput/SearchInput';
import { setStopsSearchString } from '../slices/stopsSlice';
import { getStopsSearchString } from '../drApi/stopSelectors';

const MainArea = () => {
  const { t } = useTranslation();
  const isGlobalAdmin = useSelector(getHasPermission(PERMISSIONS.GLOBAL_ADMIN));
  const dispatch = useDispatch();

  const location = useLocation();
  const path = location.pathname;

  const stopsSearchString = useSelector(getStopsSearchString);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        {path === '/stops' && (
          <div className={styles.flexGrowContainer}>
            <SearchInput
              onSearch={(value) => dispatch(setStopsSearchString({ searchString: value }))}
              onClearSearch={() => dispatch(setStopsSearchString({ searchString: '' }))}
              initialValue={stopsSearchString}
              placeHolder={t('Suche nach Stops (Kundenname, Adresse)')}
            />
          </div>
        )}
        <TopRightLink />
        {isGlobalAdmin && <HouseSwitcher />}
      </div>
      <div className={styles.contentScrollContainer}>
        <div className={styles.content}>
          <Switch>
            <Route path="/unconfirmed_stops" component={UnconfirmedStops} />
            <Route path="/routes" component={Routes} />
            <Route path="/users/new" component={NewUser} />
            <Route path="/users/:userId" component={UserDetails} />
            <Route path="/users" component={Users} />
            <Route path="/keys/new" component={NewKeyInformation} />
            <Route path="/keys/:keyInformationId" component={KeyInformationDetails} />
            <Route path="/keys" component={Keys} />
            <Route path="/stops/:stopId" component={StopDetails} />
            <Route path="/stops" component={Stops} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/importlog" component={ImportLog} />
            <Route path="/profile" component={Profile} />
            {/* Redirect to unconfirmed stops when opening the start page */}
            <Route path="/" exact render={() => <Redirect to="/unconfirmed_stops" />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MainArea;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useDeleteElementMutation } from '../../drApi/drApi';
import { getStopCardElementHasChanges, getStopCardElementWithChanges } from '../../drApi/stopSelectors';
import ReviewBar from '../ReviewBar';
import ImageCardContent from '../../detailsPageComponents/ImageCardContent';

const ImageElement = ({ stopId, cardType, elementId, title, large = false }) => {
  const { t } = useTranslation();
  const element = useSelector(getStopCardElementWithChanges(stopId, cardType, elementId));
  const hasChanges = useSelector(getStopCardElementHasChanges(stopId, cardType, elementId));
  const latest = element?.img_url?.latest;
  const [isEditing, setIsEditing] = useState(false);

  const [deleteElement, { isLoading }] = useDeleteElementMutation();

  const onAccept = useCallback(() => {
    setIsEditing(false);
  }, []);
  const onDelete = useCallback(() => {
    deleteElement({ stopId, cardType, elementId });
  }, [cardType, deleteElement, elementId, stopId]);
  return (
    <EditableCard
      title={title}
      isEditing={isEditing}
      onEditingChange={setIsEditing}
      canAbort={false}
      canEdit={!hasChanges}
      onAccept={onAccept}
      onDelete={onDelete}
      canDelete
      isLoading={isLoading}
      withDeleteModal
      deleteModalTitle={t('Bild löschen')}
      deleteModalContent={t('Wollen Sie wirklich dieses Bild löschen?')}
      large={large}
    >
      <ImageCardContent src={latest} />
      {!isEditing && hasChanges && <ReviewBar stopId={stopId} cardType={cardType} elementId={elementId} disableEdit />}
    </EditableCard>
  );
};

ImageElement.propTypes = {
  stopId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

export default ImageElement;

import { createSelector } from '@reduxjs/toolkit';
import { drApi } from './drApi';
import { getGloballySelectedHouseId } from './housesSelectors';

export const getUsersForCurrentHouse = createSelector(
  (state) => drApi.endpoints.getUsers.select(getGloballySelectedHouseId(state))(state),
  (query) => query?.data,
);

export const getUserOptions = createSelector(
  getUsersForCurrentHouse,
  (users) => {
    if (!users) {
      return [];
    }
    return users.map(({ name, _id }) => ({ name, value: _id }));
  },
);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StopsTable from './StopsTable';
import { useGetStopsQuery } from '../drApi/drApi';
import { resetPagination, setTotalItems } from '../pagination/paginationSlice';
import { getPage, getPageSize, getTotalPages } from '../pagination/paginationSelectors';
import Pagination from '../pagination/Pagination';
import useQueryParam from '../url/useQueryParam';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';
import { getStopsSearchString } from '../drApi/stopSelectors';

const tableName = 'stops';

const Stops = () => {
  const dispatch = useDispatch();

  const searchString = useSelector(getStopsSearchString);
  const page = useSelector(getPage(tableName));
  const pageSize = useSelector(getPageSize(tableName));
  const totalPages = useSelector(getTotalPages(tableName));

  const [urlRoutes] = useQueryParam('routes');
  const [infoOptions] = useQueryParam('infos');
  const routesFilter = urlRoutes?.length > 0 && urlRoutes;
  const infoFilter = infoOptions?.length > 0 && infoOptions;
  useEffect(() => {
    // reset pagination when filter is changed
    dispatch(resetPagination({ tableName }));
  }, [dispatch, routesFilter, searchString]);
  const houseId = useSelector(getGloballySelectedHouseId);
  const { data, isFetching } = useGetStopsQuery({
    houseId,
    page,
    pageSize,
    routesFilter,
    infoFilter,
    searchString,
  });
  const { stops, totalItems } = data || {};

  useEffect(() => {
    dispatch(setTotalItems({ tableName, totalItems }));
  }, [totalItems, dispatch]);

  return (
    <>
      {<StopsTable data={stops || []} isLoading={isFetching} />}
      {totalPages > 1 && !isFetching && <Pagination tableName={tableName} />}
    </>
  );
};

export default Stops;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CardsContainer from '../cards/CardsContainer';
import CardSectionTitle from '../detailsPageComponents/CardSectionTitle';
import useQueryParam from '../url/useQueryParam';
import KeyInstanceEditableCard from './editableCards/KeyInstanceEditableCard';
import AddKeyInstance from './AddKeyInstance';

const KeyInstancesSection = ({ keyInformationId, keyInstances }) => {
  const { t } = useTranslation();
  const [selectedKeyInstanceId, setSelectedKeyInstanceId] = useQueryParam('selected');

  const selectedKeyInstance = useMemo(() => {
    return keyInstances.find((keyInstance) => keyInstance._id === selectedKeyInstanceId);
  }, [keyInstances, selectedKeyInstanceId]);
  const otherKeyInstances = useMemo(() => {
    return keyInstances.filter((keyInstance) => keyInstance._id !== selectedKeyInstanceId);
  }, [keyInstances, selectedKeyInstanceId]);
  useEffect(() => {
    if (!selectedKeyInstance && keyInstances.length > 0) {
      setSelectedKeyInstanceId(keyInstances[0]._id);
    }
  }, [keyInstances, selectedKeyInstance, selectedKeyInstanceId, setSelectedKeyInstanceId]);

  const canAdd = useMemo(() => {
    return !keyInstances.some((keyInstance) => keyInstance.isDraft);
  }, [keyInstances]);

  return (
    <CardsContainer>
      <CardsContainer vertical>
        <CardSectionTitle title={t('Schlüssel-ID')} />
        {selectedKeyInstance && (
          <KeyInstanceEditableCard keyInstance={selectedKeyInstance} keyInformationId={keyInformationId} />
        )}
      </CardsContainer>
      <CardsContainer vertical>
        <CardSectionTitle title={t('Weitere Schlüssel IDs')} />
        {otherKeyInstances.map((keyInstance) => (
          <KeyInstanceEditableCard
            key={keyInstance._id}
            keyInstance={keyInstance}
            keyInformationId={keyInformationId}
          />
        ))}
        {canAdd && <AddKeyInstance keyInformationId={keyInformationId} />}
      </CardsContainer>
    </CardsContainer>
  );
};

KeyInstancesSection.propTypes = {
  keyInformationId: PropTypes.string.isRequired,
  keyInstances: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
};

export default KeyInstancesSection;

import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import styles from './CardTextInput.module.scss';

const CardTextArea = ({ name, className: classNameFromProps, ...props }) => {
  const [fieldProps] = useField({
    name,
  });
  const className = `${styles.main} ${classNameFromProps}`;
  return (
    <textarea
      className={className}
      {...fieldProps}
      {...props}
    />
  );
};

CardTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CardTextArea;

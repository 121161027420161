import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardSectionTitle.module.scss';

const CardSectionTitle = ({ title }) => {
  return (
    <div className={styles.title}>
      {title}
    </div>
  );
};

CardSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CardSectionTitle;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, Form, Field } from 'formik';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EditableCard from '../EditableCard/EditableCard';
import { usePatchUserMutation } from '../drApi/drApi';
import { getUser } from '../drApi/userSelectors';
import styles from './UserGeofencingSettingsCard.module.scss';

const GeofenceSettingsSchema = Yup.object().shape({
  geofenceSettings: Yup.object().shape({
    active: Yup.boolean(),
    notifyOnEnter: Yup.boolean(),
    notifyOnExit: Yup.boolean(),
    notifyOnDwell: Yup.boolean(),
    geofenceRadius: Yup.number().min(150, 'Radius muss mindestens 150 Meter betragen').required('Radius muss angegeben werden'),
    loiteringDelay: Yup.number(),
    saveBattery: Yup.boolean(),
  }),
});

const UserGeofencingSettingsCard = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const user = useSelector(getUser(userId));
  const geofenceSettings = user?.geofenceSettings;

  const [isEditing, setIsEditing] = useState(false);
  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((values) => {
    patchUser({
      id: userId,
      patch: {
        geofenceSettings: values.geofenceSettings,
      },
    });
  }, [patchUser, userId]);

  const disabled = !isEditing || isLoading;

  return (
    <Formik
      initialValues={{
        geofenceSettings,
      }}
      validationSchema={GeofenceSettingsSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleReset,
        values,
        errors,
      }) => (
        <EditableCard
          onAccept={handleSubmit}
          onAbort={handleReset}
          isEditing={isEditing}
          isLoading={isLoading}
          onEditingChange={setIsEditing}
          title={t('Geofence Einstellungen')}
          autoHeight
        >

          <Form>
            <div role="group">

              <div className={styles.flexContainer}>
                {[{
                  fieldName: 'active',
                  label: t('Aktiv'),
                  show: true,
                  inputType: 'checkbox',
                },
                {
                  fieldName: 'notifyOnEnter',
                  label: t('Benachrichtigen beim Betreten'),
                  show: values.geofenceSettings.active,
                  inputType: 'checkbox',
                },
                {
                  fieldName: 'notifyOnExit',
                  label: t('Benachrichtigen beim Verlassen'),
                  show: values.geofenceSettings.active,
                  inputType: 'checkbox',
                },
                {
                  fieldName: 'saveBattery',
                  label: t('Batterie sparen (evtl. verzögerte Benachrichtigungen)'),
                  show: values.geofenceSettings.active,
                  inputType: 'checkbox',
                },
                {
                  fieldName: 'geofenceRadius',
                  label: t('Radius (m)'),
                  show: values.geofenceSettings.active,
                  inputType: 'textInput',
                },
                ].map(({
                  fieldName, label, show, inputType,
                }) => {
                  if (!show) {
                    return null;
                  }

                  return (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxLabel}>
                        {label}
                      </div>

                      {inputType === 'checkbox' && (
                        <div className={[styles.toggleSwitch, styles.smallSwitch].join(' ')}>
                          <Field
                            className={styles.toggleSwitchCheckbox}
                            id={`geofenceSettings.${fieldName}`}
                            type="checkbox"
                            name={`geofenceSettings.${fieldName}`}
                            disabled={disabled}
                          />
                          <label
                            htmlFor={`geofenceSettings.${fieldName}`}
                            className={styles.toggleSwitchLabel}
                            tabIndex={disabled ? -1 : 1}
                          >
                            <span
                              className={
                                disabled
                                  ? [styles.toggleSwitchInner, styles.toggleSwitchDisabled].join(' ')
                                  : styles.toggleSwitchInner
                              }
                              data-yes={t('Ja')}
                              data-no={t('Nein')}
                              tabIndex={-1}
                            />
                            <span
                              className={
                                disabled
                                  ? [styles.toggleSwitchSwitch, styles.toggleSwitchDisabled].join(' ')
                                  : styles.toggleSwitchSwitch
                              }
                              tabIndex={-1}
                            />
                          </label>

                        </div>
                      )}

                      {inputType === 'textInput' && (
                        <Field
                          name={`geofenceSettings.${fieldName}`}
                          type="number"
                          disabled={disabled}
                        />
                      )}

                    </div>
                  );
                })}

                {errors?.geofenceSettings?.geofenceRadius ? <div className={styles.error}>{errors?.geofenceSettings?.geofenceRadius}</div> : null}
              </div>

            </div>
          </Form>
        </EditableCard>
      )}
    </Formik>
  );
};

export default UserGeofencingSettingsCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './StopSelectOption.module.scss';

const StopSelectOption = ({
  data, innerRef, selectOption, isSelected,
}) => {
  const { stopName, address } = data;
  const { street, zip, place } = address || {};
  if (isSelected) {
    return null;
  }
  return (
    <button
      type="button"
      className={styles.option}
      ref={innerRef}
      onClick={() => selectOption(data)}
    >
      <div className={styles.name}>
        {stopName}
      </div>
      <div className={styles.address}>
        {street}
        {' '}
        {zip}
        {' '}
        {place}
      </div>

    </button>
  );
};

StopSelectOption.propTypes = {
  data: PropTypes.shape({
    stopName: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      zip: PropTypes.string,
      place: PropTypes.string,
    }),
  }).isRequired,
  innerRef: PropTypes.func,
  selectOption: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default StopSelectOption;

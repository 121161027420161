import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FilterSelect from '../../FilterSelect/FilterSelect';
import styles from './RoutesFilterPopup.module.scss';
import useQueryParam from '../../url/useQueryParam';

export const INFO_TYPES = {
  REMARK: 'REMARK',
  IMAGE: 'IMAGE',
  OFFLOAD_TIME: 'OFFLOAD_TIME',
  KEY: 'KEY',
  PIN_CODE: 'PIN_CODE',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

export const INFO_TYPES_MAPPINGS = {
  [INFO_TYPES.REMARK]: 'Bemerkung',
  [INFO_TYPES.IMAGE]: 'Bild',
  [INFO_TYPES.PHONE_NUMBER]: 'Telefonnummern',
  [INFO_TYPES.OFFLOAD_TIME]: 'Zeiten',
  [INFO_TYPES.KEY]: 'Schlüssel',
  [INFO_TYPES.PIN_CODE]: 'Infos zu Codes',
};

const InfoFilterPopup = () => {
  const { t } = useTranslation();
  const [infos, setInfos] = useQueryParam('infos');
  const onChange = useCallback(
    (newInfos) => {
      setInfos(newInfos);
    },
    [setInfos],
  );

  const infoOptions = Object.entries(INFO_TYPES_MAPPINGS).map(([value, name]) => ({
    name: t(name),
    value,
  }));

  return (
    <div className={styles.main}>
      <FilterSelect options={infoOptions} onChange={onChange} search={false} value={infos || []} multiple />
    </div>
  );
};

InfoFilterPopup.propTypes = {};

export default InfoFilterPopup;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import JSURL from 'jsurl';
import { useTranslation } from 'react-i18next';
import IconBadge from '../IconBadge/IconBadge';
import styles from './KeyInstanceElement.module.scss';
import { ReactComponent as LinkIcon } from '../../img/link_external.svg';
import Card from '../cards/Card';
import KeyIdBadge from '../KeyIdBadge/KeyIdBadge';
import { KEY_POSITION_TYPES_MAPPINGS } from '../drApi/constants/keyPositionType';

const KeyInstanceElement = ({ keyInstance }) => {
  const { t } = useTranslation();
  const { _id, keyInformationId, name, keyUser, currentPosition } = keyInstance;
  const keyUserName = keyUser?.name;

  const keyProperties = [
    {
      icon: 'key',
      content: <KeyIdBadge name={name} />,
    },
    {
      icon: 'keyUser',
      content: keyUserName,
    },
    {
      icon: 'crosshair',
      content: t(KEY_POSITION_TYPES_MAPPINGS[currentPosition]?.human),
    },
  ];

  return (
    <Card className={styles.card}>
      <Link to={`/keys/${keyInformationId}?selected=${JSURL.stringify(_id)}`} className={styles.iconBar}>
        <LinkIcon className={styles.linkIcon} />
      </Link>
      {keyProperties.map(({ icon, content }) => {
        if (!content) {
          return null;
        }
        return (
          <div key={icon} className={styles.keyProperty}>
            <IconBadge icon={icon} />
            <div className={styles.keyValue}>{content}</div>
          </div>
        );
      })}
    </Card>
  );
};

KeyInstanceElement.propTypes = {
  keyInstance: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    keyInformationId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    currentPosition: PropTypes.string.isRequired,
    keyUser: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
};

export default KeyInstanceElement;

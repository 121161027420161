import PropTypes from 'prop-types';
import AnyChildren from '../../propTypes/AnyChildren';
import styles from './BlockClickPropagation.module.scss';

const BlockClickPropagation = ({ children, className: classNameFromProps = '', ...props }) => {
  const className = `${classNameFromProps} ${styles.main}`;
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/react-in-jsx-scope
    <div
      onClick={(event) => { event.stopPropagation(); }}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
};

BlockClickPropagation.propTypes = {
  className: PropTypes.string,
  children: AnyChildren,
};

export default BlockClickPropagation;

import React from 'react';
import PropTypes from 'prop-types';
import rLStyles from './RecordsList.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const RLTable = ({ children, className = '', ...props }) => {
  const classNames = `${className} ${rLStyles.table}`;
  return <table className={classNames} {...props}>{children}</table>;
};

RLTable.propTypes = {
  children: AnyChildren,
  className: PropTypes.string,
};

export default RLTable;

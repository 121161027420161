import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FilterIcon } from '../../img/filter.svg';
import styles from './RLFilterIcon.module.scss';

const RLFilterIcon = forwardRef(({ active = false, onClick }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      className={styles.iconWrapper}
    >
      <FilterIcon className={styles.icon} />
      {active && <div className={styles.activeDot} /> }
    </button>
  );
});

RLFilterIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};
export default RLFilterIcon;

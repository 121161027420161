import { toast } from 'react-toastify';
import i18n from '../../i18next/i18next';

export const successToast = ({ text }) => () => {
  toast.success(i18n.t(text));
};

export const errorToast = ({ text }) => () => {
  toast.error(i18n.t(text));
};

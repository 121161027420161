import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './ReviewBar.module.scss';
import { ReactComponent as ClockIcon } from '../../img/clock_red.svg';
import { getElementChangeProperties } from '../drApi/stopSelectors';
import { useReviewElementMutation } from '../drApi/drApi';
import DeleteModal from '../DeleteModal/DeleteModal';
import { ReactComponent as LoaderIcon } from '../../img/loader.svg';

const ReviewBar = ({ stopId, cardType, elementId, onEdit, disableEdit = false }) => {
  const { ex, acceptLabel, denyLabel, canEdit, confirmDenyTitle, confirmDenyText, changeId, changeType } = useSelector(
    getElementChangeProperties(stopId, cardType, elementId),
  );

  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [reviewElement, { isLoading }] = useReviewElementMutation();

  const onAccept = useCallback(() => {
    reviewElement({
      stopId,
      cardType,
      elementId,
      changeType,
      changeId,
      result: 'confirm',
    });
  }, [cardType, changeId, changeType, elementId, reviewElement, stopId]);

  const confirmDeny = useCallback(() => {
    reviewElement({
      stopId,
      cardType,
      elementId,
      changeType,
      changeId,
      result: 'deny',
    });
  }, [cardType, changeId, changeType, elementId, reviewElement, stopId]);

  const onDeny = useCallback(() => {
    if (!confirmDenyText) {
      confirmDeny();
    } else {
      setConfirmModalOpen(true);
    }
  }, [confirmDeny, confirmDenyText]);

  const closeModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.upper}>
          <ClockIcon className={styles.icon} />
          <div className={styles.ex}>{ex}</div>
        </div>
        <div className={styles.buttons}>
          {isLoading && <LoaderIcon className={styles.loader} />}
          <button type="button" className={styles.accept} onClick={onAccept}>
            {acceptLabel}
          </button>
          {!disableEdit && canEdit && (
            <button type="button" className={styles.edit} onClick={onEdit}>
              {t('Bearbeiten')}
            </button>
          )}
          <button type="button" className={styles.deny} onClick={onDeny}>
            {denyLabel}
          </button>
        </div>
      </div>
      {confirmDenyText && (
        <DeleteModal
          onConfirm={confirmDeny}
          onClose={closeModal}
          isOpen={confirmModalOpen}
          title={confirmDenyTitle}
          content={confirmDenyText}
        />
      )}
    </>
  );
};

ReviewBar.propTypes = {
  stopId: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  disableEdit: PropTypes.bool,
};
export default ReviewBar;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AddButton from '../detailsPageComponents/AddButton';
import addKeyInstance from './thunks/addKeyInstance';

const AddKeyInstance = ({ keyInformationId }) => {
  const dispatch = useDispatch();
  const handleAdd = useCallback(() => {
    dispatch(addKeyInstance({ keyInformationId }));
  }, [dispatch, keyInformationId]);
  return <AddButton onClick={handleAdd} inMainSection={false} />;
};

AddKeyInstance.propTypes = {
  keyInformationId: PropTypes.string.isRequired,
};

export default AddKeyInstance;

import { Popover } from 'react-tiny-popover';
import { useHistory } from 'react-router-dom';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import JSURL from 'jsurl';
import { useTranslation } from 'react-i18next';
import RLCell from '../recordsList/RLCell';
import { ReactComponent as StopsNeedle } from '../../img/pin_small.svg';
import styles from './RoutesTable.module.scss';
import RoundInitialsButton from '../RoundInitialsButton/RoundInitialsButton';
import ChangeUser, { ChangeUserType } from './ChangeUser';
import rLStyles from '../recordsList/RecordsList.module.scss';
import { ReactComponent as TrashIcon } from '../../img/bin_red.svg';

import RLRow from '../recordsList/RLRow';
import BlockClickPropagation from '../BlockClickPropagation/BlockClickPropagation';
import DeleteModal from '../DeleteModal/DeleteModal';
import { useDeleteRouteMutation } from '../drApi/drApi';

const RouteRow = ({ route }) => {
  const {
    _id, name, stops, assignedUsers, permanentUser: permanentUserProp,
  } = route;

  const { t } = useTranslation();
  const [deleteRoute] = useDeleteRouteMutation();

  const [isPopoverOpen, setIsPopoverOpen] = useState({ assignedUser: false, permanentUser: false });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const history = useHistory();

  const handleDelete = (e) => {
    e.stopPropagation();
    setDeleteModalOpen(true);
  };

  const onDeleteConfirm = useCallback(async () => {
    setDeleteModalOpen(false);
    deleteRoute({ routeId: _id });
  }, [_id, deleteRoute]);

  const onDeleteAbort = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  return (
    <>
      <RLRow
        onClick={() => {
          history.push(`/stops?routes=${JSURL.stringify([name])}`);
        }}
        role="button"
      >
        <RLCell>{name}</RLCell>
        <RLCell>
          <StopsNeedle className={styles.stopsNeedle} />
          {stops}
          {' '}
          {stops === 1 ? t('Stop') : t('Stops')}
        </RLCell>
        <RLCell right>
          <BlockClickPropagation>
            {assignedUsers.map((assignedUser) => {
              return (
                <RoundInitialsButton
                  userId={assignedUser._id}
                  name={assignedUser.name}
                  key={assignedUser._id}
                  className={styles.initials}
                />
              );
            })}
          </BlockClickPropagation>
        </RLCell>
        <RLCell>
          <BlockClickPropagation>
            <Popover
              positions={['bottom', 'top']}
              onClickOutside={() => setIsPopoverOpen((pS) => ({ ...pS, assignedUser: false }))}
              isOpen={isPopoverOpen.assignedUser}
              content={<ChangeUser routeId={_id} changeType={ChangeUserType.ASSIGNED} />}
            >
              <button
                type="button"
                onClick={() => setIsPopoverOpen((pS) => ({ assignedUser: !pS.assignedUser, permanentUser: false }))}
                className={rLStyles.link}
              >
                {t('Mitarbeiter ändern')}
              </button>
            </Popover>
          </BlockClickPropagation>

        </RLCell>
        <RLCell right>
          <BlockClickPropagation>
            {permanentUserProp && (
            <RoundInitialsButton
              userId={permanentUserProp._id}
              name={permanentUserProp.name}
              className={styles.initials}
            />
            )}
          </BlockClickPropagation>
        </RLCell>
        <RLCell>
          <BlockClickPropagation>
            <Popover
              positions={['bottom', 'top']}
              onClickOutside={() => setIsPopoverOpen((pS) => ({ ...pS, permanentUser: false }))}
              isOpen={isPopoverOpen.permanentUser}
              content={<ChangeUser routeId={_id} changeType={ChangeUserType.PERMANENT} />}
            >
              <button
                type="button"
                onClick={() => setIsPopoverOpen((pS) => ({ permanentUser: !pS.permanentUser, assignedUser: false }))}
                className={rLStyles.link}
              >
                {t('Stammmitarbeiter ändern')}
              </button>
            </Popover>
          </BlockClickPropagation>

        </RLCell>
        <RLCell onClick={handleDelete} className={rLStyles.actionCell}>
          <TrashIcon />
        </RLCell>
      </RLRow>

      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={onDeleteAbort}
        onConfirm={onDeleteConfirm}
        title={t('Tour löschen')}
        content={t('Wollen sie die Tour {{name}} wirklich löschen?', { name })}
      />
    </>
  );
};

RouteRow.propTypes = {
  route: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    stops: PropTypes.number.isRequired,
    assignedUsers: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    permanentUser: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default RouteRow;

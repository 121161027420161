import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { KEY_POSITION_TYPES_MAPPINGS } from '../drApi/constants/keyPositionType';

const customSelectStyles = {
  control: (provided, { isDisabled }) => ({
    ...provided,
    borderRadius: '2px',
    boxShadow: isDisabled ? 'none' : 'inset 0 0 3px 0 rgba(0, 0, 0, 0.12), inset 0 1px 3px 0 rgba(0, 0, 0, 0.31)',
    border: isDisabled ? 'none' : 'solid 1px rgba(151, 151, 151, 0.32)',
    minHeight: '27px',
    height: '27px',
    fontSize: '12px',
    background: 'transparent',
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'black',
    };
  },
  dropdownIndicator: (provided, { isDisabled }) => {
    return {
      ...provided,
      padding: '0px',
      display: isDisabled ? 'none' : provided.display,
    };
  },
};

const KeyPositionSelect = ({ value, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const options = Object.keys(KEY_POSITION_TYPES_MAPPINGS).map((key) => ({
    value: key,
    label: t(KEY_POSITION_TYPES_MAPPINGS[key]?.human),
  }));

  const selectValue = useMemo(() => {
    return options.find((option) => option.value === value) || options[0];
  }, [options, value]);
  return (
    <Select
      options={options}
      value={selectValue}
      onChange={(newOption) => { onChange(newOption?.value); }}
      components={{
        IndicatorSeparator: null,
        // DropdownIndicator: null,
      }}
      isClearable={false}
      backspaceRemovesValue={false}
      styles={customSelectStyles}
      isSearchable={false}
      isDisabled={disabled}
    />
  );
};

KeyPositionSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default KeyPositionSelect;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const TimeSeriesChart = ({ data = [], title = 'Time Series' }) => {
  const options = useMemo(() => ({
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  }), [title]);

  return <Line data={data} options={options} />;
};

TimeSeriesChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
};

export default TimeSeriesChart;

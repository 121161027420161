import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import RLHeaderCell from '../../recordsList/RLHeaderCell';
import RoutesFilterPopup from './RoutesFilterPopup';
import useQueryParam from '../../url/useQueryParam';
import RLFilterIcon from '../../recordsList/RLFilterIcon';
import styles from './RoutesFilterHeader.module.scss';

const RoutesFilterHeader = () => {
  const { t } = useTranslation();
  const [filterOpen, setFilterOpen] = useState(false);
  const closeFilter = () => {
    setFilterOpen(false);
  };
  const openFilter = () => {
    setFilterOpen(true);
  };
  const [routes] = useQueryParam('routes');
  const hasSelectedRoutes = routes?.length > 0;
  return (
    <RLHeaderCell right>
      <div className={styles.desc}>{t('Tour')}</div>
      <Popover positions={['bottom']} padding={10} onClickOutside={closeFilter} isOpen={filterOpen} content={<RoutesFilterPopup />}>
        <RLFilterIcon active={hasSelectedRoutes} onClick={filterOpen ? closeFilter : openFilter} />
      </Popover>
    </RLHeaderCell>
  );
};

export default RoutesFilterHeader;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as JSURL from 'jsurl';
import staticGetAccessToken from './staticGetAccessToken';
import { successToast, errorToast } from '../toasts/toastsThunks';
import { calculateStopHumanAddress } from './stopUtils';
import i18n from '../../i18next/i18next';

const { getFn } = staticGetAccessToken;

const { REACT_APP_API_BASE_URL } = process.env;
export const drApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_API_BASE_URL}/`,
    prepareHeaders: async (headers) => {
      const getAccessToken = getFn();
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: [
    'Me',
    'Routes',
    'Users',
    'Stops',
    'UnconfirmedCount',
    'KeyInformations',
    'KeyInstances',
    '_helper_sync_unconfirmed_count',
  ],
  refetchOnReconnect: true,
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getMe: build.query({
      query: () => ({ url: 'me' }),
      transformResponse: (response) => response.me,
      providesTags(result) {
        if (!result) {
          return [];
        }
        const { _id: id } = result;
        return [{ type: 'Users', id }];
      },
    }),
    getRoutes: build.query({
      query: (houseId) => ({ url: `get_routes?houseId=${houseId}` }),
      transformResponse: (response) => response.routes,
      providesTags(result) {
        if (!result) {
          // an error occurred, but we still want to refetch
          // this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
          return [{ type: 'Routes', id: 'LIST' }];
        }
        return [...result.map(({ _id }) => ({ type: 'Routes', id: _id })), { type: 'Routes', id: 'LIST' }];
      },
    }),
    getUsers: build.query({
      query: (houseId) => ({ url: `get_users?limit=9999&view=admin&houseId=${houseId}` }),
      transformResponse: (response) => {
        const { users } = response;
        return users.map((u) => ({
          permanentTourName: u.permanentTour?.name,
          ...u,
        }));
      },
      providesTags(result) {
        if (!result) {
          // an error occurred, but we still want to refetch
          // this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
          return [{ type: 'Users', id: 'LIST' }];
        }
        return [...result.map(({ _id }) => ({ type: 'Users', id: _id })), { type: 'Users', id: 'LIST' }];
      },
    }),
    getUnconfirmedStops: build.query({
      query: (houseId) => ({ url: `unconfirmedStops?houseId=${houseId}` }),
      transformResponse: (response) => {
        const { unconfirmedStops, stops } = response;
        return unconfirmedStops.map((unconfirmed) => {
          const { _id: stopId } = unconfirmed;
          const stop = stops.find(({ _id }) => _id === stopId);
          let changeDate = null;
          let authorName = null;
          outer: for (const card of stop.cards) {
            for (const element of card.elements) {
              if (element.unconfirmedChanges?.length > 0) {
                changeDate = element.unconfirmedChanges[0].createdAt;
                authorName = element.unconfirmedChanges[0].issuerName;
                break outer;
              }
            }
          }
          return {
            ...unconfirmed,
            authorName,
            changeDate,
          };
        });
      },
      providesTags: ['UnconfirmedCount'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          // unconfirmedCount should always be reloaded when unconfirmedStops is reloaded
          drApi.util.invalidateTags(['_helper_sync_unconfirmed_count']),
        );
      },
    }),
    getUnconfirmedCount: build.query({
      query: (houseId) => ({ url: `unconfirmedCount?houseId=${houseId}` }),
      transformResponse: (response) => response.unconfirmedCount,
      providesTags: ['UnconfirmedCount', '_helper_sync_unconfirmed_count'],
    }),
    getStops: build.query({
      query: ({ houseId, pageSize, page, routesFilter, infoFilter, searchString }) => {
        let url = `get_stops?view=admin&page_size=${pageSize}&page=${page}&houseId=${houseId}&search=${encodeURIComponent(
          searchString,
        )}`;
        if (routesFilter) {
          url += `&routes=${JSURL.stringify(routesFilter)}`;
        }
        if (infoFilter) {
          url += `&infos=${JSURL.stringify(infoFilter)}`;
        }
        return { url };
      },
      transformResponse: (response) => {
        if (!response) {
          return null;
        }
        const { stops, totalItems } = response;
        return {
          totalItems,
          stops: stops?.map((stop) => {
            return {
              ...stop,
              humanAddress: calculateStopHumanAddress(stop),
            };
          }),
        };
      },
      providesTags(result) {
        return result?.stops?.map(({ _id }) => ({ type: 'Stops', id: _id })) || [];
      },
    }),
    getStopOptions: build.query({
      query: ({ houseId, searchString }) => {
        const url = `get_stops?search=${encodeURIComponent(searchString)}&houseId=${houseId}`;
        return { url };
      },
      transformResponse: (response) => {
        return response?.stops.map((stop) => ({
          ...stop,
          stopName: stop.stopName || stop.customerName,
        }));
      },
      providesTags(result) {
        return result?.map(({ _id }) => ({ type: 'Stops', id: _id })) || [];
      },
    }),
    getStop: build.query({
      query: ({ stopId }) => ({ url: `stop/${stopId}?view=admin` }),
      transformResponse: (response) => response.stop,
      providesTags: (result) => {
        if (!result) {
          return [];
        }
        return [{ type: 'Stops', id: result?._id }];
      },
    }),
    getKeyInstances: build.query({
      query: (houseId) => ({ url: `keyinstances?houseId=${houseId}` }),
      transformResponse: (response) => response.keyInstances,
      providesTags: (result) => {
        if (!result) {
          return [];
        }
        const keyInstanceTags = result?.map(({ _id }) => ({ type: 'KeyInstances', id: _id })) || [];
        const keyInformationIds = [
          ...new Set(
            result?.map(({ keyInformationId }) => {
              return keyInformationId;
            }) || [],
          ),
        ];
        const keyInformationTags =
          keyInformationIds.map((keyInformationId) => ({ type: 'KeyInformations', id: keyInformationId })) || [];
        return [
          ...keyInstanceTags,
          ...keyInformationTags,
          { type: 'KeyInstances', id: 'LIST' },
          { type: 'KeyInformations', id: 'LIST' },
        ];
      },
    }),
    getKeyInformation: build.query({
      query: ({ keyInformationId }) => ({ url: `keyinformation/${keyInformationId}` }),
      transformResponse: (response) => {
        return {
          ...response,
          keyInformation: {
            ...response.keyInformation,
            stops:
              response.stops?.map((stop) => {
                return {
                  ...stop,
                  stopName: stop.stopName || stop.customerName,
                };
              }) || [],
          },
        };
      },
      providesTags: (result) => {
        if (!result) {
          return [];
        }
        const keyInstanceTags =
          result?.keyInstances?.map(({ _id }) => ({
            type: 'KeyInstances',
            id: _id,
          })) || [];
        return [...keyInstanceTags, { type: 'KeyInformations', id: result?.keyInformation?._id }];
      },
    }),
    getKeyActionLog: build.query({
      query: ({ keyInformationId, start, end }) => ({
        url: `keyactionlog/${keyInformationId}?start=${start}&end=${end}`,
      }),
    }),
    getDashboardRoutes: build.query({
      query: ({ start, end }) => ({ url: `get_dashboard_routes?start=${start}&end=${end}` }),
      transformResponse: (response) => response.dashboard,
    }),
    getDashboardCards: build.query({
      query: ({ start, end }) => ({ url: `get_dashboard_cards?start=${start}&end=${end}` }),
      transformResponse: (response) => response.dashboard,
    }),
    getDashboardUsers: build.query({
      query: ({ start, end }) => ({ url: `get_dashboard_users?start=${start}&end=${end}` }),
      transformResponse: (response) => response.dashboard,
    }),
    getDashboardKeyActions: build.query({
      query: ({ start, end }) => ({ url: `get_dashboard_key_actions?start=${start}&end=${end}` }),
      transformResponse: (response) => response.dashboard,
    }),
    getHouses: build.query({
      query: () => ({ url: 'get_houses' }),
      transformResponse: (response) => response.houses,
    }),
    getImportLog: build.query({
      query: ({ start, end }) => ({ url: `get_importlog?start=${start}&end=${end}` }),
      transformResponse: (response) => response.importlog,
    }),
    getS3UploadData: build.mutation({
      query({ filename, usePresignedPost }) {
        return {
          url: '/stop/generate_upload_image_url',
          method: 'POST',
          body: {
            filename,
            usePresignedPost,
          },
        };
      },
    }),
    updateKeyInformation: build.mutation({
      query({ keyInformationId, updateFieldsInformation, updateFieldsInstances, newKeyInstances, stopIds }) {
        // for some reason, for updateKeyInformation the backend wants the newKeyInstances in
        // the form of an object with arbitrary keys (unlike the addKeyInformation mutation)
        const newKeyInstancesWeirdFormat = newKeyInstances?.reduce((red, keyInstance, index) => {
          return {
            ...red,
            [index]: keyInstance,
          };
        }, {});
        return {
          url: `key/change/${keyInformationId}`,
          method: 'POST',
          body: {
            updateFieldsInformation: updateFieldsInformation || {},
            updateFieldsInstances: updateFieldsInstances || {},
            newKeyInstances: newKeyInstancesWeirdFormat || {},
          },
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.msg }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const stopIds = args?.stopIds ?? {};

        const stopTags = Object.values(stopIds).map((stop) => ({
          type: 'Stops',
          id: stop.newStop,
        }));

        const { keyInformationId } = args;
        return [{ type: 'KeyInformations', id: keyInformationId }, ...stopTags];
      },
    }),
    addKeyInformation: build.mutation({
      query({ body }) {
        return {
          url: 'key/add',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.msg }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const stopIds = args?.body?.newKeyInformation?.stopIds ?? {};

        const stopTags = Object.values(stopIds).map((stop) => ({
          type: 'Stops',
          id: stop.newStop,
        }));

        return [{ type: 'KeyInformations', id: 'LIST' }, { type: 'KeyInstances', id: 'LIST' }, ...stopTags];
      },
    }),
    deleteKeyInformation: build.mutation({
      query({ keyInformationId }) {
        return {
          url: 'key/delete',
          method: 'DELETE',
          body: {
            keyInformationId,
          },
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.msg }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        return [
          { type: 'KeyInformations', id: 'LIST' },
          { type: 'KeyInstances', id: 'LIST' },
        ];
      },
    }),
    deleteKeyInstance: build.mutation({
      query({ _, keyInstanceId }) {
        return {
          url: 'keyinstance/delete',
          method: 'DELETE',
          body: {
            keyInstanceId,
          },
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.msg }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { keyInformationId } = args;
        return [
          { type: 'KeyInformations', id: keyInformationId },
          { type: 'KeyInstances', id: 'LIST' },
        ];
      },
    }),
    patchRoute: build.mutation({
      query({ id, patch }) {
        return {
          url: `route/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(successToast({ text: i18n.t('Stammmitarbeiter upgedated') }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { routeId } = args;
        return [{ type: 'Routes', id: routeId }];
      },
    }),
    deleteRoute: build.mutation({
      query({ routeId }) {
        return {
          url: `route/${routeId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(successToast({ text: i18n.t('Tour wurde erfolgreich gelöscht') }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Tour konnte nicht gelöscht werden') }));
        }
      },
      invalidatesTags: [{ type: 'Routes', id: 'LIST' }],
    }),
    deleteUser: build.mutation({
      query({ userId }) {
        return {
          url: `user/${userId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(successToast({ text: i18n.t('Nutzer wurde erfolgreich gelöscht') }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Nutzer konnte nicht gelöscht werden') }));
        }
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    patchUser: build.mutation({
      query({ id, patch }) {
        return {
          url: `update_user/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(successToast({ text: i18n.t('User upgedated') }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { id } = args;
        return [{ type: 'Users', id }];
      },
    }),
    updateElement: build.mutation({
      query({ stopId, cardType, elementId, updateFields, activeChangeId = undefined }) {
        return {
          url: `/stop/${stopId}/change_element/${cardType}/${elementId}`,
          method: 'POST',
          body: {
            activeChangeId,
            updateFields,
          },
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.ackMessage }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { stopId } = args;
        return [{ type: 'Stops', stopId }, 'UnconfirmedCount'];
      },
    }),
    addElement: build.mutation({
      query({ stopId, cardType, body, houseId }) {
        return {
          url: `/stop/${stopId}/add_element/${cardType}?houseId=${houseId}`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.ackMessage }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { stopId } = args;
        return [{ type: 'Stops', stopId }];
      },
    }),
    createUser: build.mutation({
      query({ body }) {
        return {
          url: 'create_user',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted({ id, patch }, { dispatch, _, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(successToast({ text: i18n.t('User erstellt') }));
        } catch (e) {
          dispatch(
            errorToast({ text: e?.error?.data?.message || i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }),
          );
        }
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    deleteElement: build.mutation({
      query({ stopId, cardType, elementId }) {
        return {
          url: `/stop/${stopId}/delete_element/${cardType}/${elementId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.ackMessage }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { stopId } = args;
        return [{ type: 'Stops', stopId }, 'UnconfirmedCount'];
      },
    }),
    reviewElement: build.mutation({
      query({ stopId, cardType, elementId, changeId, changeType, result }) {
        if (!['deny', 'confirm'].includes(result)) {
          throw new Error('review result must be deny or confirm');
        }
        return {
          url: `/stop/${stopId}/review_element/${cardType}/${elementId}/${changeId}/${changeType}/${result}`,
          method: 'POST',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(successToast({ text: response?.data?.ackMessage }));
        } catch {
          dispatch(errorToast({ text: i18n.t('Unbekannter Fehler. Versuchen Sie es noch einmal') }));
        }
      },
      invalidatesTags(result, _, args) {
        const { stopId } = args;
        return [{ type: 'Stops', stopId }, 'UnconfirmedCount'];
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetRoutesQuery,
  useGetUsersQuery,
  useGetUnconfirmedStopsQuery,
  useGetUnconfirmedCountQuery,
  useGetStopsQuery,
  useGetStopOptionsQuery,
  useGetStopQuery,
  useGetKeyInstancesQuery,
  useGetKeyInformationQuery,
  useGetKeyActionLogQuery,
  useGetHousesQuery,
  useGetImportLogQuery,
  useAddKeyInformationMutation,
  useUpdateKeyInformationMutation,
  useDeleteKeyInformationMutation,
  useDeleteKeyInstanceMutation,
  usePatchRouteMutation,
  usePatchUserMutation,
  useUpdateElementMutation,
  useAddElementMutation,
  useCreateUserMutation,
  useDeleteElementMutation,
  useReviewElementMutation,
  useGetDashboardRoutesQuery,
  useGetDashboardCardsQuery,
  useGetDashboardUsersQuery,
  useGetDashboardKeyActionsQuery,
  useDeleteRouteMutation,
  useDeleteUserMutation,
  useGetS3UploadDataMutation,
} = drApi;

import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLTable from '../recordsList/RLTable';
import RLTableLoader from '../recordsList/RLTableLoader';
import RLSortHeader from '../recordsList/RLSortHeader';
import DashboardTableRow from './DashboardTableRow';
import i18next from '../../i18next/i18next';

const columns = [
  {
    key: '_id', header: 'ID', hidden: true, primaryKey: true,
  },
  { key: 'lda', header: 'LDA', sortable: true },
  { key: 'bst', header: 'BST', sortable: true },
  { key: 'name', header: i18next.t('Haus'), sortable: true },
  { key: 'count', header: 'Anzahl Nutzer', sortable: true },
];

const ActiveUsersTable = ({
  data,
  headers,
  isLoading = false,
}) => {
  return (
    <>
      {isLoading
        ? <RLTableLoader />
        : (
          <RLTable>
            <thead>
              <RLHeaderRow>
                {columns.map((col) => {
                  if (col.hidden) return null;
                  return <RLSortHeader key={col.key} {...headers[col.key]} />;
                })}
              </RLHeaderRow>
            </thead>
            <tbody>
              {data.map((row) => {
                const { _id } = row;
                return <DashboardTableRow key={_id} row={row} columns={columns} />;
              })}
            </tbody>
          </RLTable>
        )}
    </>
  );
};

ActiveUsersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  headers: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
};

export default sematable('ActiveUsersTable', ActiveUsersTable, columns, {
  plain: true,
  defaultPageSize: 9999,
  sortKey: 'count',
  sortDirection: 'desc',
});

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, Form, Field } from 'formik';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditableCard from '../EditableCard/EditableCard';
import { usePatchUserMutation } from '../drApi/drApi';
import { getMyId } from '../drApi/meSelectors';
import { getUserPermissions, getUserHasPermission } from '../drApi/userSelectors';
import { PERMISSIONS } from '../drApi/constants/permissions';
import styles from './UserPermissionCard.module.scss';

const permissionsList = [
  {
    label: 'Admin',
    value: PERMISSIONS.GLOBAL_ADMIN,
  },
  {
    label: 'Dashboard',
    value: PERMISSIONS.DASHBOARD,
  },
];

const UserPermissionCard = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const myId = useSelector(getMyId);
  const isGlobalAdmin = useSelector(getUserHasPermission(PERMISSIONS.GLOBAL_ADMIN, myId));
  const userPermissions = useSelector(getUserPermissions(userId));

  const [isEditing, setIsEditing] = useState(false);

  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((values) => {
    patchUser({
      id: userId,
      patch: {
        permissions: values.userPermissions,
      },
    });
  }, [patchUser, userId]);

  const disabled = !isEditing || isLoading;

  return (
    <Formik
      initialValues={{
        userPermissions,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleReset,
      }) => (
        <EditableCard
          onAccept={handleSubmit}
          onAbort={handleReset}
          isEditing={isEditing}
          isLoading={isLoading}
          onEditingChange={setIsEditing}
          canEdit={isGlobalAdmin}
          title={t('Berechtigungen')}
          autoHeight
        >

          <Form>
            <div role="group">
              {permissionsList.map((el) => (
                <div key={el.label} className={styles.flexContainer}>
                  <div>
                    {el.label}
                  </div>
                  <div className={[styles.toggleSwitch, styles.smallSwitch].join(' ')}>
                    <Field
                      className={styles.toggleSwitchCheckbox}
                      id={el.label}
                      type="checkbox"
                      name="userPermissions"
                      value={el.value}
                      disabled={disabled}
                    />
                    <label
                      htmlFor={el.label}
                      className={styles.toggleSwitchLabel}
                      tabIndex={disabled ? -1 : 1}
                    >
                      <span
                        className={
                          disabled
                            ? [styles.toggleSwitchInner, styles.toggleSwitchDisabled].join(' ')
                            : styles.toggleSwitchInner
                        }
                        data-yes={t('Ja')}
                        data-no={t('Nein')}
                        tabIndex={-1}
                      />
                      <span
                        className={
                          disabled
                            ? [styles.toggleSwitchSwitch, styles.toggleSwitchDisabled].join(' ')
                            : styles.toggleSwitchSwitch
                        }
                        tabIndex={-1}
                      />
                    </label>

                  </div>

                </div>
              ))}

            </div>
          </Form>
        </EditableCard>
      )}
    </Formik>
  );
};

export default UserPermissionCard;

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetStopOptionsQuery } from '../drApi/drApi';
import StopSelectOption from './StopSelectOption';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const customSelectStyles = {
  control: (provided, { isDisabled }) => ({
    ...provided,
    borderRadius: '2px',
    boxShadow: isDisabled ? 'none' : 'inset 0 0 3px 0 rgba(0, 0, 0, 0.12), inset 0 1px 3px 0 rgba(0, 0, 0, 0.31)',
    border: isDisabled ? 'none' : 'solid 1px rgba(151, 151, 151, 0.32)',
    minHeight: '30px',
    height: '30px',
    fontSize: '12px',
    cursor: isDisabled ? 'normal' : 'text',
    background: 'transparent',
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'black',
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      whiteSpace: 'nowrap',
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      padding: '0px',
    };
  },
  loadingIndicator: (provided, { isDisabled }) => {
    return {
      ...provided,
      display: isDisabled ? 'none' : provided.display,
    };
  },
};
const StopSelect = ({
  value, onChange, disabled = false, excludeStopIds = [],
}) => {
  const [searchString, setSearchString] = useState('');
  const houseId = useSelector(getGloballySelectedHouseId);
  const { data, isFetching } = useGetStopOptionsQuery({ houseId, searchString });
  const { t } = useTranslation();

  const getOptionLabel = useCallback(
    (stop) => stop.stopName,
    [],
  );

  const getOptionValue = useCallback(
    (stop) => stop._id,
    [],
  );

  const filteredOptions = useMemo(() => {
    return data?.filter((stop) => !excludeStopIds.includes(stop._id));
  }, [data, excludeStopIds]);
  return (
    <Select
      isLoading={isFetching}
      defaultInputValue={searchString}
      onInputChange={setSearchString}
      options={filteredOptions}
      placeholder={t('Suche einen Stop...')}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      components={{
        Option: StopSelectOption,
        IndicatorSeparator: null,
        DropdownIndicator: null,
      }}
      isClearable={false}
      backspaceRemovesValue={false}
      styles={customSelectStyles}
      menuPortalTarget={document.body}
      isDisabled={disabled}
    />
  );
};

StopSelect.propTypes = {
  value: PropTypes.shape({
    _id: PropTypes.string,
    stopName: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  excludeStopIds: PropTypes.arrayOf(PropTypes.string),
};

export default StopSelect;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from '../drApi/constants/pagination';

const initialState = {

  paginationInfo: {
    // [tableName]: paginationInfo
  },
};

const defaultPaginationInfo = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  totalItems: 50,
};

const createSubstateIfNotExists = (state, tableName) => {
  if (!state.paginationInfo[tableName]) {
    state.paginationInfo[tableName] = { ...defaultPaginationInfo };
  }
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    goToNextPage(state, action) {
      const { tableName } = action.payload;
      createSubstateIfNotExists(state, tableName);
      state.paginationInfo[tableName].page += 1;
    },
    goToPrevPage(state, action) {
      const { tableName } = action.payload;
      createSubstateIfNotExists(state, tableName);
      state.paginationInfo[tableName].page -= 1;
    },
    goToPage(state, action) {
      const { tableName, page } = action.payload;
      createSubstateIfNotExists(state, tableName);
      state.paginationInfo[tableName].page = page;
    },
    setTotalItems(state, action) {
      const { tableName, totalItems } = action.payload;
      createSubstateIfNotExists(state, tableName);
      state.paginationInfo[tableName].totalItems = totalItems;
    },
    resetPagination(state, action) {
      const { tableName } = action.payload;
      state.paginationInfo[tableName] = { ...defaultPaginationInfo };
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  goToNextPage,
  goToPrevPage,
  goToPage,
  setTotalItems,
  resetPagination,
} = paginationSlice.actions;

export default paginationSlice.reducer;

export const KEY_POSITION_TYPES = {
  CABINET: 'CABINET',
  LOGISTICS_CENTER: 'LOGISTICS_CENTER',
  ON_ROAD: 'ON_ROAD',
};

export const KEY_POSITION_TYPES_MAPPINGS = {
  [KEY_POSITION_TYPES.CABINET]: {
    human: 'Schrank',
  },
  [KEY_POSITION_TYPES.LOGISTICS_CENTER]: {
    human: 'Logistikzentrum',
  },
  [KEY_POSITION_TYPES.ON_ROAD]: {
    human: 'LKW',
  },
};

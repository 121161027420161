import React from 'react';
import { useSelector } from 'react-redux';
import { useGetUnconfirmedStopsQuery } from '../drApi/drApi';
import UnconfirmedStopsTable from './UnconfirmedStopsTable';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const UnconfirmedStops = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading, data } = useGetUnconfirmedStopsQuery(activeHouseId, {
    pollingInterval: 30000,
  });
  return <UnconfirmedStopsTable isLoading={isLoading} data={data || []} />;
};

export default UnconfirmedStops;

import React, { useCallback } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../drApi/constants/languages';
import { getLanguage } from '../drApi/languageSelectors';
import { setLanguage } from '../slices/globalSlice';

const customSelectStyles = {
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '5px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    color: 'black',
    cursor: 'pointer',
    display: state.isDisabled ? 'none' : undefined,
  }),

};

const LanguageSelect = () => {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();

  const languageOptions = Object.keys(LANGUAGES).map((lng) => ({
    value: lng,
    label: t(LANGUAGES[lng]),
  }));

  const onChange = useCallback((option) => {
    dispatch(setLanguage({ language: option.value }));
  }, [dispatch]);

  return (
    <Select
      onChange={onChange}
      value={languageOptions.find((o) => o.value === language)}
      options={languageOptions}
      styles={customSelectStyles}
    />
  );
};

export default LanguageSelect;

import { drApi } from '../../drApi/drApi';

const deleteDraftElement = ({ stopId, cardType }) => (dispatch) => {
  return dispatch(drApi.util.updateQueryData('getStop', { stopId }, (oldStop) => {
    // eslint-disable-next-line no-param-reassign
    oldStop.cards = oldStop.cards.map((c) => {
      if (c.type === cardType) {
        // eslint-disable-next-line no-param-reassign
        c.elements = c.elements.filter((e) => !e.isDraft);
      }
      return c;
    });
  }));
};

export default deleteDraftElement;

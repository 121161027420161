export const ELEMENT_TYPES = {
  IMAGE: 'image',
  MAP: 'map',
  NOTE: 'text',
  TIME: 'time',
  KEYS: 'keys',
  PIN: 'pin',
  PHONE_CONTACT: 'phone_contact',
};

export const ELEMENTS_TYPES_MAPPINGS = {
  [ELEMENT_TYPES.IMAGE]: {
    human: 'Bild',
  },
  [ELEMENT_TYPES.MAP]: {
    human: 'Karte',
  },
  [ELEMENT_TYPES.NOTE]: {
    human: 'Notiz',
  },
  [ELEMENT_TYPES.TIME]: {
    human: 'Zeit',
  },
  [ELEMENT_TYPES.PHONE_CONTACT]: {
    human: 'Nummer',
  },
  [ELEMENT_TYPES.KEYS]: {
    human: 'Schlüssel',
  },
  [ELEMENT_TYPES.PIN]: {
    human: 'PIN Codes',
  },
};

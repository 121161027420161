import React from 'react';
import sematable from 'sematable';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import RoundInitialsButton from '../RoundInitialsButton/RoundInitialsButton';
import RLSortHeader from '../recordsList/RLSortHeader';
import { ReactComponent as LinkIcon } from '../../img/link_external.svg';
import RLHeaderCell from '../recordsList/RLHeaderCell';
import RLCell from '../recordsList/RLCell';
import RLTable from '../recordsList/RLTable';
import RLRow from '../recordsList/RLRow';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLTableLoader from '../recordsList/RLTableLoader';
import { ReactComponent as ConfirmIcon } from '../../img/check_grey.svg';
import styles from './UnconfirmedStopsTable.module.scss';

const columns = [
  { key: '_id', header: 'ID', primaryKey: true },
  { key: 'routeName', header: 'Tour', sortable: true },
  { key: 'customerName', header: 'Kunde', sortable: true },
];

const UnconfirmedStopsTable = ({
  isLoading,
  headers: { routeName: routeNameHeader, customerName: customerNameHeader },
  data,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <RLTable>
        <thead>
          <RLHeaderRow>
            <RLSortHeader {...routeNameHeader} />
            <RLSortHeader {...customerNameHeader} />
            <RLHeaderCell right>{t('Autor')}</RLHeaderCell>
            <RLHeaderCell right>{t('Datum')}</RLHeaderCell>
            <RLHeaderCell />
          </RLHeaderRow>
        </thead>
        <tbody>
          {data.map((unconfirmedStop) => {
            const {
              _id: stopId, routeName, customerName, authorName, changeDate,
            } = unconfirmedStop;
            return (
              <RLRow
                key={`${stopId} ${routeName}`}
                onClick={() => {
                  history.push(`/stops/${stopId}`);
                }}
                role="button"
              >
                <RLCell>
                  {routeName}
                </RLCell>
                <RLCell>
                  {customerName}
                </RLCell>
                <RLCell right>
                  <RoundInitialsButton
                    name={authorName}
                    noLink
                  />
                </RLCell>
                <RLCell right>
                  {changeDate && format(new Date(changeDate), 'dd.MM.yyyy')}
                </RLCell>
                <RLCell right>
                  <LinkIcon />
                </RLCell>
              </RLRow>
            );
          })}
        </tbody>
      </RLTable>
      {isLoading && <RLTableLoader />}
      {!isLoading && data?.length === 0 && (
        <div className={styles.confirmWrapper}>
          <ConfirmIcon className={styles.confirmIcon} />
          {t('Momentan müssen keine Änderungen bestätigt werden')}
        </div>
      )}
    </>
  );
};

UnconfirmedStopsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default sematable('unconfirmedStops', UnconfirmedStopsTable, columns, {
  plain: true,
  defaultPageSize: 9999,
});

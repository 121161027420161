import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './TitleBar.module.scss';
import { ReactComponent as LoadingIcon } from '../../img/loader.svg';
import AnyChildren from '../../propTypes/AnyChildren';
import DeleteModal from '../DeleteModal/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../img/bin_red.svg';

const TitleBar = ({
  title,
  isLoading = false,
  onCancel,
  onAccept,
  cancelLabel = 'Abbrechen',
  acceptLabel = 'Fertig',
  canDelete = false,
  onDelete,
  withDeleteModal = false,
  deleteModalTitle = '',
  deleteModalContent = '',
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    if (withDeleteModal) {
      setDeleteModalOpen(true);
    } else {
      onDelete();
    }
  }, [onDelete, withDeleteModal]);
  const onDeleteConfirm = useCallback(() => {
    setDeleteModalOpen(false);
    onDelete();
  }, [onDelete]);

  const onDeleteAbort = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  return (
    <div className={styles.titleBar}>
      <div className={styles.title}>
        {title}
        {/* To prevent yank when name changes from '' to something */}
        &nbsp;
      </div>
      <div className={styles.buttonBar}>
        {isLoading && <LoadingIcon className={styles.loading} />}
        {onCancel && (
        <button
          type="button"
          className={styles.cancelButton}
          onClick={onCancel}
          disabled={isLoading}
        >
          {t(cancelLabel)}
        </button>
        )}
        {onAccept && (
        <button
          type="button"
          className={styles.acceptButton}
          onClick={onAccept}
          disabled={isLoading}
        >
          {t(acceptLabel)}
        </button>
        )}
        {canDelete && (
        <button
          type="button"
          onClick={handleDelete}
          disabled={isLoading}
        >
          <DeleteIcon />
        </button>
        )}
      </div>
      {withDeleteModal && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={onDeleteAbort}
          onConfirm={onDeleteConfirm}
          title={deleteModalTitle}
          content={deleteModalContent}
        />
      )}
    </div>
  );
};

TitleBar.propTypes = {
  title: AnyChildren.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  withDeleteModal: PropTypes.bool,
  deleteModalTitle: PropTypes.string,
  deleteModalContent: PropTypes.string,
};

export default TitleBar;

import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import CardTextArea from '../../EditableCard/CardTextArea';
import { useUpdateKeyInformationMutation } from '../../drApi/drApi';

const NoteEditableCard = ({ keyInformation }) => {
  const { t } = useTranslation();
  const { _id: keyInformationId, note } = keyInformation;
  const [isEditing, setIsEditing] = useState(false);

  const [
    updateKeyInformation, { isSuccess, isLoading },
  ] = useUpdateKeyInformationMutation();

  const onSubmit = useCallback((values) => {
    const { note: newNote } = values;
    if (newNote === note) {
      setIsEditing(false);
      return;
    }
    updateKeyInformation({
      keyInformationId,
      updateFieldsInformation: {
        note: newNote,
      },
    });
  }, [keyInformationId, note, updateKeyInformation]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);
  return (
    <Formik
      initialValues={{ note }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleReset,
        handleSubmit,
      }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          title={t('Bemerkung')}
          canDelete={false}
          canAbort
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          large
        >
          <CardTextArea
            name="note"
            disabled={!isEditing}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

NoteEditableCard.propTypes = {
  keyInformation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    note: PropTypes.string,
  }).isRequired,
};

export default NoteEditableCard;

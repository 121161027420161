import { drApi } from '../../drApi/drApi';

const deleteDraftKeyInstance = ({ keyInformationId }) => (dispatch) => {
  return dispatch(drApi.util.updateQueryData('getKeyInformation', { keyInformationId }, (record) => {
    return {
      ...record,
      keyInstances: record.keyInstances.filter((keyInstance) => !keyInstance.isDraft),
    };
  }));
};

export default deleteDraftKeyInstance;

import { Link, Route, Switch } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../img/plus_white.svg';
import styles from './TopRightLink.module.scss';

const TopRightLink = () => {
  const { t } = useTranslation();
  return (
    <Switch>
      <Route path="/users">
        <Switch>
          {/* Only show on routes _besides_ /new */}
          <Route path="/users/new" />
          <Route>
            <Link to="/users/new" className={styles.link}>
              <AddIcon className={styles.addIcon} />
              {t('Nutzer hinzufügen')}
            </Link>
          </Route>
        </Switch>
      </Route>
      <Route path="/keys">
        <Switch>
          <Route path="/keys/new" />
          {/* Only show on routes _besides_ /new */}
          <Route>
            <Link to="/keys/new" className={styles.link}>
              <AddIcon className={styles.addIcon} />
              {t('Schlüssel hinzufügen')}
            </Link>
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
};

export default TopRightLink;

export const USER_TYPES = {
  DRIVER: 'driver',
  MANAGER: 'manager',
  PACKER: 'packer',
};

export const USER_TYPES_FRIENDLY_NAME = {
  [USER_TYPES.DRIVER]: 'Fahrer',
  [USER_TYPES.MANAGER]: 'Tourbuchmanager',
  [USER_TYPES.PACKER]: 'Verlader',
};

import { drApi } from '../../drApi/drApi';

const addElement = ({ stopId, cardType, elementType }) => (dispatch) => {
  return dispatch(drApi.util.updateQueryData('getStop', { stopId }, (oldStop) => {
    let card = oldStop.cards.find((c) => { return c.type === cardType; });
    if (!card) {
      card = {
        type: cardType,
        elements: [],
      };
      oldStop.cards.push(card);
    }
    card.elements.push({ _id: '_NEW_ELEMENT_DUMMY_ID', isDraft: true, type: elementType });
    return oldStop;
  }));
};

export default addElement;

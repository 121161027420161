export const KEY_ACTION_TYPES = {
  PACK_KEYS: 'PACK_KEYS',
  TAKE_KEYS: 'TAKE_KEYS',
  GIVE_OVER_KEYS: 'GIVE_OVER_KEYS',
  PUT_IN_CABINET: 'PUT_IN_CABINET',
};

export const KEY_ACTION_TYPES_FRIENDLY_NAME = {
  [KEY_ACTION_TYPES.PACK_KEYS]: 'Schlüssel packen',
  [KEY_ACTION_TYPES.TAKE_KEYS]: 'Schlüssel mitnehmen',
  [KEY_ACTION_TYPES.GIVE_OVER_KEYS]: 'Schlüssel übergeben',
  [KEY_ACTION_TYPES.PUT_IN_CABINET]: 'Schlüssel zurück in Schrank legen',
};

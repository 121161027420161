import React, { useMemo, useState } from 'react';
import {
  endOfToday, sub, startOfDay, format,
} from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TimeSeriesChart from './TimeSeriesChart';
import RangePicker from '../RangePicker/RangePicker';
import styles from './Dashboard.module.scss';
import { useGetDashboardUsersQuery } from '../drApi/drApi';
import { getTimeSeriesData } from './dashboardHelper';
import { getHouses } from '../drApi/housesSelectors';
import ActiveUsersTable from './ActiveUsersTable';
import UserTypePieChart from './UserTypePieChart';

const DashboardUsers = () => {
  const { t } = useTranslation();
  const defaultEnd = endOfToday();
  const defaultStart = startOfDay(sub(defaultEnd, { weeks: 1 }));

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const { isLoading, data = [] } = useGetDashboardUsersQuery({
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const houses = useSelector(getHouses);
  const { byDate = [], byHouseAndUserType = [] } = data;

  const aggregatedByDate = useMemo(() => getTimeSeriesData(startDate, endDate, byDate, 'count'), [byDate, endDate, startDate]);
  const aggregatedByHouse = useMemo(() => {
    const dict = {};
    for (const user of byHouseAndUserType) {
      if (user.houseId in dict) {
        dict[user.houseId] += 1;
      } else {
        dict[user.houseId] = 1;
      }
    }

    return Object.keys(dict).map((hid) => {
      const house = houses?.find((hs) => hs._id === hid);
      const {
        lda, bst, name, _id,
      } = house;
      return {
        _id,
        lda,
        bst,
        name,
        count: dict[hid],
      };
    });
  }, [byHouseAndUserType, houses]);

  const aggregatedByUserType = useMemo(() => {
    const dict = {};
    for (const user of byHouseAndUserType) {
      if (user.userType in dict) {
        dict[user.userType] += 1;
      } else {
        dict[user.userType] = 1;
      }
    }

    return Object.keys(dict).map((type) => {
      return {
        _id: type,
        count: dict[type],
      };
    });
  }, [byHouseAndUserType]);

  return (
    <>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultRanges={[1, 2, 3, 7, 14, 28, 90, 180]}
      />

      <div className={styles.chartWrapper}>
        <div>
          <TimeSeriesChart data={aggregatedByDate} title={t('Anzahl aktiver Nutzer (pro Tag)')} />
        </div>
        <div className={styles.pieWrapper}>
          <UserTypePieChart data={aggregatedByUserType} dimension="count" title={t('Aktive Nutzer nach Typ')} />
        </div>
      </div>

      <h1 className={styles.header}>
        {`${t('Anzahl unterschiedliche Nutzer')} ${format(startDate, 'dd. MMM')} - ${format(endDate, 'dd. MMM')} (${t('pro Haus')})`}
      </h1>

      <div className={styles.tableWidth}>
        <ActiveUsersTable
          data={aggregatedByHouse || []}
          isLoading={isLoading}
        />
      </div>

    </>
  );
};

export default DashboardUsers;

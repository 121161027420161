import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import KeyIdBadge from '../../KeyIdBadge/KeyIdBadge';
import styles from './KeyInstanceField.module.scss';
import IconBadge from '../../IconBadge/IconBadge';
import CardTextInput from '../../EditableCard/CardTextInput';
import KeyPositionSelect from '../../KeyPositionSelect/KeyPositionSelect';
import UserSelect from '../../UserSelect/UserSelect';

const KeyInstanceField = ({ namePrefix = '', isEditing = false, otherKeyInstanceNames = [] }) => {
  const {
    values, setFieldValue,
  } = useFormikContext();
  const { t } = useTranslation();
  const nameFieldName = `${namePrefix}name`;
  const currentUserFieldName = `${namePrefix}currentUser`;
  const currentPositionFieldName = `${namePrefix}currentPosition`;
  const name = getIn(values, nameFieldName);
  const keyUser = getIn(values, currentUserFieldName);
  const currentPosition = getIn(values, currentPositionFieldName);
  const [nameFieldProps, { touched: nameTouched, error: nameError }] = useField({
    name: nameFieldName,
    validate: (val) => {
      if (!val) {
        return t('Bitte geben Sie eine Schlüssel-ID an');
      }
      if (otherKeyInstanceNames.indexOf(val) !== -1) {
        return t('Diese Schlüssel-ID ist bereits vergeben');
      }
      return null;
    },
  });
  const showNameError = nameError && nameTouched;
  return (
    <div className={styles.wrapper}>
      <div className={styles.keyProperty}>
        <IconBadge icon="key" />
        <div className={styles.keyValue}>
          {isEditing ? (
            <div className={styles.nameWrapper}>
              <CardTextInput
                className={`${styles.textInput} ${showNameError ? styles.errorInput : ''}`}
                name={nameFieldName}
                {...nameFieldProps}
              />
              {showNameError && (
                <div className={styles.errorMessage}>
                  {nameError}
                </div>
              )}
            </div>
          ) : (
            <KeyIdBadge name={name} />
          )}
        </div>
      </div>
      <div className={styles.keyProperty}>
        <IconBadge icon="keyUser" />
        <div className={styles.keyValue}>
          <UserSelect
            value={keyUser}
            onChange={(newValue) => {
              setFieldValue(currentUserFieldName, newValue);
            }}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className={styles.keyProperty}>
        <IconBadge icon="crosshair" />
        <div className={styles.keyValue}>
          <KeyPositionSelect
            value={currentPosition}
            onChange={(newValue) => {
              setFieldValue(currentPositionFieldName, newValue);
            }}
            disabled={!isEditing}
          />
        </div>
      </div>
    </div>
  );
};

KeyInstanceField.propTypes = {
  namePrefix: PropTypes.string,
  isEditing: PropTypes.bool,
  otherKeyInstanceNames: PropTypes.arrayOf(PropTypes.string),
};

export default KeyInstanceField;

import getPath from './getPath';
import isRealObj from './isRealObj';

const setPath = (obj, path, value) => {
  const pathParts = path.split('.');
  const lastPart = pathParts.pop();
  const pathToDeepestParent = pathParts.join('.');
  // deep clone
  let newObj = JSON.parse(JSON.stringify(obj));
  if (pathParts.length === 0) {
    newObj[lastPart] = value;
    return newObj;
  }
  const deepestParent = getPath(newObj, pathToDeepestParent);
  if (isRealObj(deepestParent)) {
    deepestParent[lastPart] = value;
    return newObj;
  }

  let currentPart;
  let currentPath = '';
  // eslint-disable-next-line no-cond-assign
  while (currentPart = pathParts.shift()) {
    currentPath += currentPart;
    const currentLevel = getPath(newObj, currentPath);
    if (!isRealObj(currentLevel)) {
      newObj = setPath(newObj, currentPath, {});
      if (currentLevel !== undefined) {
        // eslint-disable-next-line no-console
        console.warn(
          `Setting path ${path} of nested object but path ${currentPath} is neither undefined or an object but ${currentLevel}`, obj, currentLevel,
        );
      }
    }
    currentPath += '.';
  }
  return setPath(obj, path, value);
};

export default setPath;

import React from 'react';
import { useSelector } from 'react-redux';
import { useGetRoutesQuery } from '../drApi/drApi';
import RoutesTable from './RoutesTable';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const Routes = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading, data } = useGetRoutesQuery(activeHouseId);
  return <RoutesTable isLoading={isLoading} data={data || []} />;
};

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './UserTypeBadge.module.scss';
import { USER_TYPES, USER_TYPES_FRIENDLY_NAME } from '../drApi/constants/userType';
import { ReactComponent as ManagerLogo } from '../../img/manager.svg';
import { ReactComponent as PackerLogo } from '../../img/box_white.svg';
import { ReactComponent as DriverLogo } from '../../img/truck.svg';

const UserTypeBadge = ({
  userType,
  onlyIcon = false,
  className: classNameFromProps = '',
}) => {
  const { t } = useTranslation();
  let Icon;
  let wrapperExtraClass;

  switch (userType) {
    case USER_TYPES.MANAGER:
      Icon = ManagerLogo;
      wrapperExtraClass = styles.manager;
      break;
    case USER_TYPES.PACKER:
      Icon = PackerLogo;
      wrapperExtraClass = styles.packer;
      break;
    case USER_TYPES.DRIVER:
    default:
      Icon = DriverLogo;
      wrapperExtraClass = styles.driver;
      break;
  }
  const friendly = USER_TYPES_FRIENDLY_NAME[userType];

  return (
    <div
      className={`${styles.wrapper} ${onlyIcon ? styles.onlyIcon : ''} ${wrapperExtraClass} ${classNameFromProps}`}
    >
      {onlyIcon || <div className={styles.text}>{t(friendly)}</div>}
      <div className={styles.iconWrapper}><Icon className={styles.icon} /></div>
    </div>
  );
};

UserTypeBadge.propTypes = {
  userType: PropTypes.oneOf(Object.values(USER_TYPES)).isRequired,
  onlyIcon: PropTypes.bool,
  className: PropTypes.string,
};
export default UserTypeBadge;

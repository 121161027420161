import React from 'react';
import PropTypes from 'prop-types';
import { KEY_TYPES } from '../drApi/constants/keyType';
import styles from './KeyTypeBadge.module.scss';
import IconBadge from '../IconBadge/IconBadge';

const KeyTypeBadge = ({ keyType }) => {
  let iconName;

  switch (keyType) {
    case KEY_TYPES.CARD:
      iconName = 'card';
      break;
    case KEY_TYPES.REMOTE:
      iconName = 'remote';
      break;
    case KEY_TYPES.KEY:
    default:
      iconName = 'key';
      break;
  }
  return (
    <IconBadge icon={iconName} className={styles.wrapper} />
  );
};

KeyTypeBadge.propTypes = {
  keyType: PropTypes.oneOf(Object.values(KEY_TYPES)).isRequired,
};

export default KeyTypeBadge;

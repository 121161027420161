import React from 'react';
import PropTypes from 'prop-types';
import rLStyles from './RecordsList.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const RLCell = ({
  children, className = '', right = false, ...props
}) => {
  let classNames = `${className} ${rLStyles.cell}`;
  if (right) {
    classNames += ` ${rLStyles.right}`;
  }
  return <td className={classNames} {...props}>{children}</td>;
};

RLCell.propTypes = {
  children: AnyChildren,
  className: PropTypes.string,
  right: PropTypes.bool,
};

export default RLCell;

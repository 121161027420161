import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './global.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoggedInRoot from './features/root/LoggedInRoot';
import staticGetAccessToken from './features/drApi/staticGetAccessToken';
import NoManager from './features/noManager/NoManager';
import 'react-toastify/dist/ReactToastify.css';
import FullPageLoader from './features/loaders/FullPageLoader';
import { getLanguage } from './features/drApi/languageSelectors';

const { setFn } = staticGetAccessToken;

function App() {
  const { i18n } = useTranslation();

  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // this is an ugly workaround to access getAccessTokenSilently
    // outside of a component
    setFn(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const language = useSelector(getLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/nomanager" component={NoManager} />
        <Route path="/" component={LoggedInRoot} />
      </Switch>
      <ToastContainer
        position="bottom-right"
        newestOnTop
      />
    </Router>
  );
}

export default App;

import { Link, useHistory } from 'react-router-dom';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import RLCell from '../recordsList/RLCell';
import { ReactComponent as StopDetailsIcon } from '../../img/caret_right.svg';
import RLRow from '../recordsList/RLRow';
import TourBadge from '../TourBadge/TourBadge';
import IconBadge from '../IconBadge/IconBadge';
import { CARD_TYPES, CARD_TYPES_MAPPINGS } from '../drApi/constants/cardType';
import styles from './StopsTable.module.scss';

const StopRow = ({ stop }) => {
  const { customerName, humanAddress, _id, lastAccessed, stopInfos, routes, stopName, cards } = stop;

  const lastAccessedHuman = useMemo(() => {
    return lastAccessed ? format(new Date(lastAccessed), 'dd.MM.yyyy') : '';
  }, [lastAccessed]);
  const history = useHistory();

  return (
    <RLRow
      onClick={() => {
        history.push(`/stops/${_id}`);
      }}
      role="button"
    >
      <RLCell>{customerName || stopName}</RLCell>
      <RLCell>{humanAddress}</RLCell>
      <RLCell right>
        {routes?.length > 0 && <TourBadge tourName={routes[0].name} />}
        {routes?.length > 1 && <TourBadge className={styles.plusOneBadge} dark tourName={`+${routes.length - 1}`} />}
      </RLCell>
      <RLCell right>
        {stopInfos.map((cardType) => {
          if (cardType === CARD_TYPES.APPROACH_OFFLOAD) {
            const approachOffloadCard = cards.find((card) => card.type === CARD_TYPES.APPROACH_OFFLOAD);

            const isRemarkExist = approachOffloadCard?.elements?.some((element) => element.type === 'text') ?? false;
            const isImageExists = approachOffloadCard?.elements?.some((element) => element.type === 'image') ?? false;

            return (
              <>
                {isRemarkExist && <IconBadge className={styles.stopInfo} key={`${cardType}text`} icon="flag" />}
                {isImageExists && <IconBadge className={styles.stopInfo} key={`${cardType}image`} icon="keyUser" />}
              </>
            );
          }

          const { iconName } = CARD_TYPES_MAPPINGS[cardType] || {};
          return <IconBadge className={styles.stopInfo} key={cardType} icon={iconName} />;
        })}
      </RLCell>
      <RLCell right>{lastAccessedHuman}</RLCell>
      <RLCell>
        <Link to={`/stops/${_id}`}>
          <StopDetailsIcon />
        </Link>
      </RLCell>
    </RLRow>
  );
};

StopRow.propTypes = {
  stop: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    stopName: PropTypes.string.isRequired,
    humanAddress: PropTypes.string.isRequired,
    lastAccessed: PropTypes.string,
    _id: PropTypes.string.isRequired,
    stopInfos: PropTypes.arrayOf(PropTypes.string).isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default StopRow;

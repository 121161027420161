import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useAddElementMutation, useDeleteElementMutation, useUpdateElementMutation } from '../../drApi/drApi';
import { CARD_TYPES } from '../../drApi/constants/cardType';
import CardTextArea from '../../EditableCard/CardTextArea';
import deleteDraftElement from '../thunks/deleteDraftElement';
import {
  getActiveChangeId,
  getStopCardElementHasChanges,
  getStopCardElementWithChanges,
} from '../../drApi/stopSelectors';
import ReviewBar from '../ReviewBar';
import { getGloballySelectedHouseId } from '../../drApi/housesSelectors';

const NoteElement = ({ stopId, cardType, elementId, large = false }) => {
  const { t } = useTranslation();
  const element = useSelector(getStopCardElementWithChanges(stopId, cardType, elementId));
  const hasChanges = useSelector(getStopCardElementHasChanges(stopId, cardType, elementId));
  const activeChangeId = useSelector(getActiveChangeId(stopId, cardType, elementId));
  const { text: initialText, isDraft = false } = element;
  const [isEditing, setIsEditing] = useState(isDraft);
  const dispatch = useDispatch();
  const houseId = useSelector(getGloballySelectedHouseId);

  const [addElement, { isSuccess: isSuccessAdd, isLoading: isLoadingAdd }] = useAddElementMutation();
  const [updateElement, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate }] = useUpdateElementMutation();
  const [deleteElement, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete }] = useDeleteElementMutation();

  const isSuccess = isSuccessAdd || isSuccessUpdate || isSuccessDelete;
  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete;

  const onSubmit = useCallback(
    (values) => {
      const { note: newText } = values;

      if (newText === initialText) {
        setIsEditing(false);
        return;
      }

      if (isDraft) {
        addElement({
          stopId,
          houseId,
          cardType,
          body: {
            element: {
              type: 'text',
              text: newText,
            },
          },
        });
        return;
      }

      updateElement({
        stopId,
        cardType,
        elementId,
        activeChangeId,
        updateFields: {
          text: newText,
        },
      });
    },
    [activeChangeId, addElement, cardType, elementId, houseId, initialText, isDraft, stopId, updateElement],
  );

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  const onDelete = useCallback(() => {
    if (isDraft) {
      dispatch(deleteDraftElement({ stopId, cardType }));
      return;
    }
    deleteElement({ stopId, cardType, elementId });
  }, [cardType, deleteElement, dispatch, elementId, isDraft, stopId]);

  return (
    <Formik
      initialValues={{
        note: initialText || '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleReset }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          canEdit={!hasChanges}
          title={t('Bemerkung')}
          onDelete={onDelete}
          canDelete={!hasChanges}
          canAbort={!isDraft}
          withDeleteModal
          deleteModalTitle={t('Notiz löschen')}
          deleteModalContent={t('Wollen Sie wirklich diese Notiz löschen?')}
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          large={large}
        >
          <CardTextArea name="note" disabled={!isEditing} />
          {!isEditing && hasChanges && (
            <ReviewBar stopId={stopId} cardType={cardType} elementId={elementId} onEdit={() => setIsEditing(true)} />
          )}
        </EditableCard>
      )}
    </Formik>
  );
};

NoteElement.propTypes = {
  stopId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  cardType: PropTypes.oneOf(Object.values(CARD_TYPES)),
  large: PropTypes.bool,
};
export default NoteElement;

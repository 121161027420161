import React from 'react';
import PropTypes from 'prop-types';
import rLStyles from './RecordsList.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const RLRow = ({ children, className = '', ...props }) => {
  const classNames = `${className} ${rLStyles.row}`;
  return <tr className={classNames} {...props}>{children}</tr>;
};

RLRow.propTypes = {
  children: AnyChildren,
  className: PropTypes.string,
};

export default RLRow;

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import RLCell from '../recordsList/RLCell';
import RLRow from '../recordsList/RLRow';

const ImportLogRow = ({ log, columns }) => {
  return (
    <RLRow>
      {columns.map((el) => {
        let content = log[el.key];

        if (content) {
          if (el.type === 'date') {
            content = format(new Date(content), 'dd. MMM yy kk:mm:ss');
          } else if (el.type === 'date_short') {
            content = format(new Date(content), 'dd. MMM yy');
          }

          if (el.curtail) {
            content = `${content?.substring(0, 10)} ...`;
          }
        }

        return <RLCell key={el.key}>{content}</RLCell>;
      })}

    </RLRow>
  );
};

ImportLogRow.propTypes = {
  log: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImportLogRow;

import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import styles from './styles.module.scss';

const Profile = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>{t('Sprache')}</div>
      <div className={styles.selectContainer}>
        <LanguageSelect />
      </div>

    </div>
  );
};

export default Profile;

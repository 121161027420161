import React from 'react';
import PropTypes from 'prop-types';
import styles from './ImageCardContent.module.scss';

const ImageCardContent = ({ src }) => {
  return (
    <div className={styles.imgWrapper}>
      <img
        alt="Stopp-Bild"
        src={src}
        className={styles.img}
      />

    </div>
  );
};

ImageCardContent.propTypes = {
  src: PropTypes.string.isRequired,
};
export default ImageCardContent;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserTypeOption.module.scss';
import UserTypeBadge from '../UserTypeBadge/UserTypeBadge';
import AnyRef from '../../propTypes/AnyRef';

const UserTypeOption = ({
  data, innerRef, selectOption, isSelected,
}) => {
  const { value, label } = data;

  if (isSelected) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.option}
      ref={innerRef}
      onClick={() => selectOption(data)}
    >
      <UserTypeBadge userType={value} onlyIcon className={styles.badge} />
      {label}
    </button>
  );
};

UserTypeOption.propTypes = {
  innerRef: AnyRef,
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectOption: PropTypes.func.isRequired,
};

export default UserTypeOption;

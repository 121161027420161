import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLTable from '../recordsList/RLTable';
import styles from './ImportLog.module.scss';
import RLSortHeader from '../recordsList/RLSortHeader';
import ImportLogRow from './ImportLogRow';

const columns = [
  {
    key: 'key', header: 'E-Mail ID', curtail: true,
  },
  { key: 'LDA', header: 'LDA' },
  { key: 'BST', header: 'BST' },
  { key: 'BEZ', header: 'Name' },
  { key: 'TOUR', header: 'Tour' },
  { key: 'tourDate', header: 'Tourdatum', type: 'date_short' },
  { key: 'numStops', header: 'Stops' },
  { key: 'newStops', header: 'Neue Stops' },
  { key: 'emailReceived', header: 'Email Erhalten', type: 'date' },
  {
    key: 'started', header: 'Import gestartet', primaryKey: true, type: 'date',
  },
  { key: 'executionTime', header: 'Dauer in ms' },
  { key: 'status', header: 'Status' },
];

const ImportLogTable = ({
  data,
  headers,
}) => {
  const { t } = useTranslation();
  if (!data.length) {
    return (
      <div className={styles.text}>
        {t('Keine Daten für diesen Zeitraum')}
      </div>
    );
  }

  return (
    <RLTable>
      <thead>
        <RLHeaderRow>
          {columns.map((el) => {
            return <RLSortHeader key={el.key} {...headers[el.key]} />;
          })}
        </RLHeaderRow>
      </thead>
      <tbody>
        {data.map((log) => {
          const { _id } = log;
          return <ImportLogRow key={_id} log={log} columns={columns} />;
        })}
      </tbody>
    </RLTable>
  );
};

ImportLogTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
};

export default sematable('importlog', ImportLogTable, columns, {
  plain: true,
  defaultPageSize: 9999,
  sortKey: 'started',
  sortDirection: 'desc',
});

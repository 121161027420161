export const CARD_TYPES = {
  APPROACH_OFFLOAD: 'APPROACH_OFFLOAD',
  OFFLOAD_TIME: 'OFFLOAD_TIME',
  KEYS: 'KEYS',
  PINCODE: 'PINCODE',
  PHONE_NUMBERS: 'PHONE_NUMBERS',
};

export const CARD_TYPES_ORDER = [
  CARD_TYPES.PHONE_NUMBERS,
  CARD_TYPES.OFFLOAD_TIME,
  CARD_TYPES.KEYS,
  CARD_TYPES.PINCODE,
];

export const CARD_TYPES_MAPPINGS = {
  [CARD_TYPES.APPROACH_OFFLOAD]: {
    human: 'Anfahrt- und Abladeinformationen',
  },
  [CARD_TYPES.OFFLOAD_TIME]: {
    human: 'Zeiten',
    iconName: 'clock',
  },
  [CARD_TYPES.KEYS]: {
    human: 'Schlüssel',
    iconName: 'key',
  },
  [CARD_TYPES.PINCODE]: {
    human: 'Infos zu Codes',
    iconName: 'pinCode',
  },
  [CARD_TYPES.PHONE_NUMBERS]: {
    human: 'Telefonnummern',
    iconName: 'phone',

  },
};

export const { [CARD_TYPES.KEYS]: CARD_TYPE_KEY, ...CARD_TYPES_CREATEABLE } = CARD_TYPES_MAPPINGS;

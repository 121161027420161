import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const CardsContainer = ({
  className: classNameFromProps = '',
  children,
  vertical = false,
}) => {
  const className = `${styles.container} ${classNameFromProps} ${vertical ? styles.vertical : ''}`;
  return (
    <div className={className}>
      {children}
    </div>
  );
};

CardsContainer.propTypes = {
  className: PropTypes.string,
  children: AnyChildren,
  vertical: PropTypes.bool,
};

export default CardsContainer;

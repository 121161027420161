import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getStopCardElementIds, getStopCardHasDraft } from '../drApi/stopSelectors';
import { CARD_TYPES } from '../drApi/constants/cardType';
import CardsContainer from '../cards/CardsContainer';
import StopElementCard from './StopElementCard';
import styles from './StopMainSection.module.scss';
import AddElement from './AddElement';
import { ELEMENT_TYPES } from '../drApi/constants/elementType';

const StopMainSection = () => {
  const { stopId } = useParams();
  const cardType = CARD_TYPES.APPROACH_OFFLOAD;

  const elementIds = useSelector(getStopCardElementIds(stopId, cardType));
  const cardHasDraft = useSelector(getStopCardHasDraft(stopId, cardType));

  const elementCards = elementIds.map((elementId) => {
    return <StopElementCard key={elementId} stopId={stopId} cardType={cardType} elementId={elementId} inMainSection />;
  });

  if (!cardHasDraft) {
    elementCards.push(
      <AddElement inMainSection key="new" stopId={stopId} cardType={cardType} elementType={ELEMENT_TYPES.NOTE} />,
    );
  }

  return <CardsContainer className={styles.main}>{elementCards}</CardsContainer>;
};

export default StopMainSection;

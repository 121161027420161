import {
  addDays,
} from 'date-fns';

export const getTimeSeriesData = (start, end, data = [], dimension) => {
  const dict = {};
  let current = start;

  while (current <= end) {
    dict[current.toLocaleDateString('en-US')] = 0;
    current = addDays(current, 1);
  }

  for (const d of data) {
    const dateString = new Date(d.date).toLocaleDateString('en-US');
    if (dateString in dict) {
      dict[dateString] += d[dimension];
    }
  }

  return {
    datasets: [
      {
        data: Object.entries(dict).map(([key, val]) => ({ x: new Date(key), y: val })),
        borderColor: '#ed8c37',
        borderWidth: 2,
        pointRadius: 1,
      },
    ],
  };
};

import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLHeaderCell from '../recordsList/RLHeaderCell';
import RLTable from '../recordsList/RLTable';
import StopRow from './StopRow';
import RoutesFilterHeader from './Filter/RoutesFilterHeader';
import InfoFilterHeader from './Filter/InfoFilterHeader';
import RLTableLoader from '../recordsList/RLTableLoader';

const columns = [
  { key: '_id', header: 'ID', primaryKey: true },
  {
    key: 'customerName',
    header: 'Kunde',
    searchable: true,
    sortable: true,
  },
  {
    key: 'humanAddress',
    header: 'Adresse',
    searchable: true,
    sortable: true,
  },
];

const StopsTable = ({ data, isLoading = false }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <RLTableLoader />
      ) : (
        <RLTable>
          <thead>
            <RLHeaderRow>
              <RLHeaderCell>{t('Kunde')}</RLHeaderCell>
              <RLHeaderCell>{t('Adresse')}</RLHeaderCell>
              <RoutesFilterHeader />
              <InfoFilterHeader />
              <RLHeaderCell right>{t('Zuletzt angefahren')}</RLHeaderCell>
              <RLHeaderCell />
            </RLHeaderRow>
          </thead>
          <tbody>
            {data.map((stop) => {
              const { _id } = stop;
              return <StopRow key={_id} stop={stop} />;
            })}
          </tbody>
        </RLTable>
      )}
    </>
  );
};

StopsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export default sematable('stops', StopsTable, columns, {
  plain: true,
  defaultPageSize: 9999,
});

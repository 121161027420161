export const calculateStopHumanAddress = (stop) => {
  const { address: { place, street } = {} } = stop;
  if (!place && !street) {
    return '';
  }
  if (!place) {
    return street;
  }
  if (!street) {
    return place;
  }
  return `${place}, ${street}`;
};

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './RoundInitialsButton.module.scss';

const RoundInitialsButton = ({
  userId,
  name,
  className = '',
  noLink = false,
}) => {
  const initials = useMemo(() => name?.split(' ').map((part) => part && part[0]).join('').slice(0, 2), [name]);
  const classNames = `${className} ${styles.wrapper}`;
  if (noLink) {
    return <div className={classNames} title={name}>{initials}</div>;
  }
  return <Link to={`/users/${userId}`} className={classNames} title={name}>{initials}</Link>;
};

RoundInitialsButton.propTypes = {
  userId: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  noLink: PropTypes.bool,
};

export default RoundInitialsButton;

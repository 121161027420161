import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reducer as sematable } from 'sematable';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { drApi } from '../features/drApi/drApi';
import pagination from '../features/pagination/paginationSlice';
import global from '../features/slices/globalSlice';
import stops from '../features/slices/stopsSlice';

const reducers = combineReducers({
  [drApi.reducerPath]: drApi.reducer,
  sematable,
  pagination,
  global,
  stops,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global'],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(drApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

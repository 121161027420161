import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../EditableCard/EditableCard';
import IconBadge from '../IconBadge/IconBadge';
import CardTextInput from '../EditableCard/CardTextInput';
import { ReactComponent as AddIcon } from '../../img/plus_white.svg';
import { useAddKeyInformationMutation, useUpdateKeyInformationMutation } from '../drApi/drApi';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';
import { KEY_TYPES } from '../drApi/constants/keyType';
import { KEY_POSITION_TYPES } from '../drApi/constants/keyPositionType';
import { getStopKeyInformationId } from '../drApi/stopSelectors';
import classes from './ElementCards/IconTextElement.module.scss';

const AddKey = ({ stopId }) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const keyInformationId = useSelector(getStopKeyInformationId(stopId)) || null;
  const houseId = useSelector(getGloballySelectedHouseId);

  const [addKeyInformation, { isSuccess: isSuccessAddKey }] = useAddKeyInformationMutation();
  const [updateKeyInformation, { isSuccess: isSuccessUpdateKey }] = useUpdateKeyInformationMutation();

  const handleFormSubmit = (values) => {
    const { keyInstanceName } = values;
    if (keyInformationId) {
      updateKeyInformation({
        keyInformationId,
        newKeyInstances: [
          {
            name: keyInstanceName,
            currentPosition: KEY_POSITION_TYPES.CABINET,
            currentUser: null, // should we assign current user?
          },
        ],
        stopIds: { 0: { newStop: stopId } },
      });
    } else {
      addKeyInformation({
        body: {
          newKeyInformation: {
            houseId,
            keyTypes: {
              [KEY_TYPES.KEY]: 0,
              [KEY_TYPES.CARD]: 0,
              [KEY_TYPES.REMOTE]: 0,
            },
            image: null,
            note: '',
            // weird format for api...
            stopIds: { 0: { newStop: stopId } },
          },
          newKeyInstances: [
            {
              name: keyInstanceName,
              currentPosition: KEY_POSITION_TYPES.CABINET,
              currentUser: null,
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccessAddKey || isSuccessUpdateKey) {
      setShowForm(false);
    }
  }, [isSuccessAddKey, isSuccessUpdateKey]);

  return (
    <div>
      {!showForm && (
        <div className={classes.keyWrapper}>
          <button type="button" onClick={() => setShowForm(true)} className={classes.circle}>
            <AddIcon className={classes.icon} />
          </button>
        </div>
      )}
      {showForm && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={{
            keyInstanceName: '',
          }}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, values }) => (
            <EditableCard
              title={t('Schlüssel-ID')}
              isEditing
              canDelete
              canAbort={false}
              onDelete={() => setShowForm(false)}
              onAccept={handleSubmit}
            >
              <div className={classes.wrapper}>
                <IconBadge icon="key" />
                <div className={classes.number}>
                  <CardTextInput
                    type="text"
                    name="keyInstanceName"
                    value={values.keyInstanceName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </EditableCard>
          )}
        </Formik>
      )}
    </div>
  );
};

AddKey.propTypes = {
  stopId: PropTypes.string.isRequired,
};

export default AddKey;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RLTableLoader.module.scss';
import { ReactComponent as LoaderIcon } from '../../img/loader_thin.svg';

const RLTableLoader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.loaderWrapper}>
      <LoaderIcon className={styles.loader} />
      <div className={styles.text}>
        {t('Ihre Abfrage wird geladen')}
        <br />
        {t('Einen Moment bitte')}
      </div>
    </div>
  );
};
export default RLTableLoader;

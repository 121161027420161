import isRealObj from './isRealObj';

const getPath = (obj, path) => {
  const pathParts = path.split('.');
  return pathParts.reduce((currentLevelObj, key) => {
    if (!isRealObj(currentLevelObj)) {
      return undefined;
    }
    return currentLevelObj[key];
  }, obj);
};

export default getPath;

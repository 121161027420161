import { createSelector } from '@reduxjs/toolkit';
import { drApi } from './drApi';
import { getGloballySelectedHouseId } from './housesSelectors';

export const getRoutesForActiveHouse = createSelector(
  (state) => drApi.endpoints.getRoutes.select(getGloballySelectedHouseId(state))(state),
  (query) => query?.data,
);

export const getRouteOptions = createSelector(
  getRoutesForActiveHouse,
  (routes) => {
    if (!routes) {
      return [];
    }
    const routeNames = [...new Set(routes.map((r) => r.name))];
    return routeNames.map((name) => ({ name, value: name }));
  },
);

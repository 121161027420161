import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RLSortHeader from '../recordsList/RLSortHeader';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLHeaderCell from '../recordsList/RLHeaderCell';
import RLTable from '../recordsList/RLTable';
import RouteRow from './RouteRow';
import RLTableLoader from '../recordsList/RLTableLoader';

const columns = [
  { key: '_id', header: 'ID', primaryKey: true },
  {
    key: 'name', header: 'Name-ID', sortable: true,
  },
  {
    key: 'stops', header: 'Stops', searchable: true, sortable: true,
  },
  { key: 'assignedUsers', header: 'Mitarbeiter' },
];

const RoutesTable = ({ isLoading, headers: { name, stops }, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <RLTable>
        <thead>
          <RLHeaderRow>
            <RLSortHeader {...name} />
            <RLSortHeader {...stops} />
            <RLHeaderCell right>
              {t('Mitarbeiter')}
            </RLHeaderCell>
            <RLHeaderCell>
              {t('Aktionen')}
            </RLHeaderCell>
            <RLHeaderCell right>
              {t('Stammmitarbeiter')}
            </RLHeaderCell>
            <RLHeaderCell>
              {t('Aktionen')}
            </RLHeaderCell>
            <RLHeaderCell />
          </RLHeaderRow>
        </thead>
        <tbody>
          {data.map((route) => {
            return <RouteRow key={route._id} route={route} />;
          })}
        </tbody>
      </RLTable>
      {isLoading && <RLTableLoader />}
    </>
  );
};

RoutesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default sematable('routes', RoutesTable, columns, {
  plain: true,
  sortKey: 'name',
  defaultPageSize: 9999,
});

import { createSelector } from '@reduxjs/toolkit';
import { drApi } from './drApi';

export const getHouses = createSelector(
  drApi.endpoints.getHouses.select(),
  (query) => query?.data,
);

export const getGloballySelectedHouseId = (state) => state.global.globallySelectedHouseId;

export const getGloballySelectedHouse = createSelector(
  [getHouses, getGloballySelectedHouseId],
  (houses, houseId) => {
    return houses && houses.find((h) => h._id === houseId);
  },
);

export const getHouseById = (houseId) => createSelector(
  getHouses,
  (houses) => {
    return houses.find((hs) => hs._id === houseId);
  },
);

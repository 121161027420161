import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from '../drApi/constants/pagination';

const getPaginationInfo = (tableName) => (state) => state.pagination.paginationInfo[tableName];

export const getPage = (tableName) => createSelector(
  getPaginationInfo(tableName),
  (paginationInfo) => paginationInfo?.page || 1,
);

export const getPageSize = (tableName) => createSelector(
  getPaginationInfo(tableName),
  (paginationInfo) => paginationInfo?.pageSize || DEFAULT_PAGE_SIZE,
);

export const getTotalItems = (tableName) => createSelector(
  getPaginationInfo(tableName),
  (paginationInfo) => paginationInfo?.totalItems || 0,
);

export const getTotalPages = (tableName) => createSelector(
  getPageSize(tableName),
  getTotalItems(tableName),
  (pageSize, totalItems) => {
    return Math.ceil(totalItems / pageSize);
  },
);

const TOTAL_PAGINATION_ITEMS = 11;

// makes array from to (including both edges)
const makeArrayFromTo = (from, to) => {
  const len = to - from + 1;
  if (len <= 0) {
    return [];
  }
  return Array.from(Array(len).keys()).map((i) => (i + from));
};

export const getPaginationDisplayInfo = (tableName) => createSelector(
  getPage(tableName),
  getPageSize(tableName),
  getTotalPages(tableName),
  (page, pageSize, totalPages) => {
    const possibleSlotsBeforePage = Math.max(page - 2, 0);
    const possibleSlotsAfterPage = Math.max(totalPages - page - 1, 0);
    const firstOrLastPage = (page === 1 || page === totalPages);
    const maxTotalSlots = TOTAL_PAGINATION_ITEMS - (firstOrLastPage ? 2 : 3);
    const halfSlots = maxTotalSlots / 2;
    // check if can borrow from after slots
    const canBorrowFromAfter = Math.max(halfSlots - possibleSlotsAfterPage, 0);
    let firstVisibleSlot = Math.max(page - halfSlots - canBorrowFromAfter, 2);
    const needsStartDots = firstVisibleSlot > 2;
    // check if after slots needs to borrow from before slots
    const canBorrowFromBefore = Math.max(halfSlots - possibleSlotsBeforePage, 0);
    let lastVisibleSlot = Math.min(page + halfSlots + canBorrowFromBefore, totalPages - 1);
    const needsEndDots = lastVisibleSlot < totalPages - 2;
    if (needsStartDots) {
      firstVisibleSlot += 1;
    }
    if (needsEndDots) {
      lastVisibleSlot -= 1;
    }
    return {
      needsStartDots,
      needsEndDots,
      displayPages: makeArrayFromTo(firstVisibleSlot, lastVisibleSlot),
    };
  },
);

export const getPaginationDescription = (tableName) => createSelector(
  getPage(tableName),
  getPageSize(tableName),
  getTotalItems(tableName),
  (page, pageSize, totalItems) => {
    const from = Math.min(totalItems, (page - 1) * pageSize + 1);
    const to = Math.min(totalItems, page * pageSize);
    return { from, to, totalItems };
  },
);

export const getPaginationNavigationInfo = (tableName) => createSelector(
  getPage(tableName),
  getPageSize(tableName),
  getTotalPages(tableName),
  (page, pageSize, totalPages) => {
    const canGoBack = page > 1;
    const canGoForward = page < totalPages;
    return { canGoBack, canGoForward };
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ImageDisabledCardContent.module.scss';

const ImageDisabledCardContent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.disabled}>
      {t('Das Hinzufügen und Bearbeiten von Schlüsselbildern wird in der Webversion (noch) nicht unterstützt')}
    </div>
  );
};

export default ImageDisabledCardContent;

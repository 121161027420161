import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import addElement from './thunks/addElement';
import AddButton from '../detailsPageComponents/AddButton';

const AddElement = ({ stopId, cardType, elementType, inMainSection = false }) => {
  const dispatch = useDispatch();

  const onAddInfo = useCallback(() => {
    dispatch(addElement({ stopId, cardType, elementType }));
  }, [cardType, dispatch, elementType, stopId]);

  return <AddButton onClick={onAddInfo} inMainSection={inMainSection} stopId={stopId} />;
};

AddElement.propTypes = {
  stopId: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  inMainSection: PropTypes.bool,
};

export default AddElement;

import React, { useMemo, useState } from 'react';
import {
  endOfToday, sub, startOfDay, format,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import TimeSeriesChart from './TimeSeriesChart';
import RangePicker from '../RangePicker/RangePicker';
import styles from './Dashboard.module.scss';
import { useGetDashboardRoutesQuery } from '../drApi/drApi';
import HouseTable from './HouseTable';
import HouseTableTimePeriod from './HouseTableTimePeriod';
import { getTimeSeriesData } from './dashboardHelper';

const DashboardRoutes = () => {
  const { t } = useTranslation();
  const defaultEnd = endOfToday();
  const defaultStart = startOfDay(sub(defaultEnd, { weeks: 2 }));

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const { isLoading, data = [] } = useGetDashboardRoutesQuery({
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const { dailyData = {}, houseData = [] } = data;

  const aggregatedDailyDataByHouse = useMemo(() => {
    return dailyData?.byHouse?.map((d) => {
      const stopCount = d?.numStops;
      const routeCount = d?.numRoutes;

      return {
        lda: d._id?.LDA,
        bst: d._id?.BST,
        name: d.name,
        stopCount,
        routeCount,
      };
    });
  }, [dailyData]);

  const aggregatedDailyDataByDate = useMemo(() => {
    return dailyData?.byDate?.map((d) => {
      const stopCount = d?.numStops;
      const routeCount = d?.numRoutes;

      return {
        date: d._id,
        stopCount,
        routeCount,
      };
    });
  }, [dailyData]);

  const mappedDailyStopsData = useMemo(() => getTimeSeriesData(
    startDate, endDate, aggregatedDailyDataByDate, 'stopCount',
  ), [endDate, aggregatedDailyDataByDate, startDate]);
  const mappedDailyRoutesData = useMemo(() => getTimeSeriesData(
    startDate, endDate, aggregatedDailyDataByDate, 'routeCount',
  ), [endDate, aggregatedDailyDataByDate, startDate]);

  return (
    <>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultRanges={[1, 2, 3, 7, 14, 28, 90, 180]}
      />

      <div className={styles.chartWrapper}>

        <div>
          <TimeSeriesChart data={mappedDailyStopsData || []} title={t('Tägliche Stops')} />
        </div>

        <div>
          <TimeSeriesChart data={mappedDailyRoutesData || []} title={t('Tägliche Tours')} />
        </div>

      </div>

      <h1 className={styles.header}>
        {`${t('Stops und Tours')} ${format(startDate, 'dd. MMM')} - ${format(endDate, 'dd. MMM')} (${t('pro Haus')})`}
      </h1>

      <div className={styles.tableWidth}>
        <HouseTableTimePeriod data={aggregatedDailyDataByHouse || []} isLoading={isLoading} />
      </div>

      <h1 className={styles.header}>{t('Stops und Tours insgesamt (pro Haus)')}</h1>

      <div className={styles.tableWidth}>
        <HouseTable data={houseData || []} isLoading={isLoading} />
      </div>

    </>
  );
};

export default DashboardRoutes;

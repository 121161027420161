/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchString: '',
};

const stopsSlice = createSlice({
  name: 'stops',
  initialState,
  reducers: {
    setStopsSearchString(state, action) {
      const { searchString } = action.payload;
      state.searchString = searchString;
    },
  },
});

export const {
  setStopsSearchString,
} = stopsSlice.actions;

export default stopsSlice.reducer;

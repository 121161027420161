import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetUsersQuery } from '../drApi/drApi';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const customSelectStyles = {
  control: (provided, { isDisabled }) => ({
    ...provided,
    borderRadius: '2px',
    boxShadow: isDisabled ? 'none' : 'inset 0 0 3px 0 rgba(0, 0, 0, 0.12), inset 0 1px 3px 0 rgba(0, 0, 0, 0.31)',
    border: isDisabled ? 'none' : 'solid 1px rgba(151, 151, 151, 0.32)',
    minHeight: '27px',
    height: '27px',
    fontSize: '12px',
    cursor: isDisabled ? 'normal' : 'text',
    background: 'transparent',
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'black',
    };
  },
  dropdownIndicator: (provided, { isDisabled }) => {
    return {
      ...provided,
      padding: '0px',
      display: isDisabled ? 'none' : provided.display,
    };
  },
};

const UserSelect = ({ value, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading, data } = useGetUsersQuery(activeHouseId);

  const options = useMemo(() => {
    return data?.map((user) => ({
      value: user._id,
      label: user.name,
    })) || [];
  }, [data]);
  const selectValue = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);
  return (
    <Select
      isLoading={isLoading}
      options={options}
      value={selectValue}
      onChange={(newOption) => { onChange(newOption?.value); }}
      components={{
        IndicatorSeparator: null,
      }}
      styles={customSelectStyles}
      placeholder={t('Nutzer auswählen')}
      isDisabled={disabled}
    />
  );
};

UserSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default UserSelect;

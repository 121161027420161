import React, { useMemo, useState } from 'react';
import {
  endOfToday, sub, startOfDay, format,
} from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TimeSeriesChart from './TimeSeriesChart';
import RangePicker from '../RangePicker/RangePicker';
import styles from './Dashboard.module.scss';
import { useGetDashboardKeyActionsQuery } from '../drApi/drApi';
import { getTimeSeriesData } from './dashboardHelper';
import { getHouses } from '../drApi/housesSelectors';
import KeyActionsTable from './KeyActionsTable';

const DashboardKeyActions = () => {
  const { t } = useTranslation();
  const defaultEnd = endOfToday();
  const defaultStart = startOfDay(sub(defaultEnd, { weeks: 1 }));

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const { isLoading, data = [] } = useGetDashboardKeyActionsQuery({
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const houses = useSelector(getHouses);
  const { byDate = [], byHouse = [] } = data;

  const aggregatedByDate = useMemo(() => getTimeSeriesData(startDate, endDate, byDate, 'count'), [byDate, endDate, startDate]);
  const aggregatedByHouse = useMemo(() => {
    return byHouse.map((row) => {
      const house = houses.find((h) => h._id === row._id);
      const {
        lda, bst, name,
      } = house;

      return {
        lda,
        bst,
        name,
        ...row,
      };
    });
  }, [byHouse, houses]);

  return (
    <>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultRanges={[1, 2, 3, 7, 14, 28, 90, 180]}
      />

      <div className={styles.chartCentered}>
        <div className={styles.chartWidth}>
          <TimeSeriesChart
            data={aggregatedByDate}
            title={t('Anzahl Schlüsselevents (pro Tag)')}
          />
        </div>

      </div>

      <h1 className={styles.header}>
        {`${t('Anzahl Schlüsselevents')} ${format(startDate, 'dd. MMM')} - ${format(endDate, 'dd. MMM')} (${t('pro Haus')})`}
      </h1>

      <div className={styles.tableWidth}>
        <KeyActionsTable
          data={aggregatedByHouse || []}
          isLoading={isLoading}
        />
      </div>

    </>
  );
};

export default DashboardKeyActions;

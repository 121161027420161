import React, {
  useCallback, useMemo,
} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import UserTypeOption from './UserTypeOption';
import UserTypeValueContainer from './UserTypeValueContainer';
import { USER_TYPES, USER_TYPES_FRIENDLY_NAME } from '../drApi/constants/userType';

const customSelectStyles = {
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '5px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    background: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    color: 'black',
    cursor: 'pointer',
    display: state.isDisabled ? 'none' : undefined,
  }),

};

const UserTypeSelect = ({ disabled }) => {
  const { t } = useTranslation();

  const userTypeOptions = Object.values(USER_TYPES).map((userType) => ({
    value: userType,
    label: t(USER_TYPES_FRIENDLY_NAME[userType]),
  }));

  const { setFieldValue, values } = useFormikContext();

  const onChange = useCallback((option) => {
    setFieldValue('userType', option.value);
  }, [setFieldValue]);

  const value = useMemo(() => {
    return userTypeOptions.find(({ value: optValue }) => optValue === values.userType);
  }, [userTypeOptions, values.userType]);

  return (
    <Select
      onChange={onChange}
      value={value}
      options={userTypeOptions}
      isDisabled={disabled}
      name="userType"
      components={{
        Option: UserTypeOption,
        ValueContainer: UserTypeValueContainer,
      }}
      styles={customSelectStyles}
    />
  );
};

UserTypeSelect.propTypes = {
  disabled: PropTypes.bool,
};

export default UserTypeSelect;

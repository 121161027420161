import React from 'react';
import PropTypes from 'prop-types';
import styles from './TourBadge.module.scss';

const TourBadge = ({ tourName, dark = false, className: classNameFromProps = '' }) => {
  const className = `${styles.wrapper} ${dark && styles.dark} ${classNameFromProps}`;
  return <div className={className}>{tourName}</div>;
};

TourBadge.propTypes = {
  tourName: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default TourBadge;

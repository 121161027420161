import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import styles from './NoManager.module.scss';
import { ReactComponent as LoginPin } from '../../img/pin.svg';

const NoManager = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <LoginPin className={styles.loginPin} />
        <div className={styles.welcome}>{t('Zugriff momentan leider nur für Manager')}</div>
        <button
          type="button"
          className={styles.loginButton}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          {t('Logout')}
        </button>
      </div>
    </div>
  );
};

export default NoManager;

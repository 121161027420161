/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globallySelectedHouseId: undefined,
  language: 'de',
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGloballySelectedHouseId(state, action) {
      const { houseId } = action.payload;
      state.globallySelectedHouseId = houseId;
    },
    setLanguage(state, action) {
      const { language } = action.payload;
      state.language = language;
    },
  },
});

export const {
  setGloballySelectedHouseId,
  setLanguage,
} = globalSlice.actions;

export default globalSlice.reducer;

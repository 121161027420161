import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnyChildren from '../../propTypes/AnyChildren';
import styles from './DeleteModal.module.scss';
import { ReactComponent as CloseIcon } from '../../img/leave.svg';

const DeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
}) => {
  const { t } = useTranslation();
  const overlayStyle = {
    overlay: { zIndex: 1000 },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      style={overlayStyle}
    >
      <div className={styles.modalHeader}>
        {t('Hinweis')}
        <button
          type="button"
          className={styles.modalClose}
          onClick={onClose}
        >
          <CloseIcon className={styles.modalCloseIcon} />
        </button>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.bodyFillerBefore} />
        <div className={styles.title}>{t(title)}</div>
        <div className={styles.content}>{t(content)}</div>
        <div className={styles.bodyFillerAfter} />
      </div>
      <div className={styles.modalFooter}>
        <button
          type="button"
          onClick={onClose}
          className={styles.abort}
        >
          {t('Zurück')}
        </button>
        <button
          type="button"
          onClick={onConfirm}
          className={styles.confirm}
        >
          {t('Entfernen')}
        </button>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: AnyChildren.isRequired,
  content: AnyChildren.isRequired,
};

export default DeleteModal;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './EditableCard.module.scss';
import Card from '../cards/Card';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import { ReactComponent as AbortIcon } from '../../img/leave.svg';
import { ReactComponent as ConfirmIcon } from '../../img/check_green.svg';
import { ReactComponent as DeleteIcon } from '../../img/bin_red.svg';
import { ReactComponent as LoaderIcon } from '../../img/loader.svg';
import AnyChildren from '../../propTypes/AnyChildren';
import DeleteModal from '../DeleteModal/DeleteModal';

const EditableCard = ({
  isEditing,
  isLoading,
  onEditingChange,
  canDelete,
  withDeleteModal = false,
  deleteModalTitle = null,
  deleteModalContent = null,
  onDelete = false,
  canAbort = true,
  onAbort,
  onAccept,
  canAccept = true,
  canEdit = true,
  children,
  ...props
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const startEditing = useCallback(
    (event) => {
      event.preventDefault();
      onEditingChange(true);
    },
    [onEditingChange],
  );

  const stopEditing = useCallback(
    (event) => {
      event.preventDefault();
      onAbort();
      onEditingChange(false);
    },
    [onAbort, onEditingChange],
  );

  const accept = useCallback(() => {
    onAccept();
  }, [onAccept]);

  const handleDelete = useCallback(() => {
    if (withDeleteModal) {
      setDeleteModalOpen(true);
    } else {
      onDelete();
    }
  }, [onDelete, withDeleteModal]);
  const onDeleteConfirm = useCallback(() => {
    setDeleteModalOpen(false);
    onDelete();
  }, [onDelete]);

  const onDeleteAbort = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  let icons = null;

  if (isLoading) {
    icons = <LoaderIcon className={styles.iconButton} />;
  } else if (isEditing) {
    icons = [];
    if (canDelete) {
      icons.push(
        <button key="delete" type="button" className={styles.iconButton} onClick={handleDelete}>
          <DeleteIcon className={styles.icon} />
        </button>,
      );
    }
    if (canAbort) {
      icons.push(
        <button key="abort" type="button" className={styles.iconButton} onClick={stopEditing}>
          <AbortIcon className={styles.icon} />
        </button>,
      );
    }
    if (canAccept) {
      icons.push(
        <button key="accept" type="button" className={styles.iconButton} onClick={accept}>
          <ConfirmIcon className={styles.icon} />
        </button>,
      );
    }
  } else if (canEdit) {
    icons = (
      <button type="button" className={styles.iconButton} onClick={startEditing}>
        <EditIcon className={styles.icon} />
      </button>
    );
  }
  return (
    <Card {...props}>
      {withDeleteModal && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={onDeleteAbort}
          onConfirm={onDeleteConfirm}
          title={deleteModalTitle}
          content={deleteModalContent}
        />
      )}
      <div className={styles.iconBar}>{icons}</div>
      {children}
    </Card>
  );
};

EditableCard.propTypes = {
  canEdit: PropTypes.bool,
  canAccept: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  onEditingChange: PropTypes.func,
  isLoading: PropTypes.bool,
  canDelete: PropTypes.bool,
  withDeleteModal: PropTypes.bool,
  deleteModalTitle: PropTypes.string,
  deleteModalContent: PropTypes.string,
  onDelete: PropTypes.func,
  canAbort: PropTypes.bool,
  onAbort: PropTypes.func,
  onAccept: PropTypes.func,
  children: AnyChildren,
};

export default EditableCard;

import React from 'react';
import sematable from 'sematable';
import PropTypes from 'prop-types';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLTable from '../recordsList/RLTable';
import RLTableLoader from '../recordsList/RLTableLoader';
import RLSortHeader from '../recordsList/RLSortHeader';
import DashboardTableRow from './DashboardTableRow';
import { CARD_TYPES_CREATEABLE } from '../drApi/constants/cardType';

const columns = [
  {
    key: '_id', header: 'ID', hidden: true, primaryKey: true,
  },
  { key: 'lda', header: 'LDA', sortable: true },
  { key: 'bst', header: 'BST', sortable: true },
  { key: 'name', header: 'Haus', sortable: true },
];

// eslint-disable-next-line guard-for-in
for (const cardType in CARD_TYPES_CREATEABLE) {
  columns.push({
    key: cardType, header: CARD_TYPES_CREATEABLE[cardType].human, sortable: true,
  });
}

const CardTypeTable = ({
  data,
  headers,
  isLoading = false,
}) => {
  return (
    <>
      {isLoading
        ? <RLTableLoader />
        : (
          <RLTable>
            <thead>
              <RLHeaderRow>
                {columns.map((col) => {
                  if (col.hidden) return null;
                  return <RLSortHeader key={col.key} {...headers[col.key]} />;
                })}
              </RLHeaderRow>
            </thead>
            <tbody>
              {data.map((row) => {
                const { _id } = row;
                return <DashboardTableRow key={_id} row={row} columns={columns} />;
              })}
            </tbody>
          </RLTable>
        )}
    </>
  );
};

CardTypeTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  headers: PropTypes.objectOf(PropTypes.any).isRequired,

  isLoading: PropTypes.bool,
};

export default sematable('CardTypeTable', CardTypeTable, columns, {
  plain: true,
  defaultPageSize: 9999,
  sortKey: 'name',
  sortDirection: 'asc',
});

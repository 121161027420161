import { createSelector } from '@reduxjs/toolkit';
import { drApi } from './drApi';

const getKeyInformationData = (keyInformationId) => createSelector(
  drApi.endpoints.getKeyInformation.select({ keyInformationId }),
  (query) => { return query?.data; },
);

export const getCanDeleteKeyInstance = (keyInformationId) => createSelector(
  getKeyInformationData(keyInformationId),
  (data) => {
    return data?.keyInstances?.length > 1;
  },
);

export const getOtherKeyInstanceNames = (keyInformationId, keyInstanceId) => createSelector(
  getKeyInformationData(keyInformationId),
  (data) => {
    const keyInstances = data?.keyInstances;
    if (!keyInstances) {
      return [];
    }
    const otherKeyInstances = keyInstances
      .filter((keyInstance) => keyInstance._id !== keyInstanceId);
    return otherKeyInstances.map((keyInstance) => keyInstance.name);
  },
);

import { drApi } from '../../drApi/drApi';

const addKeyInstance = ({ keyInformationId }) => (dispatch) => {
  return dispatch(drApi.util.updateQueryData('getKeyInformation', { keyInformationId }, (record) => {
    record.keyInstances.push({
      _id: '_NEW_ELEMENT_DUMMY_ID',
      isDraft: true,
      name: '',
      currentUser: null,
      currentPosition: 'CABINET',
    });
    return record;
  }));
};

export default addKeyInstance;

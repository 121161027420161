import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { number } from 'prop-types';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import {
  startOfDay, endOfDay, sub, endOfToday,
} from 'date-fns';
import { getDateFnsLocale } from '../drApi/languageSelectors';
import styles from './RangePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const RangePicker = ({
  startDate, endDate, setStartDate, setEndDate, defaultRanges = [],
}) => {
  const { t } = useTranslation();
  const locale = useSelector(getDateFnsLocale);
  useEffect(() => {
    setDefaultLocale(locale);
  }, [locale]);

  return (
    <div className={styles.datePickerContainer}>

      {defaultRanges.map((r) => {
        return (
          <button
            key={r}
            className={styles.optionButtons}
            type="button"
            onClick={() => {
              const today = endOfToday();
              setEndDate(today);
              setStartDate(startOfDay(sub(today, { days: r - 1 })));
            }}
          >
            {`${r} ${r > 1 ? t('Tage') : t('Tag')}`}
          </button>
        );
      })}

      <div className={styles.marginLeft}>
        <DatePicker
          dateFormat="dd. MMMM yyyy"
          selected={startDate}
          onChange={(date) => setStartDate(startOfDay(date))}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <div className={styles.marginLeft}>
        bis
      </div>

      <div className={styles.marginLeft}>
        <DatePicker
          dateFormat="dd. MMMM yyyy"
          selected={endDate}
          onChange={(date) => setEndDate(endOfDay(date))}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>

    </div>
  );
};

RangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  defaultRanges: PropTypes.arrayOf(number),
};

export default RangePicker;

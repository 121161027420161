import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowUp } from '../../img/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../img/arrow_down.svg';
import { ReactComponent as ArrowUpDown } from '../../img/arrow_updown.svg';
import styles from './RLSortHeader.module.scss';
import RLHeaderCell from './RLHeaderCell';

const RLSortHeader = ({
  name, sortKey, sorted, handleClick,
}) => {
  const { t } = useTranslation();
  let Icon;
  switch (sorted) {
    case 'asc':
      Icon = ArrowUp;
      break;
    case 'desc':
      Icon = ArrowDown;
      break;
    default:
      Icon = ArrowUpDown;
  }
  return (
    <RLHeaderCell
      className={styles.cell}
      onClick={() => handleClick(sortKey)}
      role="button"
    >
      {/* This extra wrapper is necessary because
      otherwise the name and icon are not vertically centered */}
      <div className={styles.wrapper}>
        {t(name)}
        <Icon className={styles.icon} />
      </div>

    </RLHeaderCell>
  );
};

RLSortHeader.propTypes = {
  name: PropTypes.string.isRequired,
  sortKey: PropTypes.string.isRequired,
  sorted: PropTypes.oneOf(['asc', 'desc', undefined]),
  handleClick: PropTypes.func.isRequired,
};

export default RLSortHeader;

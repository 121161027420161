import React from 'react';
import { useParams } from 'react-router-dom';
import StopExtraSection from './StopExtraSection';
import { CARD_TYPES_ORDER } from '../drApi/constants/cardType';
import CardsContainer from '../cards/CardsContainer';

const StopExtraSections = () => {
  const { stopId } = useParams();
  return (
    <CardsContainer>
      {CARD_TYPES_ORDER.map((cardType) => {
        return (
          <StopExtraSection
            key={cardType}
            stopId={stopId}
            cardType={cardType}
          />
        );
      })}
    </CardsContainer>
  );
};

export default StopExtraSections;

import React from 'react';
import { useSelector } from 'react-redux';
import { useGetKeyInstancesQuery } from '../drApi/drApi';
import KeysTable from './KeysTable';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const Keys = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading, data } = useGetKeyInstancesQuery(activeHouseId);
  return <KeysTable isLoading={isLoading} data={data || []} />;
};

export default Keys;

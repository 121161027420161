import React from 'react';
import PropTypes from 'prop-types';
import RLCell from '../recordsList/RLCell';
import RLRow from '../recordsList/RLRow';

const DashboardTableRow = ({ row, columns }) => {
  return (
    <RLRow>
      {columns.map((col) => {
        if (col.hidden) return null;
        const content = row[col.key];
        return <RLCell key={col.key + row._id}>{content}</RLCell>;
      })}
    </RLRow>
  );
};

DashboardTableRow.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DashboardTableRow;

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserMdeId } from '../drApi/userSelectors';
import Card from '../cards/Card';
import styles from './MdeIdCard.module.scss';

const MdeIdCard = () => {
  const { userId } = useParams();
  const { t } = useTranslation();

  const mdeId = useSelector(getUserMdeId(userId));

  return (
    <Card
      title={t('Mitarbeiternummer')}
    >
      <div className={styles.container}>
        {mdeId}
      </div>
    </Card>
  );
};

export default MdeIdCard;

import React from 'react';
import { useSelector } from 'react-redux';
import { useGetUsersQuery } from '../drApi/drApi';
import UsersTable from './UsersTable';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const Users = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading, data } = useGetUsersQuery(activeHouseId);
  return <UsersTable isLoading={isLoading} data={data || []} />;
};

export default Users;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import Modal from 'react-modal';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import './i18next/i18next';

const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_AUTH0_CLIENT_ID } = process.env;

Modal.setAppElement('#root');

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience="https://api.gc-roadbook.de/"
        cacheLocation="localstorage"
      >
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

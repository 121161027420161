import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './AddButton.module.scss';
import { ReactComponent as AddIcon } from '../../img/plus_white.svg';
import { useAddElementMutation, useGetS3UploadDataMutation } from '../drApi/drApi';
import uploadImageToS3 from '../../utils/uploadImageToS3';
import { errorToast } from '../toasts/toastsThunks';
import { CARD_TYPES } from '../drApi/constants/cardType';
import { ELEMENT_TYPES } from '../drApi/constants/elementType';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const AddButton = ({ inMainSection = false, onClick, stopId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const houseId = useSelector(getGloballySelectedHouseId);

  const className = inMainSection ? styles.mainCard : styles.extraCard;

  const [getS3UploadData] = useGetS3UploadDataMutation();
  const [addElement] = useAddElementMutation();

  const [showPicker, setShowPicker] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (inMainSection) {
      return setShowPicker(true);
    }
    return onClick();
  };

  const addInfo = () => {
    onClick();
  };

  const onFilePicked = async (e) => {
    const file = e.currentTarget.files?.[0];
    const { data: s3UploadData } = await getS3UploadData({ filename: file.name, usePresignedPost: true });

    const imageUrl = await uploadImageToS3({ file, s3UploadData });

    if (!imageUrl) {
      dispatch(errorToast({ text: t('Upload fehlgeschlagen') }));
    } else {
      await addElement({
        stopId,
        houseId,
        cardType: CARD_TYPES.APPROACH_OFFLOAD,
        body: {
          element: {
            type: ELEMENT_TYPES.IMAGE,
            img_url: { latest: imageUrl, original: imageUrl },
          },
        },
      });
    }
  };

  return (
    <div onMouseLeave={() => setShowPicker(false)} className={className}>
      <button
        type="button"
        className={styles.circle}
        onClick={handleButtonClick}
        onMouseEnter={() => setShowPicker(true)}
      >
        <AddIcon className={styles.icon} />
      </button>

      {inMainSection && showPicker && (
        <>
          <div className={styles.menu}>
            <button type="button" className={styles.menuItem} onClick={addInfo}>
              {t('Info hinzufügen')}
            </button>
            <button type="button" className={styles.menuItem} onClick={() => fileInputRef?.current?.click()}>
              {t('Foto hochladen')}
            </button>
          </div>
        </>
      )}

      {/* dummy input element with ref */}
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => onFilePicked(e)}
        accept="image/*"
      />
    </div>
  );
};

AddButton.propTypes = {
  inMainSection: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  stopId: PropTypes.string,
};

export default AddButton;

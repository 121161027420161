import React from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import styles from './Dashboard.module.scss';
import DashboardRoutes from './DashboardRoutes';
import DashboardCards from './DashboardCards';
import DashboardUsers from './DashboardUsers';
import DashboardKeyActions from './DashboardKeyActions';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Tabs selectedTabClassName={styles.tabSelected} className={styles.paddingBottom}>
      <div className={styles.tabPadding}>
        <TabList>
          <Tab>{t('Stops & Tours')}</Tab>
          <Tab>{t('Stopinfos')}</Tab>
          <Tab>{t('Anzahl Nutzer')}</Tab>
          <Tab>{t('Schlüssel Events')}</Tab>
        </TabList>
      </div>

      <TabPanel>
        <DashboardRoutes />
      </TabPanel>
      <TabPanel>
        <DashboardCards />
      </TabPanel>
      <TabPanel>
        <DashboardUsers />
      </TabPanel>
      <TabPanel>
        <DashboardKeyActions />
      </TabPanel>
    </Tabs>
  );
};

export default Dashboard;

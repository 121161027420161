import PropTypes from 'prop-types';
import React from 'react';
import styles from './KeyIdBadge.module.scss';

const KeyIdBadge = ({ name, className: classNameFromProps = '' }) => {
  return <div className={`${styles.main} ${classNameFromProps}`}>{name}</div>;
};

KeyIdBadge.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default KeyIdBadge;

import React, { useMemo, useState } from 'react';
import {
  endOfToday, sub, startOfDay, format,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import TimeSeriesChart from './TimeSeriesChart';
import RangePicker from '../RangePicker/RangePicker';
import styles from './Dashboard.module.scss';
import { useGetDashboardCardsQuery } from '../drApi/drApi';
import { CARD_TYPES_CREATEABLE } from '../drApi/constants/cardType';
import { getTimeSeriesData } from './dashboardHelper';
import UserTypePieChart from './UserTypePieChart';
import CardTypeTable from './CardTypeTable';

const DashboardCards = () => {
  const { t } = useTranslation();
  const defaultEnd = endOfToday();
  const defaultStart = startOfDay(sub(defaultEnd, { months: 1 }));

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const { isLoading, data = [] } = useGetDashboardCardsQuery({
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const { byDate = [], byHouse = [], byUser = [] } = data;

  const aggregatedCardDataByCategory = useMemo(() => {
    const dict = {};
    byDate.forEach((el) => {
      if (el.cardType in dict) {
        dict[el.cardType].push(el);
      } else {
        dict[el.cardType] = [el];
      }
    });

    Object.keys(CARD_TYPES_CREATEABLE).forEach((category) => {
      dict[category] = getTimeSeriesData(startDate, endDate, dict[category], 'count');
    });

    return dict;
  }, [byDate, endDate, startDate]);

  const aggregatedCardDataByHouse = useMemo(() => {
    return byHouse.map((hs) => {
      const mappedHouse = {};
      Object.keys(CARD_TYPES_CREATEABLE).forEach((key) => {
        mappedHouse[key] = 0;
      });
      hs.cardTypes.forEach((ct) => {
        mappedHouse[ct.cardType] = ct.count;
      });
      return { ...mappedHouse, ...hs.house };
    });
  }, [byHouse]);

  return (
    <>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultRanges={[1, 2, 3, 7, 14, 28, 90, 180]}
      />

      <div className={`${styles.chartWrapper} ${styles.threeColumns}`}>

        {Object.keys(CARD_TYPES_CREATEABLE).map((category) => {
          return (
            <div>
              <TimeSeriesChart
                key={category}
                data={aggregatedCardDataByCategory[category] || []}
                title={t(CARD_TYPES_CREATEABLE[category].human)}
              />
            </div>
          );
        })}

        <div className={styles.pieWrapper}>
          <UserTypePieChart data={byUser} dimension="count" title={t('Erstellte Stopinfos nach Usertyp')} />
        </div>

      </div>

      <h1 className={styles.header}>
        {`${t('Erstellte Stopinfos')} ${format(startDate, 'dd. MMM')} - ${format(endDate, 'dd. MMM')} (${t('pro Haus')})`}
      </h1>

      <div className={styles.tableWidth}>
        <CardTypeTable data={aggregatedCardDataByHouse || []} isLoading={isLoading} />
      </div>

    </>
  );
};

export default DashboardCards;

import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMeQuery } from '../drApi/drApi';
import SideMenu from '../SideMenu/SideMenu';
import styles from './LoggedInRoot.module.scss';
import MainArea from '../mainArea/MainArea';
import { getHasPermission, getIsManager, getMyHouseId } from '../drApi/meSelectors';
import FullPageLoader from '../loaders/FullPageLoader';
import { setGloballySelectedHouseId } from '../slices/globalSlice';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const LoggedInRoot = () => {
  const isManager = useSelector(getIsManager);
  const { isLoading, isSuccess, isError } = useGetMeQuery();
  const myHouseId = useSelector(getMyHouseId);
  const dispatch = useDispatch();
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const isGlobalAdmin = useSelector(getHasPermission('global_admin'));

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (isSuccess && !isManager) {
    return <Redirect to="nomanager" />;
  }

  if (isError) {
    // TODO handle error
    return 'Error';
  }

  if (!activeHouseId || (!isGlobalAdmin && (myHouseId !== activeHouseId))) {
    dispatch(setGloballySelectedHouseId({ houseId: myHouseId }));
  }

  return (
    <>
      <div className={styles.main}>
        <SideMenu />
        <MainArea />
      </div>

      <Switch />
    </>
  );
};

export default withAuthenticationRequired(LoggedInRoot);

import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAddKeyInformationMutation } from '../drApi/drApi';
import TitleBar from '../detailsPageComponents/TitleBar';
import CardsContainer from '../cards/CardsContainer';
import Card from '../cards/Card';
import { KEY_TYPES } from '../drApi/constants/keyType';
import { KEY_POSITION_TYPES } from '../drApi/constants/keyPositionType';
import CardTextArea from '../EditableCard/CardTextArea';
import ImageDisabledCardContent from '../detailsPageComponents/ImageDisabledCardContent';
import StopListField from './fields/StopListField';
import CardSectionTitle from '../detailsPageComponents/CardSectionTitle';
import KeyInstanceField from './fields/KeyInstanceField';
import styles from './KeyInformation.module.scss';
import KeyTypesField from './fields/KeyTypesField';
import KeyIdBadge from '../KeyIdBadge/KeyIdBadge';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const NewKeyInformation = () => {
  const { goBack, push: historyPush } = useHistory();
  const houseId = useSelector(getGloballySelectedHouseId);
  const { t } = useTranslation();

  const [addKeyInformation, { data, isLoading, isSuccess, isError }] = useAddKeyInformationMutation();

  useEffect(() => {
    if (isSuccess) {
      const { keyInformationId } = data;
      historyPush(`/keys/${keyInformationId}`);
    }
  }, [isSuccess, isError, data, historyPush]);

  const onSubmit = useCallback(
    (values) => {
      const { note, stops, keyInstance, keyTypes } = values;
      addKeyInformation({
        body: {
          newKeyInformation: {
            houseId,
            keyTypes,
            image: null,
            note,
            // weird format for api...
            stopIds: stops
              .filter((s) => !!s)
              .reduce((red, stop, index) => {
                return { ...red, [index]: { newStop: stop._id } };
              }, {}),
          },
          newKeyInstances: [keyInstance],
        },
      });
    },
    [addKeyInformation, houseId],
  );

  const onCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <Formik
      initialValues={{
        note: '',
        stops: [],
        keyInstance: {
          name: '',
          currentPosition: KEY_POSITION_TYPES.CABINET,
          currentUser: null,
        },
        keyTypes: {
          [KEY_TYPES.KEY]: 0,
          [KEY_TYPES.CARD]: 0,
          [KEY_TYPES.REMOTE]: 0,
        },
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values: {
          keyInstance: { name },
        },
      }) => (
        <>
          <TitleBar
            title={<KeyIdBadge name={name || ''} className={!name ? styles.hidden : ''} />}
            isLoading={isLoading}
            onAccept={handleSubmit}
            onCancel={onCancel}
            acceptLabel="Schlüssel Anlegen"
          />
          <CardsContainer>
            <Card title={t('Bemerkung')} large>
              <CardTextArea name="note" disabled={isLoading} />
            </Card>
            <Card title={t('Stopadressen')} large>
              <StopListField name="stops" isEditing />
            </Card>
            <Card title={t('Informationen')} large>
              <KeyTypesField name="keyTypes" isEditing />
            </Card>
            <Card title={t('Bild')} large>
              <ImageDisabledCardContent />
            </Card>
          </CardsContainer>
          <CardsContainer>
            <CardsContainer vertical>
              <CardSectionTitle title={t('Schlüssel-ID')} />
              <Card autoHeight>
                <KeyInstanceField isEditing namePrefix="keyInstance." />
              </Card>
            </CardsContainer>
            <CardsContainer vertical>
              <CardSectionTitle title={t('Weitere Schlüssel IDs')} />
              <div className={styles.addMoreKeys}>
                {t('Weitere Schlüssel IDs können im nächsten Schritt hinzugefügt werden')}
              </div>
            </CardsContainer>
          </CardsContainer>
        </>
      )}
    </Formik>
  );
};

export default NewKeyInformation;

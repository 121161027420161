import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../cards/Card';
import { CARD_TYPES } from '../drApi/constants/cardType';
import { ELEMENT_TYPES } from '../drApi/constants/elementType';
import ImageElement from './ElementCards/ImageElement';
import NoteElement from './ElementCards/NoteElement';
import IconTextElement from './ElementCards/IconTextElement';
import { getStopCardElementType } from '../drApi/stopSelectors';

const StopElementCard = ({ stopId, elementId, cardType, inMainSection = false }) => {
  const { t } = useTranslation();
  const elementType = useSelector(getStopCardElementType(stopId, cardType, elementId));

  if (cardType === CARD_TYPES.APPROACH_OFFLOAD) {
    switch (elementType) {
      case ELEMENT_TYPES.IMAGE:
      case ELEMENT_TYPES.MAP:
        return (
          <ImageElement
            stopId={stopId}
            elementId={elementId}
            cardType={cardType}
            large={inMainSection}
            title={elementType === ELEMENT_TYPES.MAP ? t('Karte') : t('Bild')}
          />
        );
      case ELEMENT_TYPES.NOTE:
        return <NoteElement stopId={stopId} elementId={elementId} cardType={cardType} large={inMainSection} />;
      default:
      // noop
    }
  } else if (cardType === CARD_TYPES.PHONE_NUMBERS && elementType === ELEMENT_TYPES.PHONE_CONTACT) {
    return (
      <IconTextElement
        title={t('Telefonnummer')}
        icon="phone"
        elementPropPath="phone_contact.number"
        stopId={stopId}
        cardType={cardType}
        elementId={elementId}
        large={inMainSection}
      />
    );
  } else if (cardType === CARD_TYPES.OFFLOAD_TIME && elementType === ELEMENT_TYPES.NOTE) {
    return (
      <IconTextElement
        title={t('Zeiten')}
        icon="clock"
        elementPropPath="text"
        stopId={stopId}
        cardType={cardType}
        elementId={elementId}
        large={inMainSection}
      />
    );
  } else if (cardType === CARD_TYPES.PINCODE && elementType === ELEMENT_TYPES.NOTE) {
    return (
      <IconTextElement
        title={t('Pin-Code')}
        icon="pinCode"
        elementPropPath="text"
        stopId={stopId}
        cardType={cardType}
        elementId={elementId}
        large={inMainSection}
      />
    );
  }

  return (
    <Card>
      Kann Element nicht rendern für {cardType} &gt; {elementType}
    </Card>
  );
};

StopElementCard.propTypes = {
  stopId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  inMainSection: PropTypes.bool,
};

export default StopElementCard;

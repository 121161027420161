import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import RLHeaderCell from '../../recordsList/RLHeaderCell';
import InfoFilterPopup from './InfoFilterPopup';
import useQueryParam from '../../url/useQueryParam';
import RLFilterIcon from '../../recordsList/RLFilterIcon';
import styles from './RoutesFilterHeader.module.scss';

const RoutesFilterHeader = () => {
  const { t } = useTranslation();

  const [infos] = useQueryParam('infos');
  const [filterOpen, setFilterOpen] = useState(false);

  const closeFilter = () => {
    setFilterOpen(false);
  };

  const openFilter = () => {
    setFilterOpen(true);
  };

  return (
    <RLHeaderCell right>
      <div className={styles.desc}>{t('Infos')}</div>
      <Popover positions={['bottom']} padding={10} onClickOutside={closeFilter} isOpen={filterOpen} content={<InfoFilterPopup />}>
        <RLFilterIcon active={infos?.length > 0} onClick={filterOpen ? closeFilter : openFilter} />
      </Popover>
    </RLHeaderCell>
  );
};

export default RoutesFilterHeader;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetHousesQuery } from '../drApi/drApi';
import { setGloballySelectedHouseId } from '../slices/globalSlice';
import { getGloballySelectedHouse } from '../drApi/housesSelectors';

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    zIndex: 100,
  }),
  menu: (provided) => ({
    ...provided,
    background: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    color: 'white',
    background: (state.isFocused || state.isSelected) ? 'gray' : '',
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    color: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    width: 200,
  }),
  input: (provided) => ({
    ...provided,
    outline: 'none',
    color: 'white',
  }),
  singleValue: (provided) => {
    const transition = 'opacity 300ms';

    return {
      ...provided,
      transition,
      color: 'white',
      fontSize: 12,
      textDecoration: 'underline',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    borderLeft: '1px solid white',
  }),
};

const hiddenPaths = ['/dashboard', '/importlog'];

const HouseSwitcher = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetHousesQuery();
  const activeHouse = useSelector(getGloballySelectedHouse);
  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = useCallback(async (value) => {
    const { _id: selectedHouseId } = value;
    dispatch(setGloballySelectedHouseId({ houseId: selectedHouseId }));
    history.push(`/${history.location.pathname.split('/')[1]}`);
  }, [dispatch, history]);

  if (hiddenPaths.includes(history.location.pathname)) {
    return null;
  }

  return (
    <Select
      isLoading={isLoading}
      styles={customStyles}
      placeholder={t('Haus auswählen')}
      options={data}
      getOptionValue={(option) => option._id}
      getOptionLabel={(option) => option.name}
      value={activeHouse}
      onChange={onChange}
    />
  );
};

export default HouseSwitcher;

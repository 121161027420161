import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStopCustomerName } from '../drApi/stopSelectors';
import { useGetStopQuery } from '../drApi/drApi';
import StopMainSection from './StopMainSection';
import StopExtraSections from './StopExtraSections';
import DetailsPageLoader from '../loaders/DetailsPageLoader';
import TitleBar from '../detailsPageComponents/TitleBar';

const StopDetails = () => {
  const { stopId } = useParams();

  const { isLoading } = useGetStopQuery({ stopId });
  const customerName = useSelector(getStopCustomerName(stopId));

  if (isLoading) {
    return <DetailsPageLoader />;
  }

  return (
    <>
      <TitleBar title={customerName} />
      <StopMainSection />
      <StopExtraSections />
    </>
  );
};

export default StopDetails;

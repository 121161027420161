import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import StopSelect from '../../StopSelect/StopSelect';
import IconBadge from '../../IconBadge/IconBadge';
import { ReactComponent as CloseIcon } from '../../../img/leave.svg';
import styles from './StopListField.module.scss';

const StopListField = ({ name, isEditing }) => {
  const [, meta, helpers] = useField(name);

  const { value: stops } = meta;
  const { setValue } = helpers;
  const removeStop = useCallback((indexToRemove) => {
    setValue(stops.filter((stop, index) => index !== indexToRemove));
  }, [setValue, stops]);
  const addStop = useCallback(() => {
    setValue([...stops, null]);
  }, [setValue, stops]);

  const stopIds = stops.map((stop) => stop?._id);
  return (
    <div className={styles.main}>
      {stops.map((stop, index) => {
        return (
          <div key={stop ? stop._id : index} className={styles.row}>
            <IconBadge icon="flag" />
            <div className={styles.stopSelectWrapper}>
              <StopSelect
                // do not give the user the option to select a stop that is already selected
                excludeStopIds={stopIds}
                value={stop}
                disabled={!isEditing}
                onChange={(newStop) => {
                  setValue(
                    stops.map((s, i) => {
                      return (i === index) ? newStop : s;
                    }),
                  );
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => removeStop(index)}
              disabled={!isEditing}
            >
              {isEditing && <CloseIcon />}
            </button>
          </div>
        );
      })}
      {isEditing && (
        <button
          type="button"
          onClick={addStop}
          disabled={!isEditing}
          className={styles.row}
        >
          <IconBadge icon="plus" />
        </button>
      )}
    </div>
  );
};

StopListField.propTypes = {
  name: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};
export default StopListField;

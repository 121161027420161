import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const Card = ({
  title,
  className: classNameFromProps = '',
  children,
  large = false,
  autoHeight = false,
}) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={`${styles.card} ${classNameFromProps} ${large ? styles.large : ''} ${autoHeight ? styles.autoHeight : ''}`}>
        {title && <div className={styles.title}>{title}</div>}
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: AnyChildren,
  large: PropTypes.bool,
  autoHeight: PropTypes.bool,
};
export default Card;

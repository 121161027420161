import React from 'react';
import PropTypes from 'prop-types';
import rLStyles from './RecordsList.module.scss';
import AnyChildren from '../../propTypes/AnyChildren';

const RLHeaderCell = ({
  children, className = '', right = false, ...props
}) => {
  let classNames = `${className} ${rLStyles.headerCell}`;
  if (right) {
    classNames += ` ${rLStyles.right}`;
  }
  return <th className={classNames} {...props}>{children}</th>;
};

RLHeaderCell.propTypes = {
  children: AnyChildren,
  className: PropTypes.string,
  right: PropTypes.bool,
};

export default RLHeaderCell;

import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useUpdateKeyInformationMutation } from '../../drApi/drApi';
import arraysAreEqual from '../../../utils/arraysAreEqual';
import StopListField from '../fields/StopListField';

const NoteEditableCard = ({ keyInformation }) => {
  const { t } = useTranslation();
  const { _id: keyInformationId, stops } = keyInformation;
  const [isEditing, setIsEditing] = useState(false);

  const [
    updateKeyInformation, { isSuccess, isLoading },
  ] = useUpdateKeyInformationMutation();

  const onSubmit = useCallback((values) => {
    const { stops: newStops } = values;
    const oldStopIds = stops.map((s) => s?._id);
    const newStopIds = newStops.filter((s) => !!s).map((s) => s._id);
    if (arraysAreEqual(oldStopIds, newStopIds)) {
      setIsEditing(false);
      return;
    }
    // very weird format for api...
    // this makes no sense whatsoever:
    // the format is { [index]: { newStop, oldStop } }
    // (A) if the key [index] exists:
    //    IF newStop exists
    //      it (1) sets the newStop at the corresponding index in keyInformation.stopIds AND
    //      (2) adds the keyInformation to the stop.keyInformation
    //    ELSE IF newStop does not exists
    //      it (1) removes the index from the keyInformation.stopIds
    // (B) if { oldStop } exists at _any_ index it will remove the key from oldStop
    const newStopIdsWeirdBackendFormat = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Math.max(oldStopIds.length, newStopIds.length); ++i) {
      const oldStopId = oldStopIds[i];
      const newStopId = newStopIds[i];
      if (oldStopId !== newStopId) {
        newStopIdsWeirdBackendFormat[i] = { newStop: newStopId };
        // only set oldStop in case it is not present anymore in the newStopIds
        // if it just changed the position we do _not_ want to set it
        // because the keyInformation would be removed from the stop then.
        if (newStopIds.indexOf(oldStopId) === -1) {
          newStopIdsWeirdBackendFormat[i].oldStop = oldStopId;
        }
      }
    }
    updateKeyInformation({
      keyInformationId,
      updateFieldsInformation: {
        stopIds: newStopIdsWeirdBackendFormat,
      },
    });
  }, [keyInformationId, stops, updateKeyInformation]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);
  return (
    <Formik
      initialValues={{ stops }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleReset,
        handleSubmit,
      }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          title={t('Stopadressen')}
          canDelete={false}
          canAbort
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          large
        >
          <StopListField
            isEditing={isEditing}
            name="stops"
          />
        </EditableCard>
      )}
    </Formik>
  );
};

NoteEditableCard.propTypes = {
  keyInformation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    stops: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string.isRequired,
      stopName: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default NoteEditableCard;

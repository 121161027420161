import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardTextInput.module.scss';

const CardTextInput = ({ className: classNameFromProps, ...props }) => {
  const className = `${styles.main} ${classNameFromProps}`;
  return <input type="text" className={className} {...props} />;
};

CardTextInput.propTypes = {
  className: PropTypes.string,
};

export default CardTextInput;

import React from 'react';
import PropTypes from 'prop-types';
import sematable from 'sematable';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RoundInitialsButton from '../RoundInitialsButton/RoundInitialsButton';
import RLSortHeader from '../recordsList/RLSortHeader';
import { ReactComponent as CaretRightIcon } from '../../img/caret_right.svg';
import TourBadge from '../TourBadge/TourBadge';
import UserTypeBadge from '../UserTypeBadge/UserTypeBadge';
import styles from './UsersTable.module.scss';
import RLHeaderCell from '../recordsList/RLHeaderCell';
import RLRow from '../recordsList/RLRow';
import RLCell from '../recordsList/RLCell';
import RLTable from '../recordsList/RLTable';
import RLHeaderRow from '../recordsList/RLHeaderRow';
import RLTableLoader from '../recordsList/RLTableLoader';

const columns = [
  { key: '_id', header: 'ID', primaryKey: true },
  { key: 'name', header: 'Fahrername', sortable: true },
  { key: 'permanentTourName', header: 'Stammtour', sortable: true },
  { key: 'userType', header: 'Typ' },
];

const UsersTable = ({
  isLoading,
  headers: { name: nameHeader, permanentTourName: permanentTourNameHeader },
  data,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const toUser = (userId) => {
    history.push(`/users/${userId}`);
  };
  return (
    <>
      <RLTable>
        <thead>
          <RLHeaderRow>
            <RLSortHeader {...nameHeader} />
            <RLSortHeader {...permanentTourNameHeader} />
            <RLHeaderCell right>{t('Typ')}</RLHeaderCell>
            <RLHeaderCell />
          </RLHeaderRow>
        </thead>
        <tbody>
          {data.map((user) => {
            const {
              _id, name, permanentTourName, userType,
            } = user;
            return (
              <RLRow
                key={_id}
                onClick={() => { toUser(_id); }}
                role="button"
              >
                <RLCell>
                  <RoundInitialsButton
                    userId={_id}
                    name={name}
                    noLink
                    className={styles.initials}
                  />
                  {' '}
                  {name}
                </RLCell>
                <RLCell>
                  {permanentTourName && <TourBadge tourName={permanentTourName} />}
                </RLCell>
                <RLCell right>
                  <UserTypeBadge userType={userType} />
                </RLCell>
                <RLCell right>
                  <CaretRightIcon />
                </RLCell>
              </RLRow>
            );
          })}
        </tbody>
      </RLTable>
      {isLoading && <RLTableLoader />}
    </>
  );
};

UsersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default sematable('users', UsersTable, columns, {
  plain: true,
  sortKey: 'name',
  defaultPageSize: 9999,
});

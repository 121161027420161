import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGetUsersQuery, usePatchRouteMutation } from '../drApi/drApi';
import FilterSelect from '../FilterSelect/FilterSelect';
import { getUserOptions, getUsersForCurrentHouse } from '../drApi/usersSelectors';
import styles from './ChangePermanentUser.module.scss';
import { getRoutePermanentUserId, getRouteAssignedUserIds } from '../drApi/routeSelectors';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

export const ChangeUserType = {
  PERMANENT: 'PERMANENT',
  ASSIGNED: 'ASSIGNED',
};

const ChangeUser = ({ routeId, changeType }) => {
  const allUsers = useSelector(getUsersForCurrentHouse);
  const [patchRoute] = usePatchRouteMutation();
  const userOptions = useSelector(getUserOptions);
  const permanentUserId = useSelector(getRoutePermanentUserId(routeId));
  const assignedUserIds = useSelector(getRouteAssignedUserIds(routeId));
  const onChange = useCallback((newUserId) => {
    let patchBody = {};
    if (changeType === ChangeUserType.PERMANENT) {
      let newMdeId;
      if (newUserId === permanentUserId) {
        newMdeId = null;
      } else {
        newMdeId = allUsers.find(({ _id }) => (_id === newUserId))?.mdeId;
      }
      patchBody = { driverMdeId: newMdeId };
    } else if (changeType === ChangeUserType.ASSIGNED) {
      let newAssignedUserId;
      if (assignedUserIds.includes(newUserId)) {
        newAssignedUserId = null;
      } else {
        newAssignedUserId = allUsers.find(({ _id }) => (_id === newUserId))?._id;
      }
      patchBody = { assignedUserId: newAssignedUserId };
    }

    patchRoute({
      id: routeId,
      patch: patchBody,
    });
  }, [allUsers, changeType, patchRoute, permanentUserId, assignedUserIds, routeId]);
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { isLoading } = useGetUsersQuery(activeHouseId);

  return (
    <div className={styles.main}>
      <FilterSelect
        options={userOptions}
        isLoading={isLoading}
        onChange={onChange}
        value={changeType === ChangeUserType.PERMANENT ? permanentUserId : assignedUserIds[0]}
      />
    </div>
  );
};

ChangeUser.propTypes = {
  routeId: PropTypes.string.isRequired,
  changeType: PropTypes.oneOf(Object.values(ChangeUserType)).isRequired,
};

export default ChangeUser;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStopCardElementIds, getStopCardHasDraft, getStopKeyInstances } from '../drApi/stopSelectors';
import { CARD_TYPES, CARD_TYPES_MAPPINGS } from '../drApi/constants/cardType';
import StopElementCard from './StopElementCard';
import KeyInstanceElement from './KeyInstanceElement';
import AddElement from './AddElement';
import { ELEMENT_TYPES } from '../drApi/constants/elementType';
import CardSectionTitle from '../detailsPageComponents/CardSectionTitle';
import CardsContainer from '../cards/CardsContainer';
import AddKey from './AddKey';

const StopExtraSection = ({ stopId, cardType }) => {
  const { t } = useTranslation();

  const keyInstances = useSelector(getStopKeyInstances(stopId)) || [];
  const title = CARD_TYPES_MAPPINGS[cardType].human;
  const cardHasDraft = useSelector(getStopCardHasDraft(stopId, cardType));
  const elementIds = useSelector(getStopCardElementIds(stopId, cardType));

  let elementCards = [];

  if (cardType === CARD_TYPES.KEYS) {
    elementCards = keyInstances?.map((keyInstance) => {
      return <KeyInstanceElement key={keyInstance._id} keyInstance={keyInstance} />;
    });

    elementCards.push(<AddKey key="new" stopId={stopId} />);
  } else {
    elementCards = elementIds.map((elementId) => {
      return <StopElementCard key={elementId} stopId={stopId} elementId={elementId} cardType={cardType} />;
    });

    if (!cardHasDraft) {
      const newElementType = cardType === CARD_TYPES.PHONE_NUMBERS ? ELEMENT_TYPES.PHONE_CONTACT : ELEMENT_TYPES.NOTE;
      elementCards.push(<AddElement key="new" stopId={stopId} cardType={cardType} elementType={newElementType} />);
    }
  }

  return (
    <CardsContainer vertical>
      <CardSectionTitle title={t(title)} />
      {elementCards}
    </CardsContainer>
  );
};

StopExtraSection.propTypes = {
  stopId: PropTypes.string.isRequired,
  cardType: PropTypes.oneOf(Object.values(CARD_TYPES)).isRequired,
};

export default StopExtraSection;

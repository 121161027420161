import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserTypeValueContainer.module.scss';
import UserTypeBadge from '../UserTypeBadge/UserTypeBadge';
import AnyRef from '../../propTypes/AnyRef';

const UserTypeValueContainer = ({
  innerRef, hasValue, selectProps, ...props
}) => {
  if (!hasValue) {
    return null;
  }

  const { value: { value, label } } = selectProps;

  return (
    <div
      className={styles.main}
      ref={innerRef}
      {...props}
    >
      <UserTypeBadge userType={value} onlyIcon className={styles.badge} />
      {label}
    </div>
  );
};

UserTypeValueContainer.propTypes = {
  innerRef: AnyRef,
  hasValue: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    value: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default UserTypeValueContainer;

import React, { useState, useEffect } from 'react';
import {
  endOfToday, startOfToday, startOfDay, endOfDay,
} from 'date-fns';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import ImportLogTable from './ImportLogTable';
import { useGetImportLogQuery } from '../drApi/drApi';
import styles from './ImportLog.module.scss';
import RLTableLoader from '../recordsList/RLTableLoader';
import { getDateFnsLocale } from '../drApi/languageSelectors';

const ImportLog = () => {
  const locale = useSelector(getDateFnsLocale);
  useEffect(() => {
    setDefaultLocale(locale);
  }, [locale]);

  const [startDate, setStartDate] = useState(startOfToday());
  const [endDate, setEndDate] = useState(endOfToday());

  const { isFetching, data } = useGetImportLogQuery({
    start: startDate?.toUTCString(),
    end: endDate?.toUTCString(),
  });

  const handleDateChange = (date) => {
    setStartDate(startOfDay(date));
    setEndDate(endOfDay(date));
  };

  return (
    <div className={styles.wrapper}>

      <div className={styles.dateContainer}>
        <div>
          <DatePicker
            dateFormat="dd. MMMM yyyy"
            selected={startDate}
            onChange={(date) => handleDateChange(date)}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      {
        isFetching
          ? <RLTableLoader />
          : <ImportLogTable data={data || []} />
      }
    </div>
  );
};

export default ImportLog;

import React from 'react';
import { useSelector } from 'react-redux';
import { useGetUnconfirmedCountQuery } from '../drApi/drApi';
import styles from './UnconfirmedCount.module.scss';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';

const UnconfirmedCount = () => {
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  const { data: unconfirmedCount } = useGetUnconfirmedCountQuery(activeHouseId, {
    pollingInterval: 30000,
  });

  if (!unconfirmedCount) {
    return null;
  }

  return (
    <div className={styles.unconfirmedCount}>
      {unconfirmedCount}
    </div>
  );
};

export default UnconfirmedCount;

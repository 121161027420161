import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditableCard from '../../EditableCard/EditableCard';
import { useDeleteKeyInstanceMutation, useUpdateKeyInformationMutation } from '../../drApi/drApi';
import KeyInstanceField from '../fields/KeyInstanceField';
import { getCanDeleteKeyInstance, getOtherKeyInstanceNames } from '../../drApi/keyInformationSelectors';
import deleteDraftKeyInstance from '../thunks/deleteDraftKeyInstance';

const KeyInstanceEditableCard = ({ keyInformationId, keyInstance }) => {
  const { t } = useTranslation();
  const {
    _id: keyInstanceId, name, currentUser, currentPosition, isDraft = false,
  } = keyInstance;
  const [isEditing, setIsEditing] = useState(isDraft);
  const canDelete = useSelector(getCanDeleteKeyInstance(keyInformationId));
  const dispatch = useDispatch();
  const otherKeyInstanceNames = useSelector(getOtherKeyInstanceNames(
    keyInformationId, keyInstanceId,
  ));

  const [
    updateKeyInformation, { isSuccess: isSuccessAddUpdate, isLoading: isLoadingAddUpdate },
  ] = useUpdateKeyInformationMutation();
  const [
    deleteKeyInstance, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
  ] = useDeleteKeyInstanceMutation();

  const isSuccess = isSuccessAddUpdate || isSuccessDelete;
  const isLoading = isLoadingAddUpdate || isLoadingDelete;

  const onSubmit = useCallback((values) => {
    const {
      name: newName,
      currentUser: newCurrentUser,
      currentPosition: newCurrentPosition,
    } = values;
    if (
      newName === name
      && newCurrentUser === currentUser
      && newCurrentPosition === currentPosition) {
      setIsEditing(false);
      return;
    }
    if (isDraft) {
      updateKeyInformation({
        keyInformationId,
        newKeyInstances: [{
          name: newName,
          currentUser: newCurrentUser,
          currentPosition: newCurrentPosition,
        }],
      });
      return;
    }
    updateKeyInformation({
      keyInformationId,
      updateFieldsInstances: {
        [keyInstanceId]: {
          name: newName,
          currentUser: newCurrentUser,
          currentPosition: newCurrentPosition,
        },
      },
    });
  }, [name, currentUser, currentPosition, isDraft,
    updateKeyInformation, keyInformationId, keyInstanceId]);

  const onDelete = useCallback(() => {
    if (isDraft) {
      dispatch(deleteDraftKeyInstance({ keyInformationId }));
      return;
    }
    deleteKeyInstance({
      keyInformationId,
      keyInstanceId,
    });
  }, [deleteKeyInstance, dispatch, isDraft, keyInformationId, keyInstanceId]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);
  return (
    <Formik
      initialValues={{ name, currentUser, currentPosition }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleReset,
        handleSubmit,
      }) => (
        <EditableCard
          onEditingChange={setIsEditing}
          isEditing={isEditing}
          canDelete={canDelete}
          canAbort={!isDraft}
          withDeleteModal
          deleteModalTitle={t('Key-ID löschen')}
          deleteModalContent={t('Wollen Sie wirklich die Key-ID {{name}} löschen?', { name })}
          onDelete={onDelete}
          isLoading={isLoading}
          onAbort={handleReset}
          onAccept={handleSubmit}
          autoHeight
        >
          <KeyInstanceField
            isEditing={isEditing}
            namePrefix=""
            otherKeyInstanceNames={otherKeyInstanceNames}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

KeyInstanceEditableCard.propTypes = {
  keyInformationId: PropTypes.string.isRequired,
  keyInstance: PropTypes.shape({
    name: PropTypes.string.isRequired,
    currentUser: PropTypes.string,
    currentPosition: PropTypes.string,
    isDraft: PropTypes.bool,
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

export default KeyInstanceEditableCard;

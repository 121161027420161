// place in plugins/i18next.js
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import DE from './locales/de/translation.json';
import EN from './locales/en/translation.json';
import PL from './locales/pl/translation.json';
import UK from './locales/uk/translation.json';
import HU from './locales/hu/translation.json';

const languages = ['de', 'en', 'pl', 'uk', 'hu'];

function loadResource(lng) {
  let module;

  switch (lng) {
    case 'de': {
      module = DE;
      break;
    }
    case 'en': {
      module = EN;
      break;
    }
    case 'pl': {
      module = PL;
      break;
    }
    case 'uk': {
      module = UK;
      break;
    }
    case 'hu': {
      module = HU;
      break;
    }
    default:
      break;
  }

  return module;
}

function getResources(lngs) {
  const resources = {};

  lngs.forEach((lng) => {
    resources[lng] = {
      translation: loadResource(lng),
    };
  });

  return resources;
}

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    // interpolation: {
    //   prefix: '%{',
    //   suffix: '}',
    // },
    parseMissingKeyHandler(key) {
      /* eslint-disable-next-line no-console */
      const keySeparator = '~~';
      const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;

      return value;
    },
    resources: getResources(languages),
  });

export default i18next;

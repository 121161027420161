import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import UserNameCard from './UserNameCard';
import UserPasswordCard from './UserPasswordCard';
import UserTypeCard from './UserTypeCard';
import UserPermissionCard from './UserPermissionCard';
import CardsContainer from '../cards/CardsContainer';
import { useDeleteUserMutation, useGetUsersQuery } from '../drApi/drApi';
import { getUserName, getUserType } from '../drApi/userSelectors';
import { getIsManager } from '../drApi/meSelectors';
import MdeIdCard from './MdeIdCard';
import TitleBar from '../detailsPageComponents/TitleBar';
import { getGloballySelectedHouseId } from '../drApi/housesSelectors';
import { USER_TYPES } from '../drApi/constants/userType';
import UserGeofencingSettingsCard from './UserGeofencingSettingsCard';

const UserDetails = () => {
  const { t } = useTranslation();
  const activeHouseId = useSelector(getGloballySelectedHouseId);
  useGetUsersQuery(activeHouseId);
  const { userId } = useParams();
  const name = useSelector(getUserName(userId));
  const userType = useSelector(getUserType(userId));
  const isManager = useSelector(getIsManager);

  const history = useHistory();

  const [deleteUser] = useDeleteUserMutation();

  const handleDelete = useCallback(async () => {
    await deleteUser({ userId });
    history.push('/users');
  }, [deleteUser, history, userId]);

  return (
    <>
      <TitleBar
        title={name}
        canDelete
        onDelete={handleDelete}
        withDeleteModal
        deleteModalTitle={t('Nutzer löschen')}
        deleteModalContent={t('Wollen Sie wirklich den Nutzer {{name}} löschen? Er wird sich nicht mehr im Roadbook einloggen können', { name })}
      />
      <CardsContainer>
        <UserNameCard />
        <MdeIdCard />
        <UserPasswordCard />
        <UserTypeCard />
        {isManager && userType === USER_TYPES.MANAGER && <UserPermissionCard />}
        {userType === USER_TYPES.DRIVER && <UserGeofencingSettingsCard />}
      </CardsContainer>
    </>
  );
};

export default UserDetails;
